import {
  constructNewUrl,
  db,
  fetchCollection,
  sortModules,
} from "@kanpla/system";
import { Child, Module } from "@kanpla/types";
import {
  MultiDropdown,
  MultiDropdownItem,
  MultiDropdownWrapper,
  NewLogo,
} from "@kanpla/ui";
import { message } from "antd";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import useBasketPreventClick from "./BasketPreventClick";
import { AppContext } from "./contextProvider";
import SalesplaceSwitch from "./SalesplaceSwitch";

interface Props {
  /** Shows the module switch in tab mode */
  extended?: boolean;
}

const NavbarSwitches = (props: Props) => {
  const { t } = useTranslation(["components"]);

  const { children, child, customBranding, supplier, setChildId, childId } =
    useContainer(AppContext);

  const router = useRouter();
  const confirm = useBasketPreventClick({});

  /** Prepare child switch content data */
  const childSwitchData =
    children?.reduce((acc, child) => {
      return [
        ...acc,
        {
          key: child?.id || child?.id || child?.["userId"],
          label: child?.["displayName"] || child["name"],
          extraLabel: child["school_name"],
          ariaLabel: t("components:switch-to", {
            value: child["name"],
          }),
          ref: child,
          dataCy: `switch-item-${child?.name
            .replace(/\s+/g, "-")
            .toLocaleLowerCase()}`,
        },
      ];
    }, [] as MultiDropdownItem[]) || [];

  const isChildSalesplace =
    customBranding?.childIsSalesplace || supplier?.childIsSalesplace;

  const noChildSwitch = children?.length <= 1;

  const noModuleSwitch = true;

  return (
    <>
      {noChildSwitch && noModuleSwitch && (
        <div className="mx-1">
          <Link href="/app">
            <a className="block">
              <NewLogo
                className="object-left transform scale-90 md:transform-none"
                style={{
                  maxWidth: 130,
                  maxHeight: 42,
                  height: 42,
                }}
              />
            </a>
          </Link>
        </div>
      )}
      <MultiDropdownWrapper>
        {!isChildSalesplace && (
          <MultiDropdown
            hidden={noChildSwitch}
            defaultKey={childId || child?.userId}
            data={{
              items: childSwitchData,
              config: {
                activeFilter: (ref: Child) => childId && ref.id === childId,
                onClick: async (ch: Child) => {
                  try {
                    await confirm();
                    setChildId(ch.id);
                    const modules = await fetchCollection<Module>(
                      db
                        .collection("modules")
                        .where(
                          "scope.generatedSchoolIds",
                          "array-contains",
                          ch.schoolId
                        )
                    );

                    const sortedModules = sortModules(modules);

                    const redirectToUrl = constructNewUrl(
                      ch.schoolId,
                      sortedModules?.[0]?.id
                    );

                    router.push(redirectToUrl);
                  } catch (e) {
                    if (e === "Cancelled") return;
                    console.error(e);
                    message.error(e?.message);
                  }
                },
              },
            }}
          />
        )}

        {!noChildSwitch && isChildSalesplace && <SalesplaceSwitch />}
      </MultiDropdownWrapper>
    </>
  );
};

export default NavbarSwitches;
