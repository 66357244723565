import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  editConfigAmount,
  getConfigNameAndId,
  mergeConfigs,
} from "@kanpla/system";
import { CombinedOfferItem, FlexOption, OrderConfig } from "@kanpla/types";
import { Button, Select } from "antd";
import { isEmpty, setWith } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { FlexBulkContext } from ".";
import WeekInputs from "./WeekInputs";

interface Props {
  availableOptions: FlexOption[];
  firstColumnStyles: string;
  product: CombinedOfferItem;
}

const MultiLayers = (props: Props) => {
  const { t, i18n } = useTranslation(["translation"]);

  const { product, firstColumnStyles, availableOptions } = props;

  const { orders, setOrders } = useContainer(FlexBulkContext);

  const allConfigsUnmerged = Object.values(orders || {}).map(
    (o) => o?.[product.id]?.config || []
  );

  const allConfigs = mergeConfigs(allConfigsUnmerged);

  // Config sorting (to preserve position while changing selects)
  const [configSort, setConfigSort] = useState<Array<string>>([]);

  useEffect(() => {
    if ((allConfigs?.length || 0) > configSort.length) {
      const newSort = allConfigs?.map((c) => getConfigNameAndId(c.options)?.id);
      setConfigSort(newSort);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allConfigs?.length]);

  const addNewLine = () => {
    const days = [0, 1, 2, 3, 4];

    const newOrders = days.reduce((acc, dayIndex) => {
      const order = orders?.[dayIndex];

      const newOrder = editConfigAmount({
        order: order,
        productId: product.id,
        choice: {},
        amountChange: 0,
        replaceAmount: false,
      });

      setWith(acc, `${dayIndex}`, newOrder, Object);
      setConfigSort((oldSort) => [...oldSort, getConfigNameAndId({})?.id]);

      return acc;
    }, {});

    setOrders(newOrders);
  };

  return (
    <div>
      {allConfigs
        ?.sort(
          (a, b) =>
            configSort.findIndex(
              (id) => id === getConfigNameAndId(a.options)?.id
            ) -
            configSort.findIndex(
              (id) => id === getConfigNameAndId(b.options)?.id
            )
        )
        .map((config: OrderConfig) => {
          const configId = getConfigNameAndId(config.options)?.id;

          const isTargetConfig = (conf) =>
            getConfigNameAndId(conf.options)?.id === configId;

          return (
            <div className="flex border-t py-2" key={configId}>
              <div className={`${firstColumnStyles}`}>
                {availableOptions.map((option) => {
                  const choicesIds = Object.keys(config.options || {});

                  const activeChoice = option?.choices?.find((ch) =>
                    choicesIds.includes(`${ch.id}`)
                  );

                  const onChange = (newChoiceId: number) => {
                    // eslint-disable-next-line prefer-const
                    let newOrders = { ...orders };

                    // Update choices
                    const oldChoicesOptions = Object.entries(
                      config.options || ({} as OrderConfig["options"])
                    );
                    const oldChoices = oldChoicesOptions.map(
                      ([choiceId, value]) => ({
                        ...value,
                        id: choiceId,
                      })
                    );
                    const restOfChoices = oldChoices.filter(
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      //@ts-ignore
                      (o) => {
                        return !option?.choices?.some(
                          (ch) => `${ch.id}` === `${o.id}`
                        );
                      }
                    );

                    const newChoice = option?.choices?.find(
                      (ch) => ch.id === newChoiceId
                    );
                    const orderChoices = [
                      ...restOfChoices,
                      {
                        name: newChoice?.name,
                        id: `${newChoiceId}`,
                        extraPrice: newChoice?.price,
                        amount: 1,
                      },
                    ];

                    const newTargetConfigOptions: OrderConfig["options"] =
                      orderChoices.reduce((acc, choice) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        if (choice.amount > 0) acc[choice.id] = choice;
                        return acc;
                      }, {} as OrderConfig["options"]);

                    // Update sort
                    const newConfigId = getConfigNameAndId(
                      newTargetConfigOptions
                    )?.id;
                    setConfigSort(
                      configSort.map((id) => {
                        if (configId === id) {
                          return newConfigId;
                        } else {
                          return id;
                        }
                      })
                    );

                    Object.entries(orders).forEach(([dayIndex, order]) => {
                      const oldConfigSet =
                        newOrders?.[dayIndex]?.[product.id]?.config || [];
                      const otherConfigs = oldConfigSet.filter((conf) => {
                        return !isTargetConfig(conf);
                      });

                      const targetConfig: OrderConfig = oldConfigSet.find(
                        (conf) => isTargetConfig(conf)
                      );

                      const amount = targetConfig?.amount || 0;

                      const newTargetConfig: OrderConfig = {
                        amount,
                        options: newTargetConfigOptions,
                      };

                      const newConfigSet: OrderConfig[] = mergeConfigs([
                        ...otherConfigs,
                        newTargetConfig,
                      ]);

                      setWith(
                        newOrders,
                        `${dayIndex}.${product.id}.config`,
                        newConfigSet,
                        Object
                      );
                    });

                    setOrders(newOrders);
                  };

                  return (
                    <Select
                      key={option.id}
                      value={activeChoice?.id}
                      onChange={onChange}
                      placeholder={t("translation:choose").toLocaleLowerCase()}
                      className="w-28 mr-2"
                    >
                      {option?.choices?.map((choice) => (
                        <Select.Option value={choice.id} key={choice.id}>
                          {choice.name}
                        </Select.Option>
                      ))}
                    </Select>
                  );
                })}
              </div>
              <WeekInputs
                getValue={(dayIndex) => {
                  const productConfig =
                    orders?.[dayIndex]?.[product.id]?.config || [];

                  const targetConfig = productConfig.find((c) =>
                    isTargetConfig(c)
                  );

                  const amount = targetConfig?.amount || 0;

                  return amount;
                }}
                product={product}
                choice={config.options}
              />
            </div>
          );
        })}
      <div className="flex justify-between items-center py-2">
        <Button type="link" onClick={addNewLine}>
          <FontAwesomeIcon icon={faPlus} className="mr-1" />
          {isEmpty(i18n)
            ? "Tilføj ekstra variant"
            : t("translation:add-extra-variation")}
        </Button>
      </div>
    </div>
  );
};

export default MultiLayers;
