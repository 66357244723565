import {
  faClockRotateLeft,
  faEnvelopeDot,
} from "@fortawesome/pro-light-svg-icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import Notifications from "../../modals/Notifications";
import SuperadminSwitch from "../../SuperadminSwitch";
import ButtonLine from "../ButtonLine";
import Language from "./Language";
import Logout from "./Logout";
import Settings from "./Settings";

const UserInfo = () => {
  const { t } = useTranslation(["translation", "settings"]);
  const { isPosone, setHistoryOpen } = useContainer(AppContext);
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  return (
    <div>
      <h2 className="h500 border-b-2 border-divider-main pb-2">
        {t("settings:settings")}
      </h2>

      {!isPosone && (
        <ButtonLine
          label={t("settings:account-history")}
          onClick={() => setHistoryOpen(true)}
          dataCy="history-btn"
          icon={faClockRotateLeft}
        />
      )}

      <ButtonLine
        label={t("translation:mail-preferences")}
        onClick={() => setNotificationsOpen(true)}
        icon={faEnvelopeDot}
        dataCy="email-preferences-btn"
      />

      <Language />

      <Settings />

      <SuperadminSwitch />

      {/* Logout */}
      <Logout />

      {/* Modals */}
      <Notifications open={notificationsOpen} setOpen={setNotificationsOpen} />
    </div>
  );
};

export default UserInfo;
