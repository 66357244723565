import { Flex } from "@kanpla/ordering";
import useDeadlineJump from "apps/frontend/lib/useDeadlineJump";
import CanteenClosed from "../CanteenClosed";
import ModuleDescription from "../ModuleDescription";
import NavbarSecondary from "../NavbarSecondary";

const FlexIndex = () => {
  return (
    <Flex
      ModuleDescription={ModuleDescription}
      NavbarSecondary={NavbarSecondary}
      useDeadlineJump={useDeadlineJump}
      CanteenClosed={CanteenClosed}
    />
  );
};

export default FlexIndex;
