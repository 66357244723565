import { constructNewUrl, isDefaultReferenceValid } from "@kanpla/system";
import { DrawerOrModal, InvoiceReference } from "@kanpla/ui";
import { Form, message } from "antd";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

const AddReferenceInfo = () => {
  const { i18n, t } = useTranslation(["translation", "modals", "libs"]);
  const {
    child,
    setMenuOpen,
    activePlugins,
    module,
    triggerReload,
    updateCurrentChild,
    previousModuleId,
    schoolId,
  } = useContainer(AppContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  const [form] = Form.useForm();

  useEffect(() => {
    if (open) setMenuOpen(false);
  }, [open]);

  useEffect(() => {
    if (!child || !module) return;

    const referenceActive = activePlugins?.invoiceReference === true;
    const defaultReferenceRequired =
      module?.plugins?.invoiceReference?.defaultRequired;
    const invalidDefaultReference = !isDefaultReferenceValid({ child, module });

    const shouldOpen =
      referenceActive && defaultReferenceRequired && invalidDefaultReference;
    if (shouldOpen) setOpen(true);
  }, [module?.id, child?.id]);

  if (!child) return null;

  const submit = async (data) => {
    try {
      setLoading(true);
      await updateCurrentChild({
        defaultReference: data.invoiceReference,
      });
      message.success(t("modals:message.success.info-about-user"));
      setLoading(false);
      setOpen(false);
      triggerReload();
    } catch (e) {
      console.error(e);
      message.error(e.message);
      setLoading(false);
    }
  };

  const title =
    module?.plugins?.invoiceReference?.title ||
    (isEmpty(i18n) ? "Reference til firmakøb" : t("libs:reference"));

  return (
    <DrawerOrModal
      open={open}
      setOpen={() => {
        message.info(t("modals:message.info.please-add-info-about-user"));
      }}
      title={title}
      actions={[
        {
          label: t("translation:cancel"),
          onClick: () => {
            const newUrl = constructNewUrl(schoolId, previousModuleId);
            router.push(newUrl);
            setOpen(false);
          },
          type: "default",
        },
        {
          label: !loading ? t("translation:confirm") : t("translation:loading"),
          loading: loading,
          onClick: () => form.submit(),
        },
      ]}
    >
      <Form
        className="my-8 m-auto mb-0"
        layout="vertical"
        requiredMark={false}
        onFinish={submit}
        form={form}
        // To catch the submit event on a custom form
        onSubmitCapture={(event) => event.preventDefault()}
      >
        <InvoiceReference module={module} required />
      </Form>
    </DrawerOrModal>
  );
};

export default AddReferenceInfo;
