import { faShoppingBasket, faUpLong } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

export const EmptyBasket = () => {
  const { t } = useTranslation(["mealplan2"]);

  return (
    <div className="flex flex-col items-center justify-center m-4">
      <FontAwesomeIcon
        icon={faShoppingBasket}
        className="text-text-disabled text-6xl my-4 opacity-70"
      />

      <h1 className="text-primary-main text-2xl mt-4 font-semibold h800">
        {t("mealplan2:basket-empty")}
      </h1>
      <p className="text-text-secondary text-center mt-1 max-w-[15em]">
        {t("mealplan2:starting-by-adding-items")}
      </p>
      <div className="w-1/3 mt-4 flex justify-center items-end text-primary-main transform -rotate-90 ml-2">
        <FontAwesomeIcon
          icon={faUpLong}
          color="currentColor"
          className="animate-bounce text-4xl"
        />
      </div>
    </div>
  );
};
