import { hasAccessToModule, useProductTour } from "@kanpla/system";
import { Module } from "@kanpla/types";
import { ChildView } from "@kanpla/ui";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import AdminOverview from "../flexBulk/AdminOverview";
import FooterCanteen from "../FooterCanteen";
import Export from "./export";
import Kids from "./Kids";
import UserInfo from "./UserInfo/index";

const Settings = ({ backLabel }) => {
  const { t } = useTranslation(["translation", "components"]);
  const { menuOpen, setMenuOpen, child, school, modules, supplier, user } =
    useContainer(AppContext);

  const EATY_TOUR = 371155;

  // EATY SETTINGS TOUR
  useProductTour(
    EATY_TOUR,
    user,
    menuOpen && supplier?.partnerId === "Trxof1L5MJ0MxEQvHycH"
  );

  const hasBulkAccess = (module: Module) => {
    // Don't show if that module doesn't have any admin codes
    // A code is required to access export function
    if (!module.config?.bulkCodes?.length) return false;

    const bulk = hasAccessToModule({
      module,
      child: child,
      school: school,
      ignoreActive: true,
    }).bulk;

    return bulk;
  };

  return (
    <ChildView
      open={menuOpen}
      setOpen={setMenuOpen}
      title={t("translation:my-account")}
      backLabel={backLabel}
    >
      <div className="grid gap-12">
        <Kids />

        {modules.some(
          (module: Module) => module.type === "flex" && hasBulkAccess(module)
        ) && <AdminOverview availableModules={modules} />}

        {modules.some((module: Module) => hasBulkAccess(module)) && <Export />}

        <UserInfo />
      </div>
      <FooterCanteen />
    </ChildView>
  );
};

export default Settings;
