import { getOrderConfigs } from "@kanpla/system";
import { useMemo } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "./../../../ordering/src/context";

export interface BasketResult {
  totalAmount: number;
  totalPrice: number;
}

/** Returns memorized result of totalAmount and totalPrice. */
export const useBasketResult = (): BasketResult => {
  const { basket } = useContainer(OrderingContext);
  const configs = useMemo(() => getOrderConfigs(basket || {}) || [], [basket]);

  const totalAmount = useMemo(
    () =>
      configs.reduce((result, { config: { amount } }) => {
        return result + amount;
      }, 0),
    [configs]
  );

  const totalPrice = useMemo(
    () =>
      configs.reduce((result, { price, config: { amount } }) => {
        return result + price * amount;
      }, 0),
    [configs]
  );

  return { totalAmount, totalPrice };
};
