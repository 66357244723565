import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, Select, Table } from "antd";
import { cloneDeep, groupBy, isEmpty, set } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { ColumnTableProps } from "./ColumnTable.d";
import {
  getCellDesignSettings,
  getHeaderCellDesignSettings,
} from "./ColumnTable.helper";

export const ColumnTable = <PathType extends string | number>({
  columns,
  setColumns,
  options,
  setAllColumns,
  allColumns,
  moduleId,
  tableClassNames = "",
}: ColumnTableProps<PathType> & { moduleId?: string }) => {
  const { t, i18n } = useTranslation(["mealplan2", "translation"]);

  const hasI18n = !isEmpty(i18n);

  return (
    <div className="w-full">
      <Table
        pagination={false}
        size="small"
        dataSource={(columns || [])?.map((col, index) => ({
          ...col,
          withIndex: `${col.path}-${index}`,
        }))}
        rowKey="withIndex"
        className={`bg-background-secondary ${tableClassNames}`}
        footer={() => (
          <Button
            type="dashed"
            onClick={() => {
              setColumns(() => {
                const newD = cloneDeep(columns || []);
                newD.push({});
                return newD;
              });
              if (moduleId) {
                setAllColumns(() => {
                  const newState = cloneDeep(allColumns);

                  set(newState, `export-${moduleId}-columns`, [
                    ...(newState[`export-${moduleId}-columns`] || []),
                    {},
                  ]);

                  return newState;
                });
              }
            }}
          >
            {hasI18n ? t("mealplan2:add-column") : "Tilføj en kolonne"}
          </Button>
        )}
        columns={[
          {
            title: hasI18n ? t("translation:name") : "Navn",
            dataIndex: "title",
            width: "40%",
            render: (title: string, _, index) => (
              <Input
                placeholder={hasI18n ? t("translation:name") : "Navn"}
                value={title}
                onChange={(e) => {
                  setColumns((d) => {
                    const newD = d.slice();

                    newD[index] = {
                      ...newD[index],
                      title: e.target.value,
                    };

                    return newD;
                  });
                  if (moduleId) {
                    setAllColumns(() => {
                      const newState = cloneDeep(allColumns);

                      set(newState, `export-${moduleId}-columns[${index}]`, {
                        ...(newState[`export-${moduleId}-columns`][index] ||
                          {}),
                        title: e.target.value,
                      });

                      return newState;
                    });
                  }
                }}
                required
              />
            ),
            onHeaderCell: getHeaderCellDesignSettings,
            onCell: getCellDesignSettings,
          },
          {
            title: "Data",
            dataIndex: "path",
            width: "40%",
            render: (p: PathType, _, index) => {
              return (
                <Select
                  value={p}
                  className="w-40"
                  aria-required
                  placeholder={
                    hasI18n ? t("translation:select-data") : "Vælg data"
                  }
                  onChange={(path: PathType) => {
                    setColumns((d) => {
                      const newD = d.slice();

                      newD[index] = {
                        title: options.find((p) => p.path === path)?.title,
                        path,
                      };

                      return newD;
                    });
                    if (moduleId) {
                      setAllColumns(() => {
                        const newState = cloneDeep(allColumns);

                        set(newState, `export-${moduleId}-columns[${index}]`, {
                          ...(newState[`export-${moduleId}-columns`]?.[index] ||
                            {}),
                          title: options.find((p) => p.path === path)?.title,
                          path,
                        });

                        return newState;
                      });
                    }
                  }}
                >
                  {Object.entries(groupBy(options, "group")).map(
                    ([groupName, items]) => (
                      <Select.OptGroup label={groupName}>
                        {items.map((path) => (
                          <Select.Option
                            key={path.path as string}
                            value={path.path}
                          >
                            {path.title}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    )
                  )}
                </Select>
              );
            },
            onHeaderCell: getHeaderCellDesignSettings,
            onCell: getCellDesignSettings,
          },
          {
            title: hasI18n ? t("translation:delete") : "Slet",
            dataIndex: "title",
            render: (_, __, index) => (
              <Button
                danger
                onClick={() => {
                  setColumns((d) => {
                    delete d[index];

                    return d.filter((da) => da);
                  });
                  if (moduleId) {
                    const newState = cloneDeep(allColumns);

                    delete newState[`export-${moduleId}-columns`][index];

                    set(
                      newState,
                      `export-${moduleId}-columns`,
                      newState[`export-${moduleId}-columns`].filter(
                        (col) => col
                      )
                    );

                    setAllColumns(newState);
                  }
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            ),
            onHeaderCell: getHeaderCellDesignSettings,
            onCell: getCellDesignSettings,
          },
        ]}
      />
    </div>
  );
};
