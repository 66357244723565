import { faCircleInfo } from "@fortawesome/pro-duotone-svg-icons";
import { faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findHoliday } from "@kanpla/system";
import { Tooltip } from "antd";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { FlexContext } from "..";

interface Props {
  dateSeconds: string;
  isHolidayFromAdmin?: boolean;
}

const HolidayFeedback = ({
  dateSeconds,
  isHolidayFromAdmin = false,
}: Props) => {
  const { t } = useTranslation(["flex", "flex-bulk"]);

  const { periodsRanges } = useContainer(FlexContext);

  const { isHoliday, period } = useMemo(() => {
    return findHoliday({ periodsRanges, dateSeconds });
  }, [periodsRanges, dateSeconds]);

  const holidayPeriod = useMemo(() => {
    const getDateInfos = ({
      seconds,
      format,
    }: {
      seconds: number;
      format: string;
    }) => {
      return moment.unix(seconds).format(format);
    };

    const isSameMonth =
      getDateInfos({ seconds: period?.fromSeconds || 0, format: "MMMM" }) ===
      getDateInfos({ seconds: period?.toSeconds || 0, format: "MMMM" });

    if (isSameMonth) {
      return `${getDateInfos({
        seconds: period?.fromSeconds || 0,
        format: "DD",
      })} - ${getDateInfos({
        seconds: period?.toSeconds || 0,
        format: "DD",
      })} ${getDateInfos({
        seconds: period?.fromSeconds || 0,
        format: "MMMM",
      })}`;
    }

    return `${getDateInfos({
      seconds: period?.fromSeconds || 0,
      format: "DD",
    })} ${getDateInfos({
      seconds: period?.fromSeconds || 0,
      format: "MMMM",
    })} - ${getDateInfos({
      seconds: period?.toSeconds || 0,
      format: "DD",
    })} ${getDateInfos({
      seconds: period?.toSeconds || 0,
      format: "MMMM",
    })} `;
  }, [period]);

  if (isHoliday) {
    return (
      <div className="flex gap-x-3 items-baseline">
        <h3 className="h500 !text-warning-main">
          {t("flex:holiday-period-value", { value: holidayPeriod })}
        </h3>
        <Tooltip title={t("flex:tooltip.edit-holiday")}>
          <FontAwesomeIcon icon={faCircleInfo} className="cursor-pointer" />
        </Tooltip>
      </div>
    );
  }

  if (isHolidayFromAdmin) {
    return (
      <Tooltip
        title={t("flex-bulk:kitchen-closed")}
        trigger={["hover", "click"]}
      >
        <FontAwesomeIcon
          icon={faWarning}
          className="ml-2 text-warning-main cursor-pointer text-lg"
        />
      </Tooltip>
    );
  }

  return null;
};

export default HolidayFeedback;
