import { getFieldTitle } from "@kanpla/system";
import { OrderInfo, Plugins } from "@kanpla/types";
import React from "react";

interface Props {
  textInput: OrderInfo["textInput"];
  plugin: Plugins.TextInput;
}

export const TextInputDisplay = (props: Props) => {
  const { textInput = {}, plugin } = props;

  const fields = plugin?.fields || [];

  return (
    <>
      {Object.entries(textInput || {}).map(([noteKey, value]) => {
        const fieldTitle = getFieldTitle(noteKey, fields);

        const htmlValue =
          typeof value === "object"
            ? value?.join(", ")
            : ((value as string) || "").replace(/\n/g, "<br />");

        return (
          <div key={noteKey}>
            <span className="capitalize font-semibold">{fieldTitle}:</span>{" "}
            <span dangerouslySetInnerHTML={{ __html: htmlValue }}></span>
          </div>
        );
      })}
    </>
  );
};
