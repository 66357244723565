import { faArrowRight } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrderingContext } from "@kanpla/ordering";
import { priceFormatter } from "@kanpla/system";
import { Module } from "@kanpla/types";
import { DrawerOrModal, TooltipHiddenPrices } from "@kanpla/ui";
import { Button } from "antd";
import { i18n } from "i18next";
import { isEmpty } from "lodash";
import {
  render as reactRender,
  unmount as reactUnmount,
} from "rc-util/lib/React/render";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";

interface Props {
  disabled?: boolean;
}

const useBasketPreventClick = ({ disabled = false }: Props) => {
  const { t, i18n } = useTranslation([
    "mealplan2",
    "components",
    "payment",
    "signup-flow",
    "translation",
  ]);

  const [open, setOpen] = useState(true);
  useEffect(() => {
    if (!open) setOpen(true);
  }, [open]);

  const { module } = useContainer(AppContext);

  const { basket, setOpenBasket, basketContainerUtils } =
    useContainer(OrderingContext);

  const { hidePrices } = module?.config || {};

  if (typeof document === "undefined") return null;

  const container = document.createDocumentFragment();

  const close = () => {
    setOpen(false);
    reactUnmount(container);
  };

  const confirm = async (
    nextModule: Module,
    currModule: Module
  ): Promise<any> => {
    const promise = new Promise((resolve, reject) => {
      // Skip if basket is empty, or if explicitly disabled
      const shouldSkip = isEmpty(basket) || disabled;
      if (shouldSkip) {
        resolve(true);
        return;
      }

      const totalItemsAmount = basketContainerUtils.totalItemsAmount;

      // Configuration for labels
      const labels = {
        title: `${t("mealplan2:complete-the-order")}?`,
        subtitle: (
          <Trans
            t={t}
            i18nKey={"mealplan2:modal.subtitles.switching-module-basket-warn"}
          >
            Du er ved at skifte modul, vil du afslutte din
            <b>
              {{
                currentModuleName:
                  currModule?.displayName || currModule?.name || "",
              }}
            </b>
            bestilling først?
          </Trans>
        ),
        okButtonLabel: t("payment:continue-ordering"),
        cancelButtonLabel: `${t("payment:cancel-ordering")}`,
        productLabel:
          totalItemsAmount === 1
            ? t("mealplan2:plural.products_one", { totalProducts: 1 })
            : t("mealplan2:plural.products_other", {
                totalProducts: totalItemsAmount,
              }),
      };

      BasketCTA({
        open,
        setOpen: close,
        labels,
        hidePrices,
        i18n,
        onConfirm: () => {
          reject("Cancelled");
          setOpenBasket(true);
          close();
        },
        onCancel: () => {
          // Allow user to keep going
          resolve(true);
          basketContainerUtils.reset();
          setTimeout(() => basketContainerUtils.reset(), 200);
          close();
        },
        container,
        totalPriceItems: basketContainerUtils.totalPrice,
      });
    });

    return await promise;
  };

  return confirm;
};

interface CTAProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void;
  onCancel: () => void;
  container: DocumentFragment;
  totalPriceItems: number;
  hidePrices: boolean;
  i18n: i18n;
  labels: {
    title: string;
    subtitle: JSX.Element;
    okButtonLabel: string;
    cancelButtonLabel: string;
    productLabel: string;
  };
}

export const BasketCTA = (props: CTAProps) => {
  const {
    onCancel,
    onConfirm,
    open,
    setOpen,
    container,
    totalPriceItems,
    labels,
    hidePrices,
    i18n,
  } = props;

  const { title, subtitle, okButtonLabel, cancelButtonLabel, productLabel } =
    labels;

  setTimeout(() => {
    reactRender(
      <DrawerOrModal
        open={open}
        setOpen={setOpen}
        title={title}
        subtitle={<div className="mt-2">{subtitle}</div>}
        // zMax
        actions={[
          <div className="flex flex-col w-full">
            <Button
              type="primary"
              className="w-full"
              icon={<FontAwesomeIcon icon={faArrowRight} className="mr-2" />}
              onClick={onConfirm}
              size="large"
            >
              {okButtonLabel}
            </Button>
            <Button type="link" onClick={onCancel} className="mt-2">
              <span className="text-text-secondary">{cancelButtonLabel}</span>
            </Button>
          </div>,
        ]}
      >
        <div className="flex justify-between items-center w-full p-4 mt-12 rounded-md bg-secondary-main text-secondary-contrast">
          {productLabel}
          <b>
            {priceFormatter(
              totalPriceItems,
              { language: i18n.language },
              false,
              hidePrices,
              { wrapper: () => "-", component: () => <TooltipHiddenPrices /> }
            )}
          </b>
        </div>
      </DrawerOrModal>,
      container
    );
  });
};

export default useBasketPreventClick;
