import { OrderingContext } from "@kanpla/ordering";
import { LoadSingleOfferProps, LoadSingleOfferResult } from "@kanpla/services";
import {
  calculateAmountOfOrderItems,
  calculateOrderTotal,
  getOrderWithPrices,
  mergeOrders,
  useFetch,
} from "@kanpla/system";
import {
  CombinedOfferItem,
  CustomOrderContent,
  OrderOrder,
} from "@kanpla/types";
import { keys, pickBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanContext } from ".";
import { AppContext } from "../contextProvider";
import processAllergy from "./processAllergy";

type Props = {
  product: CombinedOfferItem;
};

const useOnPurchase = ({ product }: Props) => {
  const {
    schoolId,
    module,
    activePlugins,
    school,
    child,
    childId,
    dateSeconds,
  } = useContainer(AppContext);
  const { forceUpdate, orderInfo, moduleId } = useContainer(MealplanContext);
  const { basket, setBasket, setOpenBasket } = useContainer(OrderingContext);
  const { productId } = product;
  const { t } = useTranslation(["translation", "allergy", "libs"]);

  const { data: meetingOffer } = useFetch<
    LoadSingleOfferProps,
    LoadSingleOfferResult
  >(
    "offers/loadSingleOffer",
    {
      moduleId,
      schoolId,
      childId,
      dateSeconds: [dateSeconds],
    },
    {
      // fallbackData,
      refreshInterval: 60000,
    }
  );

  const items = meetingOffer?.offer?.items;

  // Holds the state from the variant/amount selectors, sets order upon submit
  const [nextOrder, setNextOrder] = useState(basket);
  const orderTrigger =
    calculateOrderTotal(basket) + calculateAmountOfOrderItems(basket);

  useEffect(() => {
    setNextOrder(basket);
  }, [orderTrigger]);

  const getNextOrder = useCallback(
    ({ productAmount, optionChoices, order }) => {
      const newProductItem = {
        [product.productId]: {
          amount: productAmount,
          price: product.price,
          name: product.name,
          photo: product?.photo,
          config: [
            {
              name: product.name,
              amount: productAmount,
              options: optionChoices || {},
            },
          ],
        },
      };

      /** special case for advanced variants */
      const newOrder = mergeOrders([order, newProductItem]);

      return newOrder;
    },
    [product]
  );

  const addWithAdvancedVariants = () => {
    const orderWithPrices = getOrderWithPrices({
      // order: mergeOrders([basket, nextOrder]),
      order: nextOrder,
      // order: addIndividualDeliveryTime(nextOrder),
      //@ts-ignore
      products: items?.map((p) => ({
        ...p,
        id: p.productId,
      })),
      module: module,
      schoolId,
    });

    return orderWithPrices;
  };

  const onPurchase = async (
    prod: CombinedOfferItem,
    data: CustomOrderContent
  ) => {
    const { requiredProductOrder } = data;

    // ✅ Only ordering with basket
    // validateExtraFields();

    // TODO: Ask about
    // - removing individual product reference
    // - Individual time input
    try {
      // Throw a warning if user is allergic to smth
      const childAllergies = keys(pickBy(child?.allergens));
      const productAllergens = keys(pickBy(prod.allergens));

      await processAllergy({ childAllergies, productAllergens, t });

      const hasKanplaGo = activePlugins?.["kanplaGo"];
      const hasPayPerOrder = activePlugins?.["payPerOrder"];
      const activePaymentPlugin = hasKanplaGo
        ? module?.plugins?.kanplaGo
        : hasPayPerOrder
        ? module?.plugins?.payPerOrder
        : undefined;

      const orderUpdater = (oldOrder: OrderOrder) => {
        /** special case for advanced variants */
        const newOrder = activePlugins.advancedVariants
          ? addWithAdvancedVariants()
          : getNextOrder({ ...data, order: basket });

        const completeOrder: OrderOrder = {
          ...newOrder,
          ...requiredProductOrder,
        };

        return completeOrder;
      };

      setBasket(orderUpdater, orderInfo);
      if (activePaymentPlugin?.showBasketPopup) setOpenBasket(true);
      if (
        module?.type === "mealplan" &&
        !hasKanplaGo &&
        !hasPayPerOrder &&
        ["school", "schoolSupplier", "highSchool"].includes(school.type) &&
        calculateOrderTotal(basket) === 0
      )
        setOpenBasket(true);

      if (activePaymentPlugin?.showBasketPopup) setOpenBasket(true);
      if (
        module?.type === "mealplan" &&
        !hasKanplaGo &&
        !hasPayPerOrder &&
        ["school", "schoolSupplier", "highSchool"].includes(school.type) &&
        calculateOrderTotal(basket) === 0
      )
        setOpenBasket(true);

      setBasket(orderUpdater, orderInfo);

      forceUpdate();
    } catch (e) {
      throw new Error(e?.message);
    }
  };

  const orderButtonDisabled =
    activePlugins.advancedVariants && !(nextOrder?.[productId]?.amount > 0);

  return { onPurchase, orderButtonDisabled, nextOrder, setNextOrder };
};

export default useOnPurchase;
