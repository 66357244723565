import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const LineWrapper = ({ children }: Props) => (
  <div className="relative">
    <hr className="border-divider-main absolute top-0 left-0 w-full mt-6 border-t-2 opacity-70" />
    <div className="relative kanpla-bg-primary max-w-md mx-auto">
      {children}
    </div>
  </div>
);

export default LineWrapper;
