import { faPen } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { FlexContext } from "..";

const StandardHolidaysButton = () => {
  const { t, i18n } = useTranslation(["flex"]);

  const { holidays, periods, setOpenStandardHolidays } =
    useContainer(FlexContext);

  const hasLanguage = !isEmpty(i18n);

  const secondsAreNull = () => {
    const holidaysNull =
      holidays.some(
        (holiday) => !holiday?.fromSeconds && !holiday?.toSeconds
      ) || !holidays.length;
    const periodsNull = periods.some(
      (period) => !period?.fromSeconds && !period?.toSeconds
    );

    return { holidaysNull, periodsNull };
  };

  const hasHolidays =
    !secondsAreNull().holidaysNull || !secondsAreNull().periodsNull;

  const text = hasHolidays
    ? hasLanguage
      ? t("flex:edit-holidays")
      : "Rediger ferie"
    : hasLanguage
    ? t("flex:set-holidays")
    : "Sæt ferie";

  return (
    <div className="pt-3">
      <Button onClick={() => setOpenStandardHolidays(true)}>
        <FontAwesomeIcon icon={faPen} className="mr-2" />
        {text}
      </Button>
    </div>
  );
};

export default StandardHolidaysButton;
