import {
  faArrowLeftLong,
  faArrowRightLong,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { escapeHTMLString } from "@kanpla/system";
import classNames from "classnames";
import { chunk } from "lodash";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "pure-react-carousel";
import React, { useEffect, useState } from "react";
import { useOnWindowResize } from "rooks";

interface Props {
  items: Array<any>;
  rows: 1 | 2;

  renderer: (data: any) => React.ReactChild;
  title?: string;
  subtitle?: string;
  linkButton?: JSX.Element;
}

const slidersAmount = (width: number) => {
  if (width < 640) return 1.2;
  if (width < 1024) return 2.15;
  return 3.1;
};

export const CustomSlider = (props: Props) => {
  const [visibleSlides, setVisibleSlides] = useState(1);

  const { items, rows, renderer, title, subtitle, linkButton } = props;

  useOnWindowResize(({ target }: { target: Window }) => {
    const width = target.innerWidth;
    return setVisibleSlides(slidersAmount(width));
  });

  useEffect(() => {
    if (typeof window === "undefined" || !window) return;
    return setVisibleSlides(slidersAmount(window.innerWidth));
  }, []);

  const extraClasses =
    "bg-background-primary border-divider-main hover:bg-background-secondary focus:bg-background-secondary group hover:border-primary-main focus:border-primary-main disabled:opacity-30";

  const totalSlides = Math.ceil(items.length / rows);

  return (
    <div>
      <CarouselProvider
        lockOnWindowScroll
        naturalSlideWidth={100}
        naturalSlideHeight={80}
        totalSlides={totalSlides}
        visibleSlides={visibleSlides}
        isIntrinsicHeight
      >
        <header>
          <div className="flex justify-between items-baseline mt-4 mb-2">
            <div>
              {title && <h2 className="h600">{escapeHTMLString(title)}</h2>}
              {subtitle && (
                <p className="text-text-secondary text-sm">
                  {escapeHTMLString(subtitle)}
                </p>
              )}
            </div>
            {linkButton && <div className="ml-auto md:mr-4">{linkButton}</div>}
            <div className="hidden md:flex float-right mb-3">
              <ButtonBack
                className={`ant-btn nobg rounded-r-none text-sm text-text-secondary leading-none ${extraClasses}`}
              >
                <FontAwesomeIcon
                  icon={faArrowLeftLong}
                  className="text-text-secondary group-hover:text-primary-main transition-colors duration-500"
                />
              </ButtonBack>
              <ButtonNext
                className={`ant-btn nobg rounded-l-none -ml-px text-sm text-text-secondary leading-none ${extraClasses}`}
              >
                <FontAwesomeIcon
                  icon={faArrowRightLong}
                  className="text-text-secondary group-hover:text-primary-main transition-colors duration-500"
                />
              </ButtonNext>
            </div>
          </div>
        </header>
        <style key={`slider-flex-${totalSlides}`}>{`
          .slider-flex.slides-${totalSlides} {
            display: grid!important;
            grid-template-columns: repeat(${totalSlides} , 1fr)
          }

          .slider-flex.slides-${totalSlides} > div {
            width: 100% !important;
          }
        `}</style>
        <Slider
          classNameTray={`flex transition gap-4 slider-flex slides-${totalSlides}`}
          className="w-screen md:w-full -ml-4 md:m-0 p-4 md:p-0 md:pb-4 pt-0"
        >
          {chunk(items, rows).map((itemElements, i) => (
            <Slide
              key={i}
              index={i}
              innerClassName="h-full"
              className="flex-1 w-full"
            >
              {itemElements.map((item, o) => (
                <div
                  className={classNames(
                    "mb-2 h-full",
                    i === 0 && "first-slide"
                  )}
                  key={`${item?.id}-${i}-${o}`}
                  style={{ touchAction: "pan-x" }}
                >
                  {renderer(item)}
                </div>
              ))}
            </Slide>
          ))}
        </Slider>
      </CarouselProvider>
    </div>
  );
};
