import { CombinedOffer, GroupedCombinedOffer } from "@kanpla/types";

export interface SetBaseProductsProps {
  setStandard: boolean;
  both: boolean;
  allGroupedItems: GroupedCombinedOffer;
  weekOrders: Record<string, unknown>;
  items: CombinedOffer;
}

export const setBaseProducts = ({
  setStandard,
  both,
  allGroupedItems,
  weekOrders,
  items,
}: SetBaseProductsProps) => {
  const isStandard = (weekOrder) => {
    const hasChanges = Boolean(weekOrder?.changes);
    const hasOrder = Boolean(weekOrder?.order);

    const isStandard = hasChanges
      ? !weekOrder?.changes[weekOrder?.changes?.length - 1].userId
      : !hasOrder;

    return isStandard;
  };

  const orders = Object.keys(allGroupedItems).reduce((acc, dateSeconds) => {
    const orderedProduct = weekOrders?.[dateSeconds]
      ? {
          id:
            items?.find((item) => {
              const prop = setStandard
                ? weekOrders?.[dateSeconds]
                : weekOrders?.[dateSeconds]?.["order"];

              const bothProductIds =
                Object.keys(weekOrders?.[dateSeconds]?.["order"] || {})[0] ||
                Object.keys(weekOrders?.[dateSeconds] || {})[0];

              const productId = Object.keys(prop || {})[0];

              return item.id === (both ? bothProductIds : productId);
            })?.id || "noLunch",
          dateSeconds: dateSeconds.toString(),
          isStandard: isStandard(weekOrders?.[dateSeconds]),
        }
      : {
          id: "noLunch",
          dateSeconds: dateSeconds.toString(),
        };

    return {
      ...acc,
      [dateSeconds]: orderedProduct,
    };
  }, {});

  return orders;
};
