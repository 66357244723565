import { OrderOrder } from "@kanpla/types";
import { map, sum } from "lodash";
import { removeUndefinedKeys } from "../utils/removeUndefinedKeys";
import { mergeConfigs } from "./mergeConfigs";

/** used when:
 * - adding products to an existing order */

export const mergeOrders = (orders: Array<OrderOrder>) => {
  const productIds = [
    ...new Set(orders.map((o) => Object.keys(o || {})).flat()),
  ];

  const mergedEntries = productIds.map((productId) => {
    const allOrderProducts = map(orders, productId).filter((p) => p);

    const totalAmount = sum(allOrderProducts.map((p) => p.amount));
    const totalConfig = mergeConfigs(
      allOrderProducts.map((p) => p.config)
    ).filter((config) => config.amount !== 0);

    const productData = {
      ...allOrderProducts[0],
      amount: totalAmount,
      config: totalConfig,
    };

    removeUndefinedKeys(productData);

    return [productId, productData];
  });

  const merged: OrderOrder = Object.fromEntries(mergedEntries);

  return merged;
};

export default mergeOrders;
