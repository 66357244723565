import { CombinedOfferItem, CustomOrderContent } from "@kanpla/types";

interface Props {
  product: CombinedOfferItem;
  data: CustomOrderContent;
}

export const createStandardOrder = ({ product, data }: Props) => {
  const order = {
    [product?.id]: {
      amount: 1,
      price: product?.price,
      name: product?.name,
      config: [
        {
          name: product?.name,
          amount: 1,
          options: data?.optionChoices,
        },
      ],
    },
  };

  return order;
};
