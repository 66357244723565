import { faArrowLeft } from "@fortawesome/pro-duotone-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Space } from "antd";
import classnames from "classnames";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  out: boolean;
  close?: () => void;
  mobile?: boolean;
}

export const ConfirmAnimation = ({ out, mobile, close }: Props) => {
  const [play, setPlay] = useState(false);
  const { t, i18n } = useTranslation(["translation", "mealplan2"]);

  // Delay playing of animation
  useEffect(() => {
    if (out) setTimeout(() => setPlay(true), 500);
    if (!out) setPlay(false);
  }, [out]);

  const backButtonClassNames = classnames({
    "absolute z-10 mt-3 ml-1": mobile,
    hidden: !mobile,
  });

  return (
    <div
      className={`${
        mobile ? "fixed" : "absolute"
      } inset-0 pointer-events-none transition text-primary-contrast duration-500 z-max transform ${
        out ? "" : "opacity-0 translate-y-2"
      }`}
    >
      {close && (
        <Button
          type="link"
          onClick={close}
          className={backButtonClassNames}
          style={{ paddingTop: "var(--status-bar-height)" }}
        >
          <Space>
            <FontAwesomeIcon icon={faArrowLeft} />
            {isEmpty(i18n) ? "Tilbage" : t("translation:back")}
          </Space>
        </Button>
      )}
      <div className="flex flex-col items-center justify-center from-success-main to-success-dark bg-gradient-to-b transition-transform transform h-full origin-bottom shadow-2xl rounded-t-lg p-8">
        <div className="bg-background-primary w-32 h-32 flex items-center justify-center rounded-full ">
          <FontAwesomeIcon
            icon={faCheck}
            className="text-success-main text-4xl"
          />
        </div>
        <h3 className="h700 mb-10 mt-10 text-center">
          {isEmpty(i18n)
            ? "Din mad er nu bestilt!"
            : t("mealplan2:your-food-is-now-ordered")}
        </h3>
      </div>
    </div>
  );
};

export default ConfirmAnimation;
