import { DrawerOrModal, SafetyPaymentBackground } from "@kanpla/ui";
import { AppContext } from "apps/frontend/components/contextProvider";
import { useContainer } from "unstated-next";
import { MealplanContext } from "../../index";
import SkipQueueBanner from "../SkipQueueBanner";
import ReceiptFooter from "./Footer";
import ReceiptHeader from "./Header";
import ReceiptItems from "./ReceiptItem";

interface Props {
  hideSkipQueue?: boolean;
}

const Receipt = (props: Props) => {
  const { hideSkipQueue = false } = props;

  const { child, customBranding, school } = useContainer(AppContext);
  const {
    hasPayPerOrder,
    receiptOpen,
    setReceiptOpen,
    receiptTime,
    checkoutItems,
    module,
  } = useContainer(MealplanContext);

  return (
    <>
      {receiptOpen &&
        !hideSkipQueue &&
        !hasPayPerOrder &&
        module.flow !== "meeting" && <SkipQueueBanner />}

      <DrawerOrModal
        open={receiptOpen}
        setOpen={() => setReceiptOpen(false)}
        showCloseButton={true}
        noPadding={true}
        drawerProps={{
          drawerClassName: "overflow-hidden bg-background-primary",
        }}
      >
        <SafetyPaymentBackground customBranding={customBranding} />
        <div
          className="w-full h-full flex flex-col md:h-auto relative z-10"
          style={{
            paddingBottom: `env(safe-area-inset-bottom)`,
          }}
        >
          <ReceiptHeader
            onBack={() => setReceiptOpen(false)}
            receiptTime={receiptTime}
            childName={child?.displayName || child?.name || "-"}
            schoolName={school?.name || ""}
          />
          <div
            className="z-10"
            style={{
              height: "5px",
              // boxShadow: "0px 1px 20px 40px #FFFFFF",
              marginTop: "-10px",
            }}
          />
          <div className="z-0 w-full h-full p-8 overflow-y-auto flex flex-col justify-center items-center">
            <ReceiptItems items={checkoutItems} />
          </div>
          <ReceiptFooter items={checkoutItems} />
        </div>
      </DrawerOrModal>
    </>
  );
};

export default Receipt;
