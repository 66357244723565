import { priceFormatter } from "@kanpla/system";
import { CustomOrderContent, MenuItem } from "@kanpla/types";
import { ProductSettingsHeader } from "@kanpla/ui";
import { Space } from "antd";
import classnames from "classnames";
import { isEmpty } from "lodash";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { ProductOptions } from "../../flex/VariantsSelectModal/Options";
import DeadlineTimer from "../DeadlineTimer";
import { Image } from "../Image";
import { DisplayAttributes } from "../productAttributes/DisplayAttributes";
import { ProductProps } from "./Product";

interface Props
  extends Pick<
    ProductProps,
    | "product"
    | "isChildView"
    | "stock"
    | "showDeadlineCountdown"
    | "plugins"
    | "order"
    | "orderInfo"
    | "mealOptions"
    | "module"
    | "maxAmount"
    | "defaultReference"
    | "showOrderInfo"
    | "initialAmount"
    | "initialOptions"
    | "hasRequiredProduct"
    | "schoolId"
    | "customInputs"
    | "noImage"
    | "customBranding"
  > {
  setData?: Dispatch<SetStateAction<CustomOrderContent>>;
  data?: CustomOrderContent;
  currentDay?: {
    available: true;
    stock?: number;
    menu?: MenuItem;
  };
  currentDayTimestamp?: number;
  hideVariants?: boolean;
  noWrapperMargin?: boolean;
}

export const ProductDetailsContent = (props: Props) => {
  const {
    product,
    isChildView = false,
    stock = null,
    showDeadlineCountdown = false,
    customInputs,
    noImage = false,
    currentDayTimestamp,
    module,
    customBranding,
    noWrapperMargin = false,
  } = props;

  const { t, i18n } = useTranslation(["libs", "translation"]);

  const hasDiscount = product?.originalPrice;
  // For leftovers products with no discount (already applied to price)
  const discountIsSameAsPrice = product?.originalPrice === product?.price;
  const showDiscountUI = !discountIsSameAsPrice && hasDiscount;

  // For display more information about the product in the current day of a menu
  const currentDay = product?.dates?.[currentDayTimestamp];

  const showStock = product?.isLeftover && stock !== Infinity && !isNaN(stock);

  const { hidePrices } = module?.config || {};

  const stockBg = () => {
    if (stock < 4) return "bg-danger-main";
    if (stock > 3 && stock < 6) return "bg-yellow-400";
    return "bg-primary-main";
  };

  const productPrice = product.taxPercentage
    ? (product.price as number) * (1 + product.taxPercentage)
    : product.price;

  const hideImage = noImage || !product?.photo;

  const wrapperClassNames = classnames({
    "w-full h-full kanpla-bg-primary rounded-t-lg": true,
    "mb-6": !noWrapperMargin,
    "": noWrapperMargin,
  });

  return (
    <div className={wrapperClassNames}>
      {!hideImage && (
        <div
          className={`w-full relative bg-gradient-to-bl md:rounded-t-lg from-gray-300 to-background-secondary user-select-none pointer-events-none  ${
            isChildView ? "h-52" : "h-72"
          }`}
        >
          <Image
            src={product.photo}
            size={{ w: 400 }}
            alt={currentDay?.menu?.name || product.name}
            className="h-full w-full object-cover object-center md:rounded-t-lg inset-x absolute"
          />
          <Image
            src={product.photo}
            size={{ w: 600 }}
            alt={currentDay?.menu?.name || product.name}
            className="h-full w-full object-cover object-center md:rounded-t-lg inset-x absolute"
          />

          <DisplayAttributes
            mode="tag"
            attributes={currentDay?.menu?.labels || product.labels}
            className={isChildView && "bottom-0 top-auto"}
            supplier={customBranding}
          />

          {showStock && (
            <div
              className={`absolute ${
                isChildView ? "top-6 right-2" : "bottom-4 right-2"
              }`}
            >
              {stock && (
                <div
                  className={`py-1 px-4 ${stockBg()} font-semibold rounded-md text-background-primary flex items-center`}
                >
                  {!isEmpty(i18n)
                    ? t("libs:value-back", { value: stock })
                    : `${stock} tilbage`}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {showDeadlineCountdown && (
        <DeadlineTimer deadline={product?.individualDeadline || null} />
      )}
      <div
        className={`px-4 pt-6 md:p-6 kanpla-bg-primary ${
          hideImage ? "md:rounded-t-lg" : ""
        }`}
      >
        <div className="px-2 pb-2">
          <div
            className={
              !currentDay?.menu?.description && !product.description
                ? "mb-4"
                : ""
            }
          >
            <h3
              className="text-text-primary text-bold text-2xl"
              title={currentDay?.menu?.name || product.name}
            >
              {currentDay?.menu?.name || product.name}
            </h3>
            {hidePrices ? null : (
              <div className="flex items-center mb-4 -mt-1">
                <p
                  className={`${
                    showDiscountUI
                      ? "text-text-disabled line-through text-sm"
                      : "text-primary-main"
                  } text-md`}
                >
                  {showDiscountUI ? (
                    !isEmpty(i18n) ? (
                      t("libs:amount-with-value", { value: hasDiscount })
                    ) : (
                      priceFormatter(
                        hasDiscount,
                        { language: i18n?.language },
                        false
                      )
                    )
                  ) : (
                    <>
                      {priceFormatter(
                        productPrice as number,
                        {
                          language: i18n?.language,
                          dictionaries: { from: t("libs:from") },
                        },
                        product.multiplePrices
                      )}
                      {product.extraPriceLabelText || ""}
                      {product.basePrice !== product.price &&
                      typeof product.price === "number" &&
                      typeof product.basePrice === "number" ? (
                        <span className="text-xs pl-1.5 text-text-disabled">
                          {!isEmpty(i18n)
                            ? t("libs:price-formatting", {
                                value:
                                  (priceFormatter(
                                    (product.price as number) *
                                      (1 + (product.taxPercentage || 0)) -
                                      product.basePrice
                                  ),
                                  { language: i18n?.language }),
                              })
                            : ` inkl. ${priceFormatter(
                                (product.price as number) *
                                  (1 + (product.taxPercentage || 0)) -
                                  product.basePrice
                              )} moms`}
                        </span>
                      ) : null}
                    </>
                  )}
                </p>
                {showDiscountUI && (
                  <p className={`text-primary-main ml-2 font-semibold text-md`}>
                    {priceFormatter(
                      productPrice as number,
                      {
                        language: i18n?.language,
                        dictionaries: { from: t("libs:from") },
                      },
                      product.multiplePrices
                    )}
                    {product.extraPriceLabelText || ""}
                    {product.basePrice !== product.price &&
                    typeof product.price === "number" &&
                    typeof product.basePrice === "number" ? (
                      <span className="text-xs pl-1.5 text-text-disabled">
                        {!isEmpty(i18n)
                          ? t("libs:price-formatting", {
                              value:
                                (priceFormatter(
                                  (product.price as number) *
                                    (1 + (product.taxPercentage || 0)) -
                                    product.basePrice
                                ),
                                { language: i18n?.language }),
                            })
                          : ` inkl. ${priceFormatter(
                              (product.price as number) *
                                (1 + (product.taxPercentage || 0)) -
                                product.basePrice
                            )} moms`}
                      </span>
                    ) : null}
                  </p>
                )}
              </div>
            )}
          </div>

          {(currentDay?.menu?.description || product.description) && (
            <p className="mb-4 whitespace-pre-wrap text-text-secondary">
              {currentDay?.menu?.description || product.description}
            </p>
          )}

          {customInputs ? (
            customInputs
          ) : (
            <div className="-mx-2">
              <DefaultContent {...props} currentDay={currentDay} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const DefaultContent = (props: Props) => {
  const {
    product,
    mealOptions,
    module,
    setData,
    initialOptions = {},
    data,
    currentDay,
    hideVariants,
    customBranding,
  } = props;

  const { t, i18n } = useTranslation(["translation"]);

  const options = Object.values(
    currentDay?.menu?.options || product?.options || []
  )
    ?.reduce((acc, id) => {
      const newOption = mealOptions?.find((opt) => opt.id === id);
      return [...acc, newOption];
    }, [])
    .filter((o) => o);

  const updateValues = (newData: Partial<CustomOrderContent>) => {
    setData((data) => ({ ...data, ...newData }));
  };

  const optionChoices = data?.["optionChoices"] || initialOptions;

  const ingredients = product?.ingredients
    ?.map((i) => i?.name)
    ?.filter((i) => i);

  return (
    <>
      {!hideVariants && (
        <div className="px-2">
          <ProductOptions
            product={{
              name: currentDay?.menu?.name || product.name,
              id: product.productId,
              color: product.color,
              price: product.price,
              taxPercentage:
                module?.plugins?.taxSettings?.active && product.taxPercentage,
            }}
            options={options}
            value={optionChoices}
            onChange={(newValue) => updateValues({ optionChoices: newValue })}
          />
        </div>
      )}

      <Space direction="vertical" size="large" className="w-full">
        <DisplayAttributes
          attributes={{
            ...currentDay?.menu?.allergens,
            ...product.allergens,
            ...currentDay?.menu?.pictograms,
            ...product.pictograms,
            ...currentDay?.menu?.labels,
            ...product.labels,
          }}
          supplier={customBranding}
          mode="description"
        />
        {ingredients?.length > 0 && (
          <div className="px-2 block">
            <ProductSettingsHeader
              title={
                isEmpty(i18n) ? "Ingredienser" : t("translation:ingredients")
              }
            />
            <div className="mt-2 text-sm text-text-secondary">
              {ingredients.join(", ")}
            </div>
          </div>
        )}
      </Space>
    </>
  );
};
