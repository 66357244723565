import { CombinedOfferItem, GroupedCombinedOffer, Week } from "@kanpla/types";

interface Props {
  items: CombinedOfferItem[];
  week: Week;
}

/**
 * This method group the offers by available day and by category.
 * Object structure:
 * {
 *   [dateSeconds]: {
 *     [category]: [offer, offer, offer],
 *     [category]: [offer, offer],
 *   },
 *   ...
 * }
 */
export const groupItemsByDayAndByCategory = ({
  items,
  week,
}: Props): GroupedCombinedOffer => {
  const datesSeconds = getGroup({
    items: week as any,
    entity: "dates",
  });
  const categories = getGroup({ items, entity: "category" }).map(
    (category) => category || ""
  );

  const groupedItems = datesSeconds.reduce((acc, dateSeconds) => {
    const reducedCategories = categories.reduce(
      (acc, category) => ({ ...acc, [category]: [] }),
      {}
    );

    return {
      ...acc,
      [dateSeconds]: reducedCategories,
    };
  }, {});

  Object.entries(groupedItems).forEach(([key, value]) => {
    const availableItems = items.filter(
      (item) =>
        item.dates[key]?.available &&
        item.dates[key]?.menu?.name?.toLowerCase() !== "x"
    );

    if (availableItems.length) {
      Object.keys(value).forEach((category) => {
        const filteredAvailableItems = availableItems.filter(
          (item) => item.category === category
        );

        value[category] = filteredAvailableItems;
      });
    }
  });

  return groupedItems;
};

interface GetGroupProps {
  items: CombinedOfferItem[];
  entity: "dates" | "category";
  thisMondaySeconds?: number;
}

const getGroup = ({ items, entity }: GetGroupProps): string[] => {
  const values = items?.reduce((acc, item) => {
    switch (entity) {
      case "dates": {
        return [...acc, item["seconds"]];
      }
      case "category":
        return [...acc, item[entity]];
      default:
        return [...acc, item];
    }
  }, []);

  const uniqueValues = [...new Set(values)];

  return uniqueValues;
};
