import { faReceipt } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip } from "antd";
import { Badge } from "@kanpla/ui";
import React from "react";
import useFutureOrders from "../lib/useFutureOrders";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";

interface Props {
  userId: string;
}

const InnerButton = () => {
  const { t } = useTranslation(["mealplan2"]);
  const { setHistoryOpen } = useContainer(AppContext);

  return (
    <Tooltip title={t("mealplan2:show-receipts")} placement="left">
      <Button size="small" type="link" onClick={() => setHistoryOpen(true)}>
        <FontAwesomeIcon
          icon={faReceipt}
          className="text-primary-main text-2xl"
        />
      </Button>
    </Tooltip>
  );
};

const ReceiptButton = ({ userId }: Props) => {
  const { hasFutureOrders, ordersForToday } = useFutureOrders(userId);

  if (!userId) return null;

  if (!hasFutureOrders) return null;

  if (!ordersForToday)
    return (
      <Badge count={1} dot offset={[-10, 4]} size="small" useRedColor>
        <InnerButton />
      </Badge>
    );

  return (
    <Badge
      count={ordersForToday}
      overflowCount={9}
      offset={[-8, 3]}
      useRedColor
      size="small"
    >
      <InnerButton />
    </Badge>
  );
};

export default ReceiptButton;
