import { Homescreen } from "@kanpla/types";

interface Props {
  singleBlock: Homescreen.ContentInner;
}

const Text = ({ singleBlock }: Props) => {
  if (!singleBlock.props?.text) return null;

  return (
    <p
      className="text-text-primary"
      dangerouslySetInnerHTML={{ __html: singleBlock.props?.text }}
    />
  );
};

export default Text;
