import { fn } from "@kanpla/system";
import { EditStandardOrder } from "@kanpla/ui";
import { useContainer } from "unstated-next";
import { FlexContext } from "..";
import { OrderingContext } from "../../context";

const StandardSettings = () => {
  const { schoolId, childId, userId, week } = useContainer(OrderingContext);
  const {
    items,
    moduleId,
    standard,
    isBulk,
    settingsOpen,
    setSettingsOpen,
    selectProductOpen,
    standardProducts,
    getCurrentProducts,
    submitStandard,
    allGroupedItems,
    standardWeek,
  } = useContainer(FlexContext);

  return (
    <EditStandardOrder
      fn={fn}
      schoolId={schoolId}
      moduleId={moduleId}
      childId={childId}
      userId={userId}
      open={settingsOpen}
      setOpen={setSettingsOpen}
      products={allGroupedItems}
      plainProducts={items}
      standard={standard}
      standardWeek={standardWeek}
      isBulk={isBulk}
      selectedProducts={standardProducts}
      selectStandardOrderOpen={selectProductOpen}
      getCurrentProducts={getCurrentProducts}
      submitStandard={submitStandard}
      week={week}
    />
  );
};

export default StandardSettings;
