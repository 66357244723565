import { OrderingContext } from "@kanpla/ordering";
import {
  callFunction,
  callInternalApi,
  getActivePlugins,
  orderInfoFormToData,
} from "@kanpla/system";
import { OrderPersonal } from "@kanpla/types";
import { message } from "antd";
import { useForm } from "antd/lib/form/Form";
import useBasketPayment from "apps/frontend/lib/payment/useBasketPayment";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocalstorageState } from "rooks";
import { useContainer } from "unstated-next";
import { MealplanContext } from "..";
import { AppContext } from "../../contextProvider";
import useBasketActions from "./lib/useBasketActions";

/** Purchase logic for basket. Contains:
 * - Logic to show allowed action buttons
 * - On purchase, and finish order logic
 * - Plugins and module config logic
 * - Kanpla Go UI receipt
 */

const useBasketPurchaseLogic = () => {
  const [form] = useForm();
  const { t, i18n } = useTranslation([
    "mealplan2",
    "translation",
    "modals",
    "payment",
    "anonymous",
    "errors",
  ]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);

  const {
    setReceiptOpen,
    setCheckoutItems,
    module,
    hasKanplaGo,
    setReceiptTime,
    newOrderInfo,
    setNewOrderInfo,
  } = useContainer(MealplanContext);
  const { card, balance, activePlugins, posoneBalance, modules } =
    useContainer(AppContext);

  const {
    basket,
    setBasket,
    setOpenBasket,
    basketContainerUtils,
    basketContainer,
  } = useContainer(OrderingContext);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [amountShownTakeFirstBanner, setAmountShownTakeFirstBanner] =
    useLocalstorageState("amount-shown-take-first-banner", 0);

  const [loading, setLoading] = useState(false);

  // To be refactored!
  const {
    selectedPaymentMethod,
    setRememberCard,
    setSelectedPaymentMethod,
    rememberCard,
    loadChargeSession,
    callbackUrl,
  } = useBasketPayment({
    setLoading,
    setReceiptTime,
    setCheckoutItems,
    setReceiptOpen,
    kanplaGoCallback: () => {
      if (!hasKanplaGo) {
        setConfirmationModalOpen(true);
        setTimeout(() => setConfirmationModalOpen(false), 3000);
      }
    },
  });

  const basketPrice = basketContainerUtils.totalPrice;

  const [basketEditMode, setBasketEditMode] = useState(false);

  const isCredit = module.paymentMethod === "credit";
  const isPosone = module.paymentMethod === "posOne";

  const isOnlyPrepaid = !activePlugins?.kanplaGo && !activePlugins?.payPerOrder;

  const onlyCredit = isCredit && isOnlyPrepaid;
  const onlyPosoneCredit = isPosone && isOnlyPrepaid;

  const hasMoney = balance >= basketPrice || false;
  const payWithSavedCard = !!card && selectedPaymentMethod === "card";

  const showOrderInfo = useMemo(
    () =>
      basketContainer.some((basket) => {
        const module = modules.find((m) => {
          return m.id === basket.moduleId;
        });
        const activePlugins = getActivePlugins({ module });

        return (
          module?.flow === "meeting" ||
          activePlugins.timeInput ||
          activePlugins.textInput ||
          activePlugins.orderForAmountOfPeople ||
          activePlugins.invoiceReference
        );
      }),
    [modules, basketContainer]
  );

  const canProcessPayment =
    activePlugins?.kanplaGo || activePlugins?.payPerOrder;

  const reset = useCallback(() => {
    /**
     * Rather than to set the basket to a plain object,
     * we will set the basket to the current session state
     */
    setBasket(basket, newOrderInfo);
    setBasketEditMode(false);
  }, []);

  const handleBasketOpening = useCallback(
    (open: boolean) => {
      setOpenBasket(open);
      setBasketEditMode(false);
    },
    [setBasketEditMode, setOpenBasket]
  );

  // Open receipt ticket
  const openConfirmation = () => {
    //message.success(t("translation:message.success.payment-successful"));

    if (module.flow === "meeting") {
      setReceiptOpen(true);
    }

    if (hasKanplaGo) {
      setReceiptOpen(true);
      setReceiptTime(moment().unix());
      setCheckoutItems(basket);
    }

    if (!hasKanplaGo) {
      setConfirmationModalOpen(true);
      setTimeout(() => setConfirmationModalOpen(false), 3000);
    }
    reset();
  };

  // submit order
  const finishOrder = async (basketContainer: OrderPersonal[]) => {
    try {
      await callInternalApi(
        "ordering/submitMultipleOrders",
        {
          orders: basketContainer,
        },
        { noErrorMessage: true }
      );

      setNewOrderInfo(null);
      // Open receipt ticket
      openConfirmation();
      handleBasketOpening(false);

      // Reset basket container
      basketContainerUtils.reset();
    } catch (err) {
      const errorMessage = err?.message?.replace("Error: ", "");
      const errorTitle = i18n.exists(`errors:${errorMessage}`)
        ? t(`errors:${errorMessage}`)
        : errorMessage;
      console.error(err);
      message.error(errorTitle);
    } finally {
      setLoading(false);
    }
  };

  // submit order
  const onPurchase = useCallback(async () => {
    try {
      let updatedBasketContainer = basketContainer;

      // Retrieve order info and store it in the basket
      if (showOrderInfo) {
        const data = await form.validateFields();
        const newData = orderInfoFormToData(data);

        // Update all orderInfos into the basket container
        Object.entries(newData).forEach(([dateSecondsString, moduleValues]) => {
          Object.entries(moduleValues).forEach(([moduleId, orderInfo]) => {
            const dateSeconds = parseInt(dateSecondsString);
            const findOrder = basketContainerUtils.findTargetBasket(
              moduleId,
              dateSeconds
            );
            const newBasketContainer =
              basketContainerUtils.setBasketFromDifferentModule({
                o: findOrder.order,
                orderInfo,
                moduleId,
                dateSeconds,
              });

            updatedBasketContainer = Object.values(newBasketContainer);
          });
        });

        form.resetFields();
      }

      setLoading(true);
      setAmountShownTakeFirstBanner(amountShownTakeFirstBanner + 1);

      const shouldChargeCreditCard =
        !hasMoney &&
        !payWithSavedCard &&
        module.paymentMethod !== "billing" &&
        !onlyPosoneCredit;

      if (shouldChargeCreditCard) {
        const missingMoney = basketPrice - balance;
        const calculatePrice = missingMoney < 0 ? basketPrice : missingMoney;

        // TODO move over to callInterlanApi
        const sessionId = await callFunction("reepayPaymentOnce", {
          amount: calculatePrice,
          recurring: selectedPaymentMethod === "card" ? rememberCard : false,
          paymentMethod: selectedPaymentMethod,
          isWindowSession: true,
          mode: "order",
          callbackUrl,
          orders: updatedBasketContainer,
        });

        await loadChargeSession(sessionId);
      } else {
        if (onlyPosoneCredit && posoneBalance < basketPrice)
          throw new Error(t("translation:not-enough-credit"));
        await finishOrder(updatedBasketContainer);
      }
      basketContainerUtils.reset();
    } catch (e) {
      const errors = e?.errorFields?.map((f) => f?.errors);
      message.error(errors?.join(", ") || e?.message);
      console.error(e);
      setLoading(false);
    }
  }, [
    amountShownTakeFirstBanner,
    balance,
    basketContainer,
    basketContainerUtils,
    basketPrice,
    callbackUrl,
    form,
    hasMoney,
    module.paymentMethod,
    onlyPosoneCredit,
    payWithSavedCard,
    posoneBalance,
    rememberCard,
    selectedPaymentMethod,
    showOrderInfo,
    t,
  ]);

  const { actions } = useBasketActions({
    onPurchase,
    onlyCredit,
    onlyPosoneCredit,
    canProcessPayment,
    loading,
    selectedPaymentMethod,
  });

  return {
    actions,
    basketPrice,
    handleBasketOpening,
    amountShownTakeFirstBanner,
    form,
    isCredit,
    hasMoney,
    setRememberCard,
    rememberCard,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    confirmationModalOpen,
    setConfirmationModalOpen,
    basketEditMode,
    setBasketEditMode,
  };
};

export default useBasketPurchaseLogic;
