import { OrderingContext } from "@kanpla/ordering";
import { OrderOrder, PaymentProvider } from "@kanpla/types";
import { message } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocalstorageState } from "rooks";
import { useContainer } from "unstated-next";
import useChargeSession from "./UseChargeSession";
import useWindowPayment from "./useWindowPayment";

interface Props {
  /** Sets the loading of the payment action button */
  setLoading: Dispatch<SetStateAction<boolean>>;
  setReceiptTime?: Dispatch<SetStateAction<number>>;
  setCheckoutItems?: Dispatch<SetStateAction<OrderOrder>>;
  setReceiptOpen?: Dispatch<SetStateAction<boolean>>;
  /** Callback that executes any operation related to `kanplaGo` after the payment is confirmed */
  kanplaGoCallback?: () => void;
}

/**
 * Handles state and callbacks related to payment inside the basket
 */
const useBasketPayment = (props: Props) => {
  const {
    setLoading,
    setReceiptOpen = () => null,
    setCheckoutItems = () => null,
    setReceiptTime = () => null,
    kanplaGoCallback = () => null,
  } = props;

  const { t } = useTranslation(["mealplan2"]);
  const { basketContainerUtils } = useContainer(OrderingContext);

  /** Remembers the last payment method used by the user as sets it as default */
  const [defaultPaymentMethod, setDefaultPaymentMethod] =
    useLocalstorageState<PaymentProvider>("kanpla:last-payment-method", "card");
  /** Payment method selected by the user to make the payment */
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentProvider>(null);
  /** Remembers the card of the user, if payment is card */
  const [rememberCard, setRememberCard] = useState<boolean>(true);

  useEffect(() => {
    setDefaultPaymentMethod(selectedPaymentMethod);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentMethod]);

  /** Create reepay window session */
  const { loadChargeSession } = useChargeSession({
    setLoading,
    onError: (error) => message.error(error.error),
  });

  /** Listen on payment success and provides callback url for reepay */
  const { callbackUrl } = useWindowPayment({
    mode: "order",
    setReceiptOpen,
    setReceiptTime,
    setCheckoutItems,
    callback: () => {
      basketContainerUtils.reset();
      kanplaGoCallback();
    },
  });

  /* Catch `cancel` event from the native wrapper */
  const handleCancelFromWrapper = (e) => {
    const { isApplePayCanceledByUser } = JSON.parse(e.data || {});

    if (isApplePayCanceledByUser) {
      setLoading(false);
      message.warning({
        title: t("mealplan2:message.warning.payment-canceled"),
        key: "payment-canceled",
      });
    }
    window.removeEventListener("message", handleCancelFromWrapper);
  };

  useEffect(() => {
    // Listen on messages from native wrapper (handles user cancel event)
    if (window?.["isRenderedInWebView"]) {
      window.addEventListener("message", handleCancelFromWrapper);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    selectedPaymentMethod:
      selectedPaymentMethod || defaultPaymentMethod || "card",
    setSelectedPaymentMethod,
    rememberCard,
    setRememberCard,
    /** Opens a reepay session in window mode */
    loadChargeSession,
    /** The `url` that will be opened after the window payment is made */
    callbackUrl,
  };
};

export default useBasketPayment;
