import { detectProductPrice } from "@kanpla/system";
import { Module, OrderOrder, OrderOrderProduct, Product } from "@kanpla/types";

interface Props {
  order: OrderOrder;
  products: Product[];
  module: Module;
  schoolId: string;
}

export const getOrderWithPrices = (props: Props) => {
  const { order, products, module, schoolId } = props;

  const orderEntries = Object.entries(order).map(([productId, value]) => {
    const product = products.find((p) => p.id === productId);
    if (!product) return [productId, { amount: 0 } as OrderOrderProduct];

    const price = detectProductPrice(product, module, schoolId);

    const name = product.name;

    const newConfig = value?.config?.filter((conf) => conf.amount > 0) || [];

    const newValue: OrderOrderProduct = {
      name,
      extraName: value.extraName,
      amount: value.amount,
      config: newConfig || [],
      price,
    };

    return [productId, newValue];
  });

  const orderWithPrices: OrderOrder = Object.fromEntries(orderEntries);

  return orderWithPrices;
};
