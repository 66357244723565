import { CombinedOfferItem } from "@kanpla/types";
import { Dictionary, groupBy, sortBy } from "lodash";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanContext } from ".";
import { AppContext } from "../contextProvider";

/** Returns dictionary of sorted and grouped categories. */
export const useCategories = (
  itemsFromProps?: CombinedOfferItem[]
): Dictionary<CombinedOfferItem[]> => {
  const { items, hasLeftovers, module } = useContainer(MealplanContext);
  const { dateSeconds, schoolId } = useContainer(AppContext);
  const { t } = useTranslation("translation");

  const category = module?.text?.["noCategory/text"] || t("dish-of-the-day");

  const sorted = sortBy(
    itemsFromProps || items,
    (item) => item.category !== (hasLeftovers ? "leftovers" : "")
  );

  const mapped = sorted.map((item) => {
    const shopProducts = module?.productLines?.filter(
      (pl) =>
        item.productId === pl.productId &&
        dateSeconds >= pl.fromSeconds &&
        dateSeconds <= pl.toSeconds &&
        pl.scope?.generatedSchoolIds?.includes(schoolId)
    );

    const shopProduct = shopProducts?.[0];
    return {
      ...(shopProduct || {}),
      ...item,
      category: item.category ? item.category : category,
      isBig: item.category ? false : true,
    };
  });

  return groupBy(mapped, "category");
};
