import {
  BasketListItem,
  OrderInfoItem,
  OrderingContext,
} from "@kanpla/ordering";
import { getActivePlugins, getOrderConfigs } from "@kanpla/system";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import Payment from "../basket/elements/Payment";
import PriceOverview from "../basket/elements/PriceOverview";
import useBasketPurchaseLogic from "../basket/useBasketPurchaseLogic";

const OrderPreview = () => {
  const { module, dateSeconds } = useContainer(AppContext);
  const {
    hasMoney,
    isCredit,
    basketPrice,
    rememberCard,
    setRememberCard,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    form,
  } = useBasketPurchaseLogic();
  const { basket } = useContainer(OrderingContext);

  const { t } = useTranslation(["design", "mealplan2"]);

  const orderConfig = useMemo(() => getOrderConfigs(basket), [basket]);
  const activePlugins = useMemo(
    () =>
      getActivePlugins({
        module,
      }),
    [module]
  );

  const hasActivePlugins =
    activePlugins.timeInput ||
    activePlugins.textInput ||
    activePlugins.orderForAmountOfPeople ||
    activePlugins.requiredProduct ||
    activePlugins.invoiceReference;

  if (isEmpty(basket))
    return (
      <div className="w-full mt-8">
        <h1 className="h400">
          {t("mealplan2:registration.empty-basket-title")}
        </h1>
        <p className="text-text-disabled mt-1">
          {t("mealplan2:registration.empty-basket-description")}
        </p>
      </div>
    );

  return (
    <div className="w-full flex flex-col transition-all ease-linear">
      <h1 className="h500 my-2">{t("design:basket")}</h1>
      {orderConfig.map((item) => {
        return (
          <div key={item.productId}>
            <BasketListItem
              item={item}
              editMode={false}
              basket={basket}
              hideDeleteButton
              hidePicture
              tooltipPlacement="left"
              displayStringAmount
            />
          </div>
        );
      })}
      <div className="mt-2">
        {hasActivePlugins && (
          <OrderInfoItem
            module={module}
            dateSeconds={dateSeconds}
            form={form}
            noHeightIfExpanded
            noExtraMarginOnSettingsHeader
          />
        )}
      </div>
      {/* Price overview */}
      <PriceOverview
        basketPrice={basketPrice}
        isCredit={isCredit}
        hasMoney={hasMoney}
      />
      <div className="mt-2">
        {/* Payment */}
        <Payment
          setRememberCard={setRememberCard}
          rememberCard={rememberCard}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          selectedPaymentMethod={selectedPaymentMethod}
          basketPrice={basketPrice}
          hasMoney={hasMoney}
        />
      </div>
    </div>
  );
};

export default OrderPreview;
