import React, { ReactElement } from "react";
import classnames from "classnames";
import {
  faBell,
  faCheckToSlot,
  faMegaphone,
  faImage,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { Tooltip } from "antd";

type MessageType = "notification" | "interaction" | "banner" | "popup";

interface Props {
  /** The type of message to display */
  type: MessageType;
  /** The title of the message */
  title: string;
  /** The description of the message */
  description: string | ReactElement;
  /** The date seconds of the creation of the message */
  dateSeconds: number;
  /** If the message has been read or not */
  active?: boolean;
}

type MappedIcons = {
  [key in MessageType]: {
    icon: IconDefinition;
    label: string;
  };
};

const mappedIcons: MappedIcons = {
  notification: {
    icon: faBell,
    label: "Beskeder",
  },
  interaction: {
    icon: faCheckToSlot,
    label: "Afstemning",
  },
  banner: {
    icon: faMegaphone,
    label: "Banner",
  },
  popup: { icon: faImage, label: "Popup" },
};

const MessageLine = (props: Props) => {
  const { type, title, description, dateSeconds, active = false } = props;

  const momentTime = moment.unix(dateSeconds);
  const timeString = momentTime.fromNow();

  return (
    <div
      className={classnames("w-full flex flex-col px-4 text-text-primary", {
        "border-l-2 border-primary-main py-4": active,
      })}
      style={{
        backgroundColor:
          active && "rgba(var(--palette-primary-main-rgb) / 0.08)",
      }}
    >
      {/* Type */}
      <div
        className={classnames(
          "w-fit px-2 rounded-sm mb-4 flex items-center justify-center",
          active
            ? "text-primary-main"
            : "text-secondary-contrast bg-background-secondary"
        )}
        style={{
          backgroundColor:
            active && "rgba(var(--palette-primary-main-rgb) / 0.2)",
          paddingTop: "2px",
          paddingBottom: "2px",
        }}
      >
        <FontAwesomeIcon
          icon={mappedIcons[type].icon}
          size="xs"
          className={classnames(
            "mr-2",
            active ? "text-primary-main" : "text-secondary-contrast"
          )}
        />
        <span className="text-xs">{mappedIcons[type].label}</span>
      </div>
      {/* Title */}
      <h2 className="text-md font-semibold mb-2">{title}</h2>
      {/* Description */}
      <div className="mb-2 text-sm">{description}</div>
      <Tooltip
        className="text-sm"
        title={momentTime.format("llll")}
        placement="left"
      >
        <p className="text-text-disabled text-xs">{timeString}</p>
      </Tooltip>
    </div>
  );
};

export default MessageLine;
