import { defineOptions, exportOrders } from "@kanpla/system";
import {
  ExportOrderColumns,
  ExportOrderColumnsBase,
  ExportOrdersResponseItem,
} from "@kanpla/types";
import { ColumnTable, ColumnTableList, DrawerOrModal } from "@kanpla/ui";
import {
  Button,
  DatePicker,
  FormInstance,
  Space,
  Steps,
  Switch,
  message,
} from "antd";
import { cloneDeep, set } from "lodash";
import moment, { Moment } from "moment";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocalstorageState } from "rooks";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

interface SelectModuleProps {
  selectedModuleId?: string;
  setSelectedModuleId?: Dispatch<SetStateAction<string>>;
  form?: FormInstance<any>;
}

interface AllCols {
  [key: string]: ColumnTableList<string>;
}

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  modalTitle: string;
  setAllColumns?: Dispatch<SetStateAction<AllCols>>;
  allColumns?: AllCols;
  SelectModule?: (props: SelectModuleProps) => JSX.Element;
  selectModuleProps?: SelectModuleProps;
  selectedColumns?: ColumnTableList<string>;
}

const ExportOrdersModal = ({
  open,
  setOpen,
  modalTitle,
  setAllColumns,
  allColumns,
  selectedColumns,
  SelectModule = null,
  selectModuleProps = {},
}: Props) => {
  const { t, i18n } = useTranslation(["translation", "mealplan2", "libs"]);
  moment.updateLocale(i18n.language, { week: { dow: 1, doy: 4 } });

  const dictionaries: ExportOrdersResponseItem = useMemo(() => {
    const translations = {
      moduleName: t("libs:module-name"),
      modulePaymentMethod: t("libs:module-payment-method"),
      salesplaceName: t("libs:salesplace-name"),
      displayName: t("libs:display-name"),
      groupName: t("libs:group-name"),
      childId: t("libs:child-id"),
      email: t("libs:email"),
      dateSeconds: t("libs:date-seconds"),
      billedDateSeconds: t("libs:billed-date-seconds"),
      order: t("libs:order"),
      orderId: t("libs:order-id"),
      priceTotalIncludingTax: t("libs:price-total-including-tax"),
      priceTotalExcludingTax: t("libs:price-total-excluding-tax"),
      taxTotal: t("libs:tax-total"),
      priceIndividualIncludingTax: t("libs:price-individual-including-tax"),
      priceIndividualExcludingTax: t("libs:price-individual-excluding-tax"),
      taxIndividual: t("libs:tax-individual"),
      productsTotal: t("libs:products-total"),
      orderInfo_name: t("libs:order-info-name"),
      deliveryTime: t("libs:delivery-time"),
      invoiceReference: t("libs:invoice-reference"),
    };

    return translations;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  const { module, allModules, schoolId, modules, child } =
    useContainer(AppContext);

  const { selectedModuleId, form } = selectModuleProps;

  const [productPerLine, setProductPerLine] = useState(false);
  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState<[Moment, Moment]>([
    moment().utc().startOf("month"),
    moment().utc().endOf("month"),
  ]);

  const moduleIds: string[] = useMemo(
    () => allModules.map((m) => m.id),
    [allModules]
  );

  const options = useMemo(() => {
    const { definedOptions, customOptions } = defineOptions({
      modules,
      dictionaries,
      productPerLine,
    });

    return [...definedOptions, ...customOptions];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modules, productPerLine]);

  const defaultColumns = useMemo(() => {
    const cols = [
      {
        title: t("translation:date"),
        path: "dateSeconds",
      },
      {
        title: t("mealplan2:name-of-the-order"),
        path: "orderInfo_name",
      },
      {
        title: t("translation:total-price"),
        path: "priceTotalIncludingTax",
      },
      {
        title: t("translation:VAT"),
        path: "taxTotal",
      },
    ];

    return cols;
  }, []);

  const [columnSettings, setColumnSettings] =
    useLocalstorageState<ColumnTableList>(
      `export-${module?.id}-columns`,
      defaultColumns
    );

  const propOrState = (
    newState: ColumnTableList<string> = null,
    getState = false
  ) => {
    if (getState) return SelectModule ? selectedColumns : columnSettings;

    if (SelectModule) {
      const newCols = cloneDeep(allColumns);

      set(newCols, `export-${selectedModuleId}-columns`, newState);

      setAllColumns(newCols);
    } else {
      setColumnSettings(newState);
    }
  };

  useEffect(() => {
    const isOldWay = (
      (propOrState(null, true) as ColumnTableList<string>) || []
    ).some((col) => col?.path?.includes("info//"));

    if (isOldWay) propOrState(defaultColumns);

    if (
      propOrState(null, true) &&
      (propOrState(null, true) as any) !== "undefined" &&
      (propOrState(null, true) as any) !== "null"
    )
      return;

    propOrState(defaultColumns);
  }, []);

  const downloadExcel = useCallback(async (): Promise<void> => {
    const [start, end] = range;

    const selectedColumnsConfig: ExportOrderColumns = {};

    (propOrState(null, true) as ColumnTableList<string>)
      .filter((item) => item.path?.trim())
      .forEach(({ path, title }) => {
        selectedColumnsConfig[path] = {
          displayName: title?.trim(),
        };
      });

    /** Check if a module is selected from where to export the orders */
    if (SelectModule) {
      try {
        await form.validateFields();
      } catch (error) {
        const errors = error?.errorFields?.reduce((acc, field) => {
          const errors = field?.errors;

          return [...acc, ...errors];
        }, []);

        console.error(...errors);

        return;
      }
    }

    setLoading(true);

    try {
      await exportOrders({
        fromSeconds: start.unix(),
        toSeconds: end.unix(),
        moduleIds: SelectModule ? [selectedModuleId] : moduleIds,
        schoolIds: [schoolId],
        returnType: "xls",
        columns: selectedColumnsConfig,
        productPerLine,
        groupNames: child?.groupName ? [child.groupName] : [],
        childId: child?.id,
        schoolId,
      });
    } catch (e) {
      console.error(e);
      message.error({ key: "error-export-orders", content: e?.message });
    } finally {
      setLoading(false);
    }
  }, [range, schoolId, columnSettings, selectedModuleId, child?.id]);

  const onChangeProductPerLine = (checked: boolean) => {
    const orderCol = {
      title: t("translation:order-short"),
      path: "order",
    };

    const orderPathIsAlreadyPresent = Boolean(
      checked &&
        propOrState(null, true).find((col) => col.path === orderCol.path)
    );

    const newColumns = checked
      ? [orderCol, ...(propOrState(null, true) as ColumnTableList<string>)]
      : (propOrState(null, true) as ColumnTableList<string>).filter(
          (col) => col.path !== orderCol.path
        );

    if (!orderPathIsAlreadyPresent) propOrState(newColumns);

    setProductPerLine(checked);
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={modalTitle}
      style={{ maxWidth: "36rem" }}
    >
      <>
        {SelectModule ? <SelectModule {...selectModuleProps} /> : null}
        <Steps current={-1} direction="vertical" className="text-text-primary">
          <Steps.Step
            title={t("mealplan2:select-time")}
            description={
              <DatePicker.RangePicker
                allowClear={false}
                value={range}
                onChange={setRange}
                ranges={{
                  [t("mealplan2:this-month")]: [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  [t("mealplan2:last-month")]: [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "month").endOf("month"),
                  ],
                  [t("translation:this-week")]: [
                    moment().startOf("week"),
                    moment().endOf("week"),
                  ],
                  [t("mealplan2:last-week")]: [
                    moment().subtract(1, "week").startOf("week"),
                    moment().subtract(1, "week").endOf("week"),
                  ],
                }}
              />
            }
          />
          <Steps.Step
            title={t("mealplan2:select-columns")}
            description={
              <ColumnTable
                columns={propOrState(null, true) as ColumnTableList<string>}
                setColumns={setColumnSettings}
                setAllColumns={setAllColumns}
                allColumns={allColumns}
                options={
                  options as ColumnTableList<keyof ExportOrderColumnsBase>
                }
                moduleId={selectedModuleId}
                tableClassNames="bg-white"
              />
            }
          />
          <Steps.Step
            title={t("translation:product-per-line")}
            description={
              <Space>
                <Switch
                  checked={productPerLine}
                  onChange={onChangeProductPerLine}
                />
                <p className="text-text-primary">
                  {t("translation:product-per-line-description")}
                </p>
              </Space>
            }
          />
          <Steps.Step
            description={
              <Button type="primary" loading={loading} onClick={downloadExcel}>
                {t("mealplan2:export-data")}
              </Button>
            }
          />
        </Steps>
      </>
    </DrawerOrModal>
  );
};

export default ExportOrdersModal;
