import { faCheck } from "@fortawesome/pro-duotone-svg-icons";
import { faUmbrellaBeach, faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  findHoliday,
  getProductName,
  getWeekDays,
  isDatePastDeadline,
  Timestamp,
} from "@kanpla/system";
import {
  CombinedOffer,
  FlexStandard,
  Holiday,
  HolidaysRecurring,
  IBaseProducts,
  IGetCurrentProductsProps,
  Week,
} from "@kanpla/types";
import classnames from "classnames";
import { isEmpty } from "lodash";
import moment from "moment";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

interface Props {
  selectedProducts: IBaseProducts;
  getCurrentProducts: (props: IGetCurrentProductsProps) => void;
  plainProducts: CombinedOffer;
  week: Week;
  deadline: number;
  deadlineWeekRelative: boolean;
  deadlineExcludingWeekends: boolean;
  selectProductOpen: boolean;
  periodsRanges: Array<
    FlexStandard["holidays"][0] & { ranges: number[] | Date[] }
  >;
  setOpenStandardHolidays: Dispatch<SetStateAction<boolean>>;
  activeHolidays: (Holiday | HolidaysRecurring)[];
}

export const MenuViewMobile = (props: Props) => {
  const {
    selectedProducts,
    getCurrentProducts,
    plainProducts,
    week,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    selectProductOpen,
    periodsRanges,
    setOpenStandardHolidays,
    activeHolidays,
  } = props;

  const { t, i18n } = useTranslation(["translation", "libs", "flex"]);

  const dictionaries = [
    t("libs:week-days.monday"),
    t("libs:week-days.tuesday"),
    t("libs:week-days.wednesday"),
    t("libs:week-days.thursday"),
    t("libs:week-days.friday"),
  ];

  const days = isEmpty(i18n) ? dictionaries : getWeekDays(i18n.language);

  const [currentDayIndex, setCurrentDayIndex] = useState(-1);

  useEffect(() => {
    if (!selectProductOpen) setCurrentDayIndex(-1);
  }, [selectProductOpen]);

  const isHolidayFromAdmin = useCallback(
    (dayIndex: number) => {
      const holiday = activeHolidays?.[dayIndex];

      return Boolean(holiday);
    },
    [JSON.stringify(activeHolidays)]
  );

  return (
    <div className="pt-1">
      {days.map((label, dayIndex) => {
        const { isHoliday } = findHoliday({
          periodsRanges,
          dateSeconds: week?.[dayIndex]?.seconds.toString(),
        });

        return (
          <DayMenuViewMobile
            key={dayIndex.toString()}
            label={label}
            selectedProducts={selectedProducts}
            getCurrentProducts={getCurrentProducts}
            plainProducts={plainProducts}
            daySeconds={week?.[dayIndex]?.seconds}
            isItemFromStandard={
              selectedProducts?.[week?.[dayIndex]?.seconds]?.["isStandard"] &&
              selectedProducts?.[week?.[dayIndex]?.seconds]?.id !== "noLunch"
            }
            isPast={isDatePastDeadline({
              date: new Timestamp(Number(week?.[dayIndex]?.seconds), 0),
              deadline,
              deadlineWeekRelative,
              deadlineExcludingWeekends,
            })}
            currentDayIndex={currentDayIndex}
            setCurrentDayIndex={setCurrentDayIndex}
            dayIndex={dayIndex}
            isHoliday={isHoliday}
            setOpenStandardHolidays={setOpenStandardHolidays}
            isHolidayFromAdmin={isHolidayFromAdmin(dayIndex)}
          />
        );
      })}
    </div>
  );
};

export const DayMenuViewMobile = ({
  label,
  selectedProducts,
  getCurrentProducts,
  plainProducts,
  daySeconds,
  isItemFromStandard,
  isPast,
  dayIndex,
  currentDayIndex,
  setCurrentDayIndex,
  isHoliday,
  setOpenStandardHolidays,
  isHolidayFromAdmin,
}) => {
  const { t, i18n } = useTranslation(["translation", "flex", "flex-bulk"]);

  const today = useMemo(() => {
    return moment().startOf("day").unix() === Number(daySeconds)
      ? ` (${t("translation:today")})`
      : "";
  }, [daySeconds, i18n?.language]);

  const displayProduct = () => {
    const dateSeconds = Object.entries(selectedProducts || {}).find(
      ([dateSeconds, _]) => {
        const dayName = moment
          .unix(Number(dateSeconds))
          .format("dddd")
          .toLowerCase();

        const isSameDay = dayName === (label as string).toLowerCase();

        return isSameDay;
      }
    )?.[0];

    if (!dateSeconds) return null;

    const productId = selectedProducts[dateSeconds].id;

    const product = plainProducts.find((product) => product.id === productId);

    const decisionExists = Boolean(
      Object.entries(selectedProducts).find(
        ([_, product]) => product["id"] === productId
      )
    );

    const wrapperClassNames = classnames({
      "bg-secondary-dark": !decisionExists && !isHoliday,
      "bg-primary-main": decisionExists && !isHoliday,
      "bg-blue-600": isItemFromStandard && !isHoliday,
      "bg-warning-main": isHoliday || isHolidayFromAdmin,
    });

    const productInfos = getProductName({
      product,
      date: dateSeconds,
      noLunch: t("translation:no-breakfast"),
    });

    const infos = () => {
      return {
        Name: () => (
          <p className="text-lg">
            {isHoliday
              ? t("flex:holiday")
              : isHolidayFromAdmin
              ? t("flex-bulk:kitchen-closed")
              : productInfos.name}
          </p>
        ),
        Icon: () => (
          <FontAwesomeIcon
            icon={
              isHoliday
                ? faUmbrellaBeach
                : isHolidayFromAdmin
                ? faWarning
                : faCheck
            }
            color="white"
            className="text-lg"
          />
        ),
      };
    };

    const { Name, Icon } = infos();

    return (
      <>
        <div
          style={{
            minWidth: "36px",
          }}
          className={`w-9 aspect-square rounded-full self-center flex items-center justify-center ${wrapperClassNames}`}
        >
          {(decisionExists || isHoliday) && <Icon />}
        </div>
        <div>
          <p className="text-sm text-secondary-dark">{`${label}${today}`}</p>
          <Name />
        </div>
      </>
    );
  };

  const dayClassNames = classnames({
    "opacity-30 pointer-events-none": isPast || isHolidayFromAdmin,
    "shadow-lg": currentDayIndex === dayIndex,
  });

  const onClickDay = () => {
    setCurrentDayIndex(dayIndex);
  };

  return (
    <div
      onClick={() => {
        if (isHoliday) {
          setOpenStandardHolidays(true);

          return;
        }
        getCurrentProducts({ label });
        onClickDay();
      }}
      className={`flex px-5 py-4 gap-x-4 items-center rounded-lg transition-all cursor-pointer ${dayClassNames}`}
    >
      {displayProduct()}
    </div>
  );
};
