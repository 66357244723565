import moment from "moment";

interface Props {
  dateSeconds: string;
  todayString: string;
}

/**
 * This method returns a string that represent a day (current or not).
 * E.g.: today is August 22 -> Monday (Today) and August 23 will be -> Tuesday
 */
export const displayDayString = ({ dateSeconds, todayString }: Props) => {
  const correctSeconds =
    moment().startOf("day").unix() + moment().utcOffset() * 60;

  const isToday = Number(dateSeconds) === correctSeconds;

  const day = `${moment.unix(Number(dateSeconds)).format("dddd [d. ]DD/MM")}${
    isToday ? ` (${todayString.toLocaleLowerCase()})` : ""
  }`;

  return day;
};
