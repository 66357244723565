import { configToDisplayString, getOrderConfigs } from "@kanpla/system";
import { CombinedOfferItem, OrderConfig, OrderOrder } from "@kanpla/types";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanContext } from "../../";
// import Image from "next/image";

interface Props {
  price: number;
  productId: string;
  config: OrderConfig;
  multiple?: boolean;
}

interface ReceiptItemProps {
  items: OrderOrder;
}

export const SingleReceiptItem = (props: Props) => {
  const { price, config, productId, multiple = false } = props;
  const { t, i18n } = useTranslation(["translation"]);
  const { items } = useContainer(MealplanContext);

  const product =
    items?.find((p) => p.productId === productId) || ({} as CombinedOfferItem);

  const { photo, name } = product;

  if (multiple)
    return (
      <div className="w-full flex items-center mt-6">
        <div className="w-full relative">
          {photo && (
            <img
              src={`${photo}?w=300`}
              alt={name}
              className="object-cover w-full object-center rounded-lg"
              style={{ height: "100px" }}
            />
          )}
          {config.amount > 1 && (
            <div className="bg-primary-main -top-4 -left-4 rounded-full flex items-center justify-center absolute text-xl text-text-secondary font-semibold w-12 h-12 p-2">
              {config.amount}x
            </div>
          )}
        </div>
        <div className="w-full ml-4 flex flex-col">
          <h1 className="h400 mt-2">{name}</h1>
          {!isEmpty(config) && (
            <p className="text-text-secondary whitespace-nowrap mt-1">
              {configToDisplayString(config, false, i18n.language)}
            </p>
          )}
          <p className="text-primary-main text-md mt-1">
            {t("translation:amount-with-value", { value: price })}
          </p>
        </div>
      </div>
    );

  return (
    <div className="w-full flex flex-col items-center relative mt-4">
      <div className="w-full relative h-52">
        {photo && (
          <img
            src={`${photo}?w=300`}
            alt={name}
            className="w-full h-full rounded-lg object-cover object-center"
          />
        )}
      </div>
      <h1 className="h600 mt-2 text-center text-text-primary">{name}</h1>
      <p className="text-text-secondary whitespace-nowrap">
        {configToDisplayString(config, false, i18n.language)}
      </p>
      {config.amount > 1 && (
        <div className="bg-primary-main -top-4 -left-4 rounded-full flex items-center justify-center absolute text-xl text-primary-contrast font-semibold w-12 h-12 p-2">
          {config.amount}x
        </div>
      )}
    </div>
  );
};

const ReceiptItems = (props: ReceiptItemProps) => {
  const { items } = props;
  const configs = getOrderConfigs(items);

  return (
    <>
      {configs.map(({ productId, price, config }) => (
        <SingleReceiptItem
          productId={productId}
          key={productId}
          price={price}
          config={config}
          multiple={configs.length > 1}
        />
      ))}
    </>
  );
};

export default ReceiptItems;
