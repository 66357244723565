import {
  faBellSlash,
  faMessageSlash,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

interface Props {
  type: "popup" | "message";
}

const EmptyView = (props: Props) => {
  const { type, noIllustration = false } = props;
  const { t } = useTranslation(["components"]);

  const isMessage = type === "message";

  return (
    <div className="flex flex-col w-full h-full justify-center items-center mt-2">
      <p className="w-full text-text-disabled text-xs px-4 pb-2 text-center">
        <FontAwesomeIcon
          icon={isMessage ? faBellSlash : faMessageSlash}
          size="sm"
          className="mr-2"
        />
        {isMessage
          ? t("components:no-recent-notifications")
          : t("components:no-recent-popups")}
      </p>
    </div>
  );
};

export default EmptyView;
