import { ExportOrdersResponseItem, Module, Partner } from "@kanpla/types";
import { groupBy } from "lodash";

interface Props {
  modules: Module[];
  partner?: Partner;
  dictionaries?: ExportOrdersResponseItem;
  productPerLine?: boolean;
}

export const defineOptions = ({
  modules,
  partner = null,
  dictionaries = null,
  productPerLine,
}: Props) => {
  const moduleIds = (modules || []).map((module) => module?.id);
  const emptyExportOrderInstance: ExportOrdersResponseItem = dictionaries
    ? dictionaries
    : ({
        moduleName: "Modulets navn",
        modulePaymentMethod: "Betalingsmetode",
        salesplaceName: "Salgsstedets navn",
        displayName: "Brugerens navn",
        groupName: "Brugerens inddeling",
        childId: "Brugerens ID",
        email: "Brugerens email",
        dateSeconds: "Bestillingsdato",
        billedDateSeconds: "Faktureringsdato",
        order: "Bestilte produkter",
        orderId: "Bestilling ID",
        priceTotalIncludingTax: "Pris inkl. moms",
        priceTotalExcludingTax: "Pris ekskl. moms",
        taxTotal: "Moms",
        priceIndividualIncludingTax: "Pris pr. stk. inkl. moms",
        priceIndividualExcludingTax: "Pris pr. stk. ekskl. moms",
        taxIndividual: "Moms pr. stk.",
        productsTotal: "Antal produkter",
        orderInfo_name: "Bestillingens navn",
        deliveryTime: "Leveringstidspunkt",
        invoiceReference: "Faktura reference",
      } as const);

  const setCustomOptions = () => {
    const activeModulesWithTextInputs = modules.filter(
      (m) =>
        moduleIds.includes(m.id) &&
        m?.plugins?.textInput?.active &&
        m?.plugins?.textInput?.fields?.length
    );
    const textInputs = activeModulesWithTextInputs
      .map((module) => module?.plugins?.textInput?.fields)
      .flat();

    const groupedInputs = groupBy(textInputs, "title");

    const options = Object.entries(groupedInputs).map(([title, inputs]) => ({
      path: `custom_${inputs.map((input) => input.key).join("|")}`,
      title,
      group: "Brugerdefinerede felter",
    }));

    return options;
  };

  const setDefinedOptions = () => {
    const options = Object.entries(emptyExportOrderInstance)
      .filter(
        ([path]) =>
          productPerLine ||
          ![
            "priceIndividualIncludingTax",
            "priceIndividualExcludingTax",
            "taxIndividual",
          ].includes(path)
      )
      .map(([path, title]) => ({
        path,
        title,
        group: "Definerede felter",
      }));

    if (partner?.proPlugins?.customProductId)
      options.push({
        path: "customId",
        title: "Brugerdefinerede produkt ID'er",
        group: "Definerede felter",
      });

    return options;
  };

  const customOptions = setCustomOptions();
  const definedOptions = setDefinedOptions();

  return {
    customOptions: customOptions || [],
    definedOptions: definedOptions || [],
  };
};
