import { constructNewUrl, getTodayTimestamp, Timestamp } from "@kanpla/system";
import { Homescreen } from "@kanpla/types";
import { DisplayAttributes, DotDotDot } from "@kanpla/ui";
import moment from "moment";
import Link from "next/link";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { HomescreenContext } from "..";
import { AppContext } from "../../contextProvider";
import SectionHeader from "../elements/SectionHeader";
import classnames from "classnames";

interface ContentProps {
  productId: string;
  moduleId: string;
  link?: string;
  hideBorder?: boolean;
}

const LinkWrapper = ({ link, children }) => {
  if (link) return <Link href={link}>{children}</Link>;

  return children;
};

const blockClasses = "text-center p-6 py-12 rounded-lg bg-background-secondary";

export const FlexMenuContent = ({
  productId,
  moduleId,
  link,
  hideBorder,
}: ContentProps) => {
  const { t, i18n } = useTranslation(["homescreen"]);

  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const { schoolId, innerAppLoading, getOffer, supplier } =
    useContainer(AppContext);
  const offer = getOffer(moduleId);
  const todaySeconds = getTodayTimestamp({ Timestamp }).seconds;

  const { items } = offer || {};

  const product = items?.find((item) => item.productId === productId);

  const blockClassesCombined = classnames(
    blockClasses,
    !hideBorder && "md:border border-divider-main"
  );

  if (!product)
    return (
      <div className={blockClassesCombined}>
        <p>{t("homescreen:no-menu-for-today")}</p>
      </div>
    );

  const today = product?.dates?.[todaySeconds]?.menu;

  const productDates = Object.keys(product?.dates || {})?.map((date) =>
    parseInt(date)
  );
  const nextAvailableSeconds = productDates
    .sort()
    .find((d) => d > todaySeconds);

  const nextMenu = product?.dates?.[nextAvailableSeconds]?.menu;

  if (innerAppLoading)
    return (
      <p>
        {t("homescreen:loading-today-menu")} <DotDotDot />
      </p>
    );

  if (today?.name === "X" || (!today && !nextMenu))
    return (
      <div className={blockClassesCombined}>
        <p>{t("homescreen:kitchen-do-not-produce", { value: product.name })}</p>
      </div>
    );

  const moduleUrlID = link?.split("/m/")?.[1]?.split("/")?.[0];
  const redirectToUrl = constructNewUrl(schoolId, moduleUrlID);

  // Menu for next day available
  if (!today && nextMenu)
    return (
      <LinkWrapper link={redirectToUrl}>
        <div className={blockClassesCombined}>
          <h4 className="h200 text-text-disabled text-sm">
            {t("homescreen:today-menu-not-available", {
              value: moment.unix(nextAvailableSeconds).format("DD. MMM YYYY"),
            })}
          </h4>
          <h3 className="h600 text-text-primary">
            {nextMenu?.name || product?.name}
          </h3>
          {nextMenu?.description && (
            <p className="text-text-primary">{nextMenu.description}</p>
          )}

          <DisplayAttributes
            attributes={{
              ...nextMenu?.allergens,
              ...nextMenu?.pictograms,
            }}
            mode="all"
            supplier={supplier}
            className="justify-center"
          />
        </div>
      </LinkWrapper>
    );

  return (
    <LinkWrapper link={redirectToUrl}>
      <div className={blockClassesCombined}>
        <h3 className="h600 text-text-primary">
          {today?.name || product?.name}
        </h3>
        {today?.description && (
          <p className="text-text-primary">{today.description}</p>
        )}
        <DisplayAttributes
          attributes={{
            ...today?.allergens,
            ...today?.pictograms,
          }}
          mode="all"
          supplier={supplier}
          className="justify-center"
        />
      </div>
    </LinkWrapper>
  );
};

interface Props {
  singleBlock: Homescreen.ContentInner;
}

const FlexMenu = ({ singleBlock }: Props) => {
  const { content } = useContext(HomescreenContext);
  const { schoolId } = useContainer(AppContext);

  if (!singleBlock || !singleBlock.props) return null;

  const { moduleId, productId, hideBorder } = singleBlock.props;

  if (!moduleId || !productId) return null;

  const buttonArea = content[singleBlock?.linkedNodes?.buttonArea];
  const buttonProps = content[buttonArea?.nodes?.[0]];

  const url = buttonProps.props?.url;

  const moduleUrlID = url?.split("/m/")?.[1]?.split("/")?.[0];

  const redirectToUrl = constructNewUrl(schoolId, moduleUrlID);

  return (
    <section>
      <SectionHeader
        buttonProps={buttonProps}
        title={singleBlock.props?.title}
        subtitle={singleBlock.props?.subtitle}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-2 md:py-4">
        <FlexMenuContent
          link={redirectToUrl}
          productId={productId}
          moduleId={moduleId}
          hideBorder={hideBorder}
        />
      </div>
    </section>
  );
};

export default FlexMenu;
