import { faTriangleExclamation } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrderingContext } from "@kanpla/ordering";
import { ActionsAuthenticationModal, PaymentProvider } from "@kanpla/types";
import { useConfirmation } from "apps/frontend/components/confirmProvider";
import { AppContext } from "apps/frontend/components/contextProvider";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanContext } from "../..";
import Slider from "../elements/Slider";

interface Props {
  onPurchase: () => Promise<void>;
  onlyCredit: boolean;
  onlyPosoneCredit: boolean;
  canProcessPayment: boolean;
  loading: boolean;
  selectedPaymentMethod: PaymentProvider;
}

const useBasketActions = (props: Props) => {
  const {
    onPurchase,
    onlyCredit,
    onlyPosoneCredit,
    canProcessPayment,
    loading,
    selectedPaymentMethod,
  } = props;
  const {
    auth,
    setDataAuthenticationModal,
    setOpenAuthenticationModal,
    mobile,
    balance,
    posoneBalance,
    isSuperadmin,
    child,
    isOverridingUser,
    userId,
  } = useContainer(AppContext);

  const { basketContainerUtils, basket } = useContainer(OrderingContext);

  const { module } = useContainer(MealplanContext);

  const confirm = useConfirmation();

  const basketPrice = basketContainerUtils.totalPrice;

  const { t } = useTranslation([
    "mealplan2",
    "payment",
    "translation",
    "anonymous",
  ]);

  const handleOnPurchase = async () => {
    if (isSuperadmin && isOverridingUser) {
      await confirm({
        title: "You are about to order on behalf of the user!",
        closableOutside: false,
        children: (
          <>
            <p className="text-center">
              Are you sure you want to place this order on behalf of this child?
            </p>
            <p className="text-sm text-center text-text-disabled mt-2">
              {child?.displayName || child.name}
              <br />
              UID: {userId}
            </p>
          </>
        ),
        extraTopContent: (
          <div className="w-full flex mb-2 justify-center">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="text-orange-500"
              size="2x"
            />
          </div>
        ),
      });
    }

    await onPurchase();
  };

  /** Set the data of the authentication modal when the user is about to order */
  const setDataForAuthenticationModalAndOpenModal = (
    title: string,
    subtitle: string,
    action: ActionsAuthenticationModal
  ) => {
    setDataAuthenticationModal({
      title,
      subtitle,
      action,
    });
    setOpenAuthenticationModal(true);
  };

  const disableSwipeIfEmptyOnRegistering =
    module?.flow === "registering" && isEmpty(basket);

  const authenticatedActions = [
    (module.paymentMethod !== "billing" || module?.flow === "registering") &&
    canProcessPayment &&
    mobile ? (
      <div className="w-full">
        <Slider
          submit={handleOnPurchase}
          checkoutLoading={loading}
          label={
            module?.flow === "registering"
              ? t("mealplan2:registration.swipe-to-register")
              : t("mealplan2:swipe-to-pay")
          }
          disabled={
            !selectedPaymentMethod ||
            loading ||
            disableSwipeIfEmptyOnRegistering
          }
        />
      </div>
    ) : (
      {
        label:
          module.paymentMethod === "billing"
            ? t("translation:approve")
            : canProcessPayment && basketPrice > balance
            ? t("payment:pay-and-checkout", {
                price: basketPrice - balance,
              })
            : t("payment:checkout"),
        onClick: handleOnPurchase,
        type: "primary",
        loading,
        disabled:
          loading ||
          (onlyCredit && basketPrice > balance) ||
          (onlyPosoneCredit && basketPrice > posoneBalance),
      }
    ),
  ];

  const unauthenticatedActions = [
    !auth.user && {
      label: t("translation:log-in"),
      onClick: () => {
        setDataForAuthenticationModalAndOpenModal(
          t("translation:welcome-back"),
          t("translation:log-in-to-your-account"),
          "login"
        );
      },
      type: "primary",
      dataCy: "login-anonymous",
    },
    {
      label: t("translation:sign-up"),
      onClick: () => {
        setDataForAuthenticationModalAndOpenModal(
          t("translation:sign-up"),
          t("anonymous:modal.subtitles.info-authenticate-signup"),
          "signup"
        );
      },
      type: "ghost",
      dataCy: "signup-anonymous",
    },
  ];

  return {
    actions: auth?.user?.uid ? authenticatedActions : unauthenticatedActions,
  };
};

export default useBasketActions;
