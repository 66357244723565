import { isBelowMinimum } from "@kanpla/system";
import { CombinedOfferItem, DayIndex, OrderConfig } from "@kanpla/types";
import classnames from "classnames";
import { useCallback } from "react";
import { useContainer } from "unstated-next";
import { FlexBulkContext } from ".";
import { OrderingContext } from "../context";
import Input from "./Input";

interface Props {
  getValue: (dayIndex: DayIndex) => number;
  disabled?: boolean;
  expandProduct?: () => void;
  choice: OrderConfig["options"];
  product: CombinedOfferItem;
}

const WeekInputs = ({
  getValue,
  disabled = false,
  choice,
  expandProduct = () => null,
  product,
}: Props) => {
  const { week, school } = useContainer(OrderingContext);
  const { orders } = useContainer(FlexBulkContext);

  const checkIsBelow = useCallback(
    (dayIndex: number) => {
      const isBelow = isBelowMinimum({
        school,
        localOrder: orders?.[dayIndex] || {},
      });

      return isBelow;
    },
    [JSON.stringify(orders), school]
  );

  return (
    <>
      {week.map((date, dayIndex: DayIndex) => {
        const amount = getValue(dayIndex);
        const dateSeconds = week[dayIndex]?.seconds;

        const disabledDay =
          product?.[dayIndex]?.disabled ||
          !product?.dates?.[dateSeconds]?.available ||
          product?.dates?.[dateSeconds]?.menu?.name === "X";

        const isBelow = checkIsBelow(dayIndex);

        const classNames = classnames({
          "flex-1 text-center": true,
        });

        return (
          <div key={date.seconds} className={classNames}>
            <Input
              dayIndex={dayIndex}
              product={product}
              choice={choice}
              expandProduct={expandProduct}
              value={amount}
              disabled={disabledDay || disabled}
              isBelow={isBelow}
            />
          </div>
        );
      })}
    </>
  );
};

export default WeekInputs;
