import { faBell } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, ChildView, Popper } from "@kanpla/ui";
import classnames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { NotificationCenterContext } from ".";
import { AppContext } from "../contextProvider";
import Content, { AllMessages } from "./Content";

const NotificationCenter = () => {
  const { mobile, notificationBadge } = useContainer(AppContext);
  const { notifications, allMessages, popups } = useContainer(
    NotificationCenterContext
  );

  const { t } = useTranslation(["components"]);

  const [open, setOpen] = useState(false);

  const NotificationButton = (
    <Badge
      count={notificationBadge ? 1 : 0}
      hidden={!notificationBadge}
      dot
      offset={[-11, 4]}
      useRedColor
    >
      <FontAwesomeIcon
        icon={faBell}
        className={classnames(
          "text-primary-main text-2xl mx-2 hover:opacity-80 transition-opacity ease-in-out",
          notificationBadge && "animate-ring"
        )}
        onClick={() => mobile && setOpen(true)}
      />
    </Badge>
  );

  if (mobile)
    return (
      <>
        {NotificationButton}
        <ChildView
          open={open}
          setOpen={setOpen}
          title={t("components:notifications")}
          noPadding
        >
          <Content
            allMessages={allMessages as AllMessages}
            notifications={notifications}
            popups={popups}
            isViewing={open}
          />
        </ChildView>
      </>
    );

  return (
    <Popper
      actionElement={NotificationButton}
      withPadding={false}
      minWidth={410}
      maxWidth={410}
      className="pb-8"
      lockOnOpen
      zIndex="z-30"
    >
      <div className="flex flex-col">
        <h1 className="h500 px-4 pt-4">{t("components:notifications")}</h1>
        <Content
          allMessages={allMessages as AllMessages}
          notifications={notifications}
          popups={popups}
        />
      </div>
    </Popper>
  );
};

export default NotificationCenter;
