import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

export const ChildView = ({
  open,
  setOpen,
  children,
  backLabel = undefined,
  title,
  isFullWidth = false,
  noPadding = false,
}) => {
  const { t } = useTranslation(["translation"]);
  const [shadow, setShadow] = useState(false);

  let maxWidth;
  isFullWidth ? (maxWidth = "100wh") : (maxWidth = 550);

  return (
    <SwipeableDrawer
      className="print:hidden"
      sx={{
        width: "100%",
        maxWidth,
        flexShrink: 0,
        zIndex: 31,
        overscrollBehavior: "contain",
        "& .MuiDrawer-paper": {
          width: "100%",
          maxWidth,
          zIndex: 31,
          boxSizing: "border-box",
          backgroundColor: "var(--palette-background-primary, #ffffff)",
          overscrollBehavior: "contain",
        },
      }}
      anchor="right"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      PaperProps={{
        onScroll: (a) =>
          setShadow(
            a.currentTarget
              .querySelector(".scrolled-body")
              .getBoundingClientRect().top < 25
          ),
      }}
    >
      <div
        className={`px-4 pb-4 sticky top-0 z-20 kanpla-bg-primary text-text-primary transition-shadow duration-500 ${
          shadow ? "shadow" : ""
        }`}
        style={{
          paddingTop: `calc(var(--status-bar-height) + 1rem)`,
        }}
      >
        <div className="text-center relative grid grid-cols-5">
          <button
            className=""
            onClick={() => setOpen(false)}
            style={{ letterSpacing: `-0.0125em`, top: 2 }}
          >
            <p className="whitespace-nowrap font-regular text-primary-main flex items-center ">
              <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
              <span className="overflow-ellipsis overflow-hidden text-sm">
                {backLabel || t("translation:back")}
              </span>
            </p>
          </button>
          <div
            className="font-medium text-lg h800 col-span-3"
            style={{
              letterSpacing: `-0.0125em`,
            }}
          >
            {title}
          </div>
        </div>
      </div>
      <div
        className={classnames(
          "lg:pt-12 bg-background-primary text-text-primary scrolled-body flex-1",
          !noPadding ? "py-8 px-3 sm:px-4 md:px-6" : "py-2 px-0"
        )}
        data-cy="menu"
      >
        {children}
      </div>
    </SwipeableDrawer>
  );
};
