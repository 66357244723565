import { OrderingContext } from "@kanpla/ordering";
import { LoadSingleOfferProps, LoadSingleOfferResult } from "@kanpla/services";
import {
  getDayIndexFromSeconds,
  getDaysInRange,
  getMidnightSeconds,
  getWeekArray,
  getWeekSeconds,
  Timestamp,
  useFetch,
} from "@kanpla/system";
import {
  CombinedOfferItem,
  DayIndex,
  OrderConfig,
  OrderMealplan,
} from "@kanpla/types";
import { message } from "antd";
import moment, { Moment } from "moment";
import { useRouter } from "next/router";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanContext } from "..";
import { AppContext } from "../../contextProvider";
import OrderForAmountOfPeople from "./OrderForAmountOfPeople";
import Receipt from "./Receipt";
import History from "./views/History";
import Ordering from "./views/Ordering";
import Overview from "./views/Overview";

interface ContextProps {
  currentView: string;
  setCurrentView: Dispatch<SetStateAction<string>>;
  editingOrder: OrderMealplan;
  setEditingOrder: Dispatch<SetStateAction<OrderMealplan>>;
  isConfirming: boolean;
  setIsConfirming: Dispatch<SetStateAction<boolean>>;
  hasOrderForAmountOfPeoplePlugin: boolean;
  meetingOffer: LoadSingleOfferResult;
  setCalendarDate: Dispatch<SetStateAction<Moment>>;
  /**
   * Use instead of `hasRequiredProduct` from mealplan context,
   * since that one will be undefined with custom offer items (loading forward in time)
   */
  meetingHasRequiredProduct: boolean;
}

interface Props {
  moduleId?: string;
}

export interface Views {
  [key: string]: {
    content: ReactNode;
  };
}

export interface BasketProduct {
  amount: number;
  price: number;
  productId: string;
  config?: OrderConfig;
}

export const MealplanMeetingContext = createContext<Partial<ContextProps>>({});

const MealplanMeeting = (props: Props) => {
  const { t, i18n } = useTranslation(["mealplan2"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const {
    setTimeNavigation,
    setDayIndex,
    setWeek,
    dayIndex,
    week,
    moduleId,
    schoolId,
    childId,
    activePlugins,
    module,
  } = useContainer(AppContext);
  const { setAllowedOrderIds, deadline } = useContainer(MealplanContext);
  const { basketContainerUtils, setBasket, basket } =
    useContainer(OrderingContext);
  const { receiptOpen, setReceiptOpen, newOrderInfo } =
    useContainer(MealplanContext);

  const [currentView, setCurrentView] = useState<string>("overview");
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [calendarDate, setCalendarDate] = useState<Moment>(moment());

  const { data: meetingOffer } = useFetch<
    LoadSingleOfferProps,
    LoadSingleOfferResult
  >(
    "offers/loadSingleOffer",
    {
      moduleId,
      schoolId,
      childId,
      dateSeconds: (
        getDaysInRange(
          moment(calendarDate).startOf("month").startOf("day"),
          moment(calendarDate).endOf("month").add(2, "days").startOf("day"),
          "unix"
        ) as Array<number>
      ).map((seconds) => getMidnightSeconds(seconds)),
    },
    { refreshInterval: 60000 }
  );

  // Ignore all other orders for the day (except the one editing, in case)
  const [editingOrder, setEditingOrder] = useState<OrderMealplan>(null);

  const meetingHasRequiredProduct = useMemo(
    () =>
      module?.plugins?.requiredProduct?.active &&
      (meetingOffer?.offer?.items as CombinedOfferItem[])?.some(
        (p: CombinedOfferItem) =>
          p.productId === module?.plugins?.requiredProduct?.productId
      ),
    [
      meetingOffer?.offer?.items,
      module?.plugins?.requiredProduct?.active,
      module?.plugins?.requiredProduct?.productId,
    ]
  );

  const router = useRouter();

  /** Automatic ordering via a link */
  const queryTrigger = JSON.stringify(router.query);
  useEffect(() => {
    const date = router.query?.date;
    if (!date) return;

    // Ignore if date is unix because of the new way date gets registered in the URL
    if (moment.unix(parseInt(date as string)).isValid()) return;

    const momentDate = moment.parseZone(`${date}`, "DDMMYYYY", i18n.language);

    const currentlySelectedDate = week?.[dayIndex]?.seconds;

    if (moment.unix(currentlySelectedDate).isSame(momentDate, "day")) return;

    if (!momentDate.isValid()) {
      message.warning(t("mealplan2:message.warning.invalid-date"));
      return;
    }

    const dayDeadline = momentDate.clone().startOf("day").add(deadline, "s");
    // After deadline
    if (dayDeadline.isBefore(moment())) {
      message.warning(t("mealplan2:message.warning.too-late"));
      return;
    }

    const dateSeconds = momentDate.unix();
    const weekSeconds = getWeekSeconds(dateSeconds);
    const weekArray = getWeekArray(weekSeconds, Timestamp);
    const localDayIndex = parseInt(
      getDayIndexFromSeconds(dateSeconds).toFixed(0)
    );

    // Disallow weekend
    if (localDayIndex === 5 || localDayIndex === 6) {
      message.warning(t("mealplan2:message.warning.cant-book-for-weekend"));
      return;
    }

    setDayIndex(localDayIndex as DayIndex);
    setWeek(weekArray);
    setCurrentView("ordering");
    setEditingOrder(null);
  }, [queryTrigger, week, dayIndex]);

  useEffect(() => {
    if (editingOrder) {
      setBasket(editingOrder.order);
      setAllowedOrderIds([editingOrder.id]);
    } else {
      setAllowedOrderIds([]);
      basketContainerUtils.reset();
    }
  }, [editingOrder?.id, currentView]);

  const views: Views = {
    overview: {
      content: <Overview />,
    },
    ordering: {
      content: <Ordering />,
    },
    history: {
      content: <History />,
    },
  };

  useEffect(() => {
    setTimeNavigation("none");
  }, [currentView, setTimeNavigation]);

  useEffect(() => {
    setTimeNavigation("none");
  }, []);

  return (
    <MealplanMeetingContext.Provider
      value={{
        currentView,
        setCurrentView,
        editingOrder,
        setEditingOrder,
        isConfirming,
        setIsConfirming,
        setCalendarDate,
        meetingOffer,
        meetingHasRequiredProduct,
      }}
    >
      {views[currentView].content}
      {activePlugins.orderForAmountOfPeople && <OrderForAmountOfPeople />}

      <Receipt
        open={receiptOpen}
        setOpen={setReceiptOpen}
        order={basket}
        orderInfo={newOrderInfo}
        date={week[dayIndex]}
      />
    </MealplanMeetingContext.Provider>
  );
};

export default MealplanMeeting;
