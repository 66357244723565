import { ScheduledNotification } from "@kanpla/types";
import { Tabs } from "antd";
import {
  GroupType,
  Notifications,
} from "apps/frontend/pages/api/internal/notifications/fetchNotifications";
import { NormalizedPopup } from "apps/frontend/pages/api/internal/popups/fetchPopupHistory";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIntersectionObserverRef } from "rooks";
import { useContainer } from "unstated-next";
import { NotificationCenterContext } from ".";
import { AppContext } from "../contextProvider";
import EmptyView from "./EmptyView";
import MessageLine from "./MessageLine";

export type AllMessages = {
  [key in GroupType]: (
    | NormalizedPopup
    | (Notification & ScheduledNotification)
  )[];
};

interface Props {
  allMessages: AllMessages;
  notifications: Notifications;
  popups: NormalizedPopup[];
  isViewing?: boolean;
}

const Content = (props: Props) => {
  const { setNotificationBadge, mobile } = useContainer(AppContext);
  const { newMessagesAmount, setNewMessagesAmount } = useContainer(
    NotificationCenterContext
  );

  const { allMessages, notifications, popups, isViewing = null } = props;

  const { t } = useTranslation(["translation", "components", "modals"]);

  useEffect(() => {
    if (mobile && !isViewing) return;

    setNotificationBadge(false);

    return () => setNewMessagesAmount(0);
  }, [isViewing]);

  const hasRecentNotifications =
    Boolean(allMessages?.["today"]) && !isEmpty(allMessages?.["today"]);
  const hasPreviousNotifications =
    Boolean(allMessages?.["previous"]) && !isEmpty(allMessages?.["previous"]);

  const noNotifications = isEmpty(notifications);
  const noPopups = isEmpty(popups);

  const noMessages = noNotifications && noPopups;

  return (
    <Tabs
      defaultActiveKey="all"
      className="custom-tabs"
      tabBarStyle={{ paddingLeft: !mobile && "16px" }}
      centered={mobile}
    >
      <Tabs.TabPane tab={t("modals:notifications.tabs.all")} key="all">
        <TabContentWrapper mobile={mobile} noMessages={noMessages}>
          {noMessages && <EmptyView type="message" />}
          {!noMessages &&
            Object.entries(allMessages).map(([day, notifications]) => {
              const label =
                day === "today"
                  ? t("translation:today")
                  : t("translation:earlier");

              const isToday = day === "today";

              return (
                <div key={day}>
                  <DayHeader
                    label={label}
                    withMargin={isToday}
                    mobile={mobile}
                  />
                  {(!hasRecentNotifications && isToday) ||
                    (!isToday && !hasPreviousNotifications && (
                      <EmptyView type="message" />
                    ))}
                  {notifications.map((n, i) => (
                    <MessageLine
                      key={i}
                      message={n}
                      isForToday={isToday}
                      isRecent={isToday && i < newMessagesAmount}
                    />
                  ))}
                </div>
              );
            })}
        </TabContentWrapper>
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={t("modals:notifications.tabs.messages")}
        key="messages"
      >
        <TabContentWrapper mobile={mobile} noMessages={noMessages}>
          {noNotifications && <EmptyView type="message" />}
          {notifications.map((n, i) => (
            <MessageLine key={i} message={n} />
          ))}
        </TabContentWrapper>
      </Tabs.TabPane>
      <Tabs.TabPane tab={t("modals:notifications.tabs.popups")} key="popups">
        <TabContentWrapper mobile={mobile} noMessages={noMessages}>
          {noPopups && <EmptyView type="popup" />}
          {popups.map((p, i) => (
            <MessageLine key={i} message={p} />
          ))}
        </TabContentWrapper>
      </Tabs.TabPane>
    </Tabs>
  );
};

const TabContentWrapper = ({ children, mobile, noMessages }) => (
  <div
    className={"md:overflow-scroll relative md:h-auto"}
    style={{
      height: !mobile && !noMessages ? "calc(100vh - 290px)" : "250px",
      maxHeight: 500,
    }}
  >
    {children}
  </div>
);

const DayHeader = ({
  label,
  withMargin,
  mobile,
}: {
  label: string;
  withMargin: boolean;
  mobile: boolean;
}) => {
  const [isScrolling, setIsScrolling] = useState(false);

  const callback = (entries) =>
    setIsScrolling(entries?.[0]?.intersectionRatio < 1);

  const [divRef] = useIntersectionObserverRef(callback, {
    threshold: [1],
  });

  return (
    <div
      ref={divRef}
      className={classNames(
        "w-full px-4 pt-2 pb-4 bg-background-primary sticky transition-all ease-in-out text-text-primary",
        isScrolling && "shadow-sm z-10"
      )}
      style={{
        top: "-1px",
        paddingTop: isScrolling && mobile && "calc(1em + 60px)",
      }}
    >
      <h1 className={classNames("h300", { "mt-2": !withMargin })}>{label}</h1>
    </div>
  );
};

export default Content;
