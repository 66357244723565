import { faList } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrderOrder } from "@kanpla/types";
import { Button } from "antd";
import classnames from "classnames";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { FlexContext } from "..";

interface Props {
  children?: JSX.Element; // Settings modal
  text?: string;
  setOpen: (open: boolean) => void;
  full?: boolean;
  flex?: boolean;
}

const StandardOrderButton = ({
  children,
  setOpen,
  full,
  flex = true,
}: Props) => {
  const { t, i18n } = useTranslation(["translation"]);

  const { standard } = useContainer(FlexContext);

  const hasStandard = Object.values(standard || {}).some(
    (dayStandard: OrderOrder) => !isEmpty(dayStandard)
  );

  const hasLanguage = !isEmpty(i18n);

  const text = hasStandard
    ? hasLanguage
      ? t("translation:edit-default-selection")
      : "Rediger standardvalg"
    : hasLanguage
    ? t("translation:set-default-selection")
    : "Angiv standardvalg";

  const firstElementClassNames = classnames({
    "w-full": full,
    "pt-8 flex justify-center": flex,
    "": !flex && !full,
  });

  const secondElementClassNames = classnames({
    "w-full": full,
    "max-w-sm w-full": flex,
    "": !flex && !full,
  });

  const thirdElementClassNames = classnames({
    "": full,
    "pt-6": !full,
    "flex flex-col items-center justify-center": flex,
    "pt-3": !flex && !full,
  });

  return (
    <div className={firstElementClassNames}>
      <div className={secondElementClassNames}>
        <div className={thirdElementClassNames}>
          <Button onClick={() => setOpen(true)} type="primary">
            <FontAwesomeIcon icon={faList} className="mr-2" />
            {text}
          </Button>
          {children}
        </div>
      </div>
    </div>
  );
};

export default StandardOrderButton;
