import { faPen } from "@fortawesome/pro-light-svg-icons";
import { Timestamp, useFetch, useSubmit } from "@kanpla/system";
import { Holiday, HolidayPeriod } from "@kanpla/types";
import { message } from "antd";
import { GetHolidaysProps } from "apps/frontend/pages/api/internal/load/holidays";
import { SubmitHolidaysProps } from "apps/frontend/pages/api/internal/submit/holidays";
import { HolidaysModal } from "libs/ordering/src/lib/flex/HolidaysModal";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import ButtonLine from "../../../../apps/frontend/components/settings/ButtonLine";
import { OrderingContext } from "../context";

const emptyPeriod: HolidayPeriod = { fromSeconds: null, toSeconds: null };

export const FlexBulkHolidaysModal: FC = () => {
  const { t } = useTranslation(["flex-bulk", "translation"]);
  const [open, setOpen] = useState(false);
  const [periods, setPeriods] = useState<HolidayPeriod[]>([emptyPeriod]);

  const {
    moduleId,
    schoolId,
    userId,
    module: { partnerId },
    offer: {
      holidayDates,
      deadlineInfo: {
        deadline,
        deadlineWeekRelative,
        deadlineExcludingWeekends,
      },
    },
  } = useContainer(OrderingContext);

  const { data, setData } = useFetch<GetHolidaysProps, Holiday[]>(
    "load/holidays",
    {
      moduleId,
      schoolId,
      partnerId,
    }
  );

  const { submit } = useSubmit<SubmitHolidaysProps, HolidayPeriod[]>({
    path: "submit/holidays",
    requestConstructor: (periods: HolidayPeriod[]) => {
      const holidays = periods
        .filter((p) => Boolean(p.fromSeconds && p.toSeconds))
        .map(({ fromSeconds, toSeconds, ...restHoliday }) => {
          return {
            ...restHoliday,
            from: new Timestamp(fromSeconds || 0, 0),
            to: new Timestamp(toSeconds || 0, 0),
            design: {
              title: "Ferie",
              text: "Din arbejdsplads har lukket denne dag pga. ferien.",
              image: "no_mealplan",
            },
          };
        });

      return {
        holidays,
        moduleId,
        partnerId,
        schoolId,
        userId,
      } as SubmitHolidaysProps;
    },
    responseDestructor: (newHolidays) => setData(newHolidays),
  });

  useEffect(() => {
    if (!data?.length) return setPeriods([emptyPeriod]);

    setPeriods(
      data?.map((holiday) => ({
        ...holiday,
        fromSeconds: holiday.from.seconds,
        toSeconds: holiday.to.seconds,
      }))
    );
  }, [data]);

  const confirmHandler = useCallback(
    async (periodsValue: HolidayPeriod[]) => {
      await submit(periodsValue);
      setPeriods(periodsValue);

      message.success({
        key: "success-holidays-saving",
        content: t("flex:message.success.holidays-saved"),
      });
      setOpen(false);
    },
    [submit, t]
  );

  return (
    <>
      <ButtonLine
        onClick={() => setOpen(true)}
        label={t("flex-bulk:edit-holidays")}
        icon={faPen}
      />
      <HolidaysModal
        open={open}
        setOpen={setOpen}
        onConfirm={confirmHandler}
        periods={periods}
        holidayDates={holidayDates}
        deadline={deadline}
        deadlineWeekRelative={deadlineWeekRelative}
        deadlineExcludingWeekends={deadlineExcludingWeekends}
      />
    </>
  );
};
