import {
  faFileMagnifyingGlass,
  faPrint,
} from "@fortawesome/pro-light-svg-icons";
import { ProductBank } from "@kanpla/types";
import { Dropdown, Menu } from "antd";
import { StrippedModule } from "apps/frontend/pages/api/internal/offers/loadFrontend";
import { useTranslation } from "react-i18next";
import ButtonLine from "../../settings/ButtonLine";
import SinglePrint from "./SinglePrint";

interface Props {
  productBanks: Array<ProductBank>;
  flexModulesToPrint: StrippedModule[];
}

const MultipleModulePrint = ({ productBanks, flexModulesToPrint }: Props) => {
  const { t } = useTranslation(["translation"]);

  const productBank = productBanks?.[0];

  return (
    <>
      <Dropdown
        openClassName="print:hidden"
        overlayClassName="print:hidden"
        className="print:hidden"
        trigger={["click"]}
        overlay={
          <Menu>
            {flexModulesToPrint?.map((m) => (
              <SinglePrint module={m} key={m.id} productBank={productBank}>
                <Menu.Item>{m.displayName || m.name}</Menu.Item>
              </SinglePrint>
            ))}
          </Menu>
        }
      >
        <ButtonLine label={t("translation:print-week-menu")} icon={faPrint} />
      </Dropdown>
      <Dropdown
        openClassName="print:hidden"
        overlayClassName="print:hidden"
        className="print:hidden"
        trigger={["click"]}
        overlay={
          <Menu>
            {flexModulesToPrint?.map((m) => (
              <SinglePrint
                isDigital
                module={m}
                key={m.id}
                productBank={productBank}
              >
                <Menu.Item>{m.displayName || m.name}</Menu.Item>
              </SinglePrint>
            ))}
          </Menu>
        }
      >
        <ButtonLine
          label={t("translation:see-week-menu")}
          icon={faFileMagnifyingGlass}
        />
      </Dropdown>
    </>
  );
};

export default MultipleModulePrint;
