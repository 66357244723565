import { Header } from "@kanpla/ui";
import { useTranslation } from "react-i18next";
import Products from "./Products";

const Index = () => {
  const { t } = useTranslation(["subscription"]);

  return (
    <>
      <Header title={t("subscription:add-subscription")} />
      <Products />
    </>
  );
};

export default Index;
