import { DrawerOrModal, Spinner } from "@kanpla/ui";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import TypeDivider from "./TypeDivider";
import useHistory from "./useHistory";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const History = ({ open, setOpen }: Props) => {
  const { t } = useTranslation(["modals"]);

  return (
    <DrawerOrModal
      title={t("modals:modal.titles.account-history")}
      subtitle={t("modals:modal.subtitles.overview-of-your-payments")}
      open={open}
      setOpen={setOpen}
      showCloseButton={true}
    >
      {open && <Content />}
    </DrawerOrModal>
  );
};

const Content = () => {
  const { i18n } = useTranslation();
  // Util to change the localization of moment.js
  moment.locale(i18n.language);

  const { items, itemsLoading } = useHistory();

  if (itemsLoading)
    return (
      <div className="py-4 flex items-center justify-center text-primary-main">
        <Spinner useCurrentColor />
      </div>
    );

  return <TypeDivider items={items} />;
};

export default History;
