import { OrderingContext } from "@kanpla/ordering";
import { useWindowSize } from "@kanpla/system";
import { CombinedOfferItem } from "@kanpla/types";
import { HorizontalSlider, TodaySwitch, WeekNavbarSwitch } from "@kanpla/ui";
import classnames from "classnames";
import { capitalize, Dictionary, isEmpty } from "lodash";
import moment from "moment";
import {
  default as React,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useIntersectionObserverRef } from "rooks";
import { useContainer } from "unstated-next";
import useBasketPreventClick from "./BasketPreventClick";
import { AppContext } from "./contextProvider";
import BalanceDisplay from "./design/balance";
import BasketDisplay from "./design/BasketDisplay";

type Props = {
  timeNavigation: "daily" | "weekly" | "none";
  deadlineFormatted?: string;
  extraContent?: React.ReactNode;
  selectedCategoryIndex?: number;
  setSelectedCategoryIndex?: Dispatch<SetStateAction<number>>;
  categories?: Dictionary<CombinedOfferItem[]>;
};

const NavbarSecondary = ({
  deadlineFormatted,
  timeNavigation = "daily",
  extraContent = null,
  selectedCategoryIndex,
  setSelectedCategoryIndex,
  categories = {},
}: Props) => {
  const { t, i18n } = useTranslation(["components", "translation"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);

  const {
    week,
    setWeek,
    dayIndex,
    setDayIndex,
    setPaymentOpen,
    withCredit,
    mobile,
    setNavbarHasContent,
    module,
    auth,

    innerAppLoading,
  } = useContainer(AppContext);

  const {
    basketContainer,
    basketContainerUtils,
    basket,
    openBasket,
    setOpenBasket,
  } = useContainer(OrderingContext);

  const confirm = useBasketPreventClick({ disabled: true });

  const requiredProductId = useMemo(
    () =>
      module?.plugins?.requiredProduct?.active &&
      module?.plugins?.requiredProduct?.productId,
    [
      module?.plugins?.requiredProduct?.active,
      module?.plugins?.requiredProduct?.productId,
    ]
  );

  const categoryItems = useMemo(
    () =>
      Object.entries(categories)
        .filter(
          ([_, items]) =>
            items?.filter((item) => requiredProductId !== item?.productId)
              ?.length
        )
        .map(
          ([name]) =>
            capitalize(name) ||
            module?.text?.["noCategory/text"] ||
            t("translation:dish-of-the-day")
        ),
    [categories, requiredProductId, module?.text, t]
  );

  const itemsTotalCount: number = useMemo(() => {
    return Object.values(categories).flat().length;
  }, [categories]);

  const { isMobileSize } = useWindowSize();

  const [isScrolled, setIsScrolled] = useState(false);

  const callback = (entries) =>
    setIsScrolled(entries?.[0]?.intersectionRatio < 1);

  const [myRef] = useIntersectionObserverRef(callback, {
    threshold: [0, 0.9, 1],
    rootMargin: "50px 0px 0px 0px",
  });

  const DeadlineInfo = () =>
    deadlineFormatted ? (
      <div>{t("components:order-until", { value: deadlineFormatted })}</div>
    ) : null;

  const hasExtraContent =
    !isEmpty(extraContent) ||
    (withCredit && mobile) ||
    (!withCredit && !isEmpty(basket) && !mobile);

  useEffect(() => {
    setNavbarHasContent(hasExtraContent);
  }, [hasExtraContent]);

  const ExtraContent = () => (
    <div className="flex items-center">
      {withCredit && !mobile && auth?.user?.uid && (
        <div className="hidden lg:block flex-shrink-0 whitespace-nowrap">
          {t("components:balance")}{" "}
          <BalanceDisplay setPaymentOpen={setPaymentOpen} />
        </div>
      )}
      {!isEmpty(basketContainer) && !mobile && (
        <div className="ml-auto mt-3 lg:ml-4">
          <BasketDisplay
            className={`${!openBasket ? "animate-pop" : ""}`}
            amountOfItems={basketContainerUtils.totalItemsAmount}
            onClick={() => setOpenBasket(true)}
          />
        </div>
      )}
      {extraContent}
    </div>
  );

  const showCategoriesSlider =
    categoryItems.length > 1 &&
    (isMobileSize
      ? categoryItems.length >= 4 || itemsTotalCount > 20
      : categoryItems.length >= 6 || itemsTotalCount > 25);

  if (!hasExtraContent && timeNavigation === "none" && !showCategoriesSlider)
    return null;

  const showFirstRow = hasExtraContent || timeNavigation !== "none";

  return (
    <>
      <div
        className="sticky z-20 bg-background-primary pt-16 -mt-16"
        style={{ top: "calc(var(--status-bar-height, 0px) - 4rem)" }}
        ref={myRef}
      >
        {showFirstRow && (
          <div className="flex flex-col lg:flex-row justify-between items-center py-3 wrapper w-full">
            {timeNavigation !== "none" && (
              <div className="flex flex-col lg:flex-row justify-start items-center w-full lg:w-initial">
                {timeNavigation === "daily" ? (
                  <TodaySwitch
                    week={week}
                    setWeek={setWeek}
                    dayIndex={dayIndex}
                    setDayIndex={setDayIndex}
                    confirm={confirm}
                  />
                ) : (
                  <WeekNavbarSwitch
                    week={week}
                    setWeek={setWeek}
                    dayIndex={dayIndex}
                    setDayIndex={setDayIndex}
                    dayRowOnPhone={module.flow === "menuPreview"}
                  />
                )}
                <div className="ml-3 hidden lg:block">
                  {innerAppLoading ? (
                    <div className="h-2 w-20 rounded loader" />
                  ) : (
                    <DeadlineInfo />
                  )}
                </div>
              </div>
            )}
            <div className="hidden lg:block ml-auto">
              <ExtraContent />
            </div>
          </div>
        )}
        {showCategoriesSlider && (
          <HorizontalSlider
            items={categoryItems}
            selectedIndex={selectedCategoryIndex}
            onClick={setSelectedCategoryIndex}
            classNames={classnames(
              "pb-4",
              showFirstRow ? "pt-1 mt-1" : "pt-2 mt-2"
            )}
          />
        )}
        <div
          className={`h-px w-screen absolute left-0 right-0 bg-divider-main bottom-0 transition-opacity ${
            isScrolled ? "" : "opacity-0"
          }`}
          style={{
            left: "-50vw",
            marginLeft: "50%",
          }}
        ></div>
      </div>
      <div className="wrapper lg:hidden text-center">
        {timeNavigation !== "none" && (
          <div className="mb-6">
            <DeadlineInfo />
          </div>
        )}
        <ExtraContent />
      </div>
    </>
  );
};

export default NavbarSecondary;
