/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useContainer } from "unstated-next";
import { CombinedOfferItem, DayIndex } from "@kanpla/types";
import { AppContext } from "../contextProvider";
import Product from "./Product";

interface Props {
  categoryName: string;
  items: CombinedOfferItem[];
  overrideDayIndex?: DayIndex;
}

const DayDetailCategory = ({
  items,
  categoryName,
  overrideDayIndex,
}: Props) => {
  const { dayIndex } = useContainer(AppContext);

  return (
    <>
      <h2 className="text-base font-semibold before:h-px before:bg-gray-400 before:flex-1 after:flex-1 after:h-px after:bg-gray-400 flex items-center pt-6 pb-8 px-2 after:ml-10 before:mr-10 capitalize">
        {categoryName}
      </h2>
      <div>
        {items
          .sort((a, b) => a.price - b.price)
          .map((product) => {
            return (
              <Product
                product={product}
                key={product?.id}
                dayIndex={overrideDayIndex ?? dayIndex}
                detailed
              />
            );
          })}
      </div>
    </>
  );
};

export default DayDetailCategory;
