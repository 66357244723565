import { getTodayTimestamp, Timestamp } from "@kanpla/system";
import { HistoryItem } from "apps/frontend/pages/api/internal/user/history";
import { groupBy, sortBy } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Item from "./Item";

interface Props {
  items: HistoryItem[];
}
const TimeDivider = ({ items }: Props) => {
  const { t, i18n } = useTranslation(["translation", "modals"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);

  const todaySeconds = getTodayTimestamp({ Timestamp }).seconds;
  const tomorrowSeconds = todaySeconds + 86400;
  const timeGroups = groupBy(items, (item) => {
    const isFuture = item.dateSeconds >= tomorrowSeconds;
    const isToday = item.dateSeconds >= todaySeconds;

    if (isFuture) {
      return "future";
    } else if (isToday) {
      return "today";
    } else {
      return "past";
    }
  });

  const todayOrders = timeGroups.today || [];
  const futureOrders = timeGroups.future || [];
  const pastOrders = timeGroups.past || [];

  return (
    <>
      <h2 className="h400 text-text-primary">{t("translation:today")}</h2>
      {todayOrders.map((item) => (
        <Item item={item} isBig={true} key={item.order?.id} />
      ))}
      {!todayOrders.length && <NoOrders />}

      <h2 className="h400 mt-4 text-text-primary">{t("modals:coming")}</h2>
      {sortBy(futureOrders, "dateSeconds").map((item) => (
        <Item item={item} isBig={true} key={item.order?.id} />
      ))}
      {!futureOrders.length && <NoOrders />}

      <h2 className="h400 mt-4 text-text-primary">
        {t("translation:earlier")}
      </h2>
      {Object.entries(
        groupBy(pastOrders, (item) =>
          moment.unix(item.dateSeconds).format("MMMM YYYY")
        )
      ).map(([monthName, monthData]) => (
        <div key={monthName}>
          <h5 className="text-medium h300 text-text-secondary my-2">
            {monthName}
          </h5>
          {monthData.map((item) => (
            <Item item={item} />
          ))}
        </div>
      ))}
      {!pastOrders.length && <NoOrders />}
    </>
  );
};

const NoOrders = () => {
  const { t } = useTranslation(["modals"]);
  return (
    <div className="bg-background-secondary py-6 px-2 rounded-lg text-center text-text-secondary">
      {t("modals:no-bookings-short")}
    </div>
  );
};

export default TimeDivider;
