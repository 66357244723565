import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Timestamp as FirebaseTimestamp,
  addWeek,
  getMondaySeconds,
  getWeekArray,
  getWeekSeconds,
  subtractWeek,
  weekToDisplayString,
} from "@kanpla/system";
import { DayIndex, Timestamp, Week } from "@kanpla/types";
import { Button, DatePicker } from "antd";
import classnames from "classnames";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  week: Week;
  setWeek: Dispatch<SetStateAction<Week>>;
  dayIndex: DayIndex;
  setDayIndex: Dispatch<SetStateAction<DayIndex>>;
  dayRowOnPhone: boolean;
}

export const WeekNavbarSwitch = ({
  week,
  setWeek,
  dayRowOnPhone,
  dayIndex: selectedDayIndex,
  setDayIndex,
}: Props) => {
  const { t, i18n } = useTranslation(["translation", "libs"]);
  /**
   * Util to change the localization of moment.js
   * dow and doy explanation: https://github.com/moment/momentjs.com/issues/279
   */
  moment?.updateLocale(isEmpty(i18n) ? "da" : i18n.language, {
    week: { dow: 1, doy: 4 },
  });

  const buttonStyles =
    "px-6 h-auto flex items-center justify-center rounded hover:bg-main-600 transition-all flex-grow-0 flex-shrink-0";

  const adjustWeek = (ahead: boolean) => {
    const newWeek = ahead ? addWeek(week) : subtractWeek(week);
    return setWeek(newWeek);
  };

  const weekSeconds = week[0]?.seconds;

  const weekRelative = useMemo(() => {
    const currentSeconds = getWeekSeconds(moment().unix());
    const weeksAhead = (weekSeconds - currentSeconds) / 604800;
    const displayString =
      weeksAhead === 0
        ? isEmpty(i18n)
          ? "Denne uge"
          : t("translation:this-week")
        : weeksAhead === 1
        ? isEmpty(i18n)
          ? "Næste uge"
          : t("translation:next-week")
        : weeksAhead > 1
        ? isEmpty(i18n)
          ? `uge`
          : t("libs:in-weeks", { value: Math.abs(weeksAhead) })
        : isEmpty(i18n)
        ? `uge`
        : t("libs:plural.week", {
            count: Math.abs(weeksAhead),
            value: Math.abs(weeksAhead),
          });
    return displayString;
  }, [weekSeconds, i18n.language]);

  const today = new Date(Date.now()).getDate();
  const thisMonth = new Date(Date.now()).getMonth();
  // see whether a day is in the past or not
  function isInThePast(day: Timestamp) {
    return (
      (today > new Date(day.toMillis()).getDate() &&
        thisMonth >= new Date(day.toMillis()).getMonth()) ||
      thisMonth > new Date(day.toMillis()).getMonth()
    );
  }

  return (
    <>
      <div className="justify-between border rounded-lg bg-main-100 items-stretch flex w-full md:w-auto">
        <button className={buttonStyles} onClick={() => adjustWeek(false)}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>

        <button className="block text-center mx-1 md:mx-2 relative rounded hover:bg-main-600 transition-all flex-grow cursor-pointer md:w-40 px-2 py-2">
          <div className="text-lg -mb-1 font-semibold text-primary-main">
            {weekRelative}
          </div>
          <div className="text-xs text-text-secondary">
            {weekToDisplayString(week, isEmpty(i18n) ? `uge` : t("libs:week"))}
          </div>

          <style>
            {`.hidden-input .ant-picker {
            height: 100% !important;
            width: 100% !important;
            opacity: 0;
          }`}
          </style>
          <div className="absolute inset-0 hidden-input cursor-pointer">
            <DatePicker
              dropdownAlign={{ points: ["tc", "bc"] }}
              className="cursor-pointer"
              value={moment.utc(week[0].seconds * 1000)}
              onChange={(newValue) => {
                const newSeconds =
                  newValue.utc().startOf("week").valueOf() / 1000;
                const newWeek = getWeekArray(newSeconds, FirebaseTimestamp);
                setWeek(newWeek);
              }}
              allowClear={false}
              format={(m) =>
                weekToDisplayString(
                  week,
                  isEmpty(i18n) ? `uge` : t("libs:week")
                )
              }
              picker="week"
              bordered={false}
              style={{ border: 0, width: 150 }}
              renderExtraFooter={() => (
                <Button
                  size="small"
                  className="w-full text-sm"
                  type="dashed"
                  onClick={() =>
                    setWeek(
                      getWeekArray(
                        getMondaySeconds(moment().utc().unix()),
                        FirebaseTimestamp
                      )
                    )
                  }
                >
                  {isEmpty(i18n)
                    ? "Til den nuværende uge"
                    : t("libs:for-that-week")}
                </Button>
              )}
            />
          </div>
        </button>

        <button className={buttonStyles} onClick={() => adjustWeek(true)}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      {/* Display days as a row when the module flow is "menuPreview" */}
      {dayRowOnPhone && (
        <div className="flex md:hidden w-full content-center justify-center -mb-4 z-30">
          {week.map((day, dayIndex) => {
            return (
              <div
                className={classnames(
                  "py-1 mt-3 flex flex-col items-center cursor-pointer transition rounded-md flex-1 max-w-[68px]",
                  isInThePast(day) && "text-text-disabled",
                  isInThePast(day) && selectedDayIndex === dayIndex
                    ? "bg-background-secondary text-text-contrast"
                    : selectedDayIndex === dayIndex
                    ? "bg-primary-main text-white shadow-md"
                    : "bg-transparent"
                )}
                onClick={() => setDayIndex(dayIndex as DayIndex)}
                key={dayIndex}
              >
                <span className="text-xl font-semibold mt-1" key={day.seconds}>
                  {moment.unix(day.seconds).format("D")}
                </span>
                <span className="text-sm opacity-75 mb-2">
                  {moment.unix(day.seconds).format("ddd")}
                </span>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
