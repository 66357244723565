import { constructNewUrl, hasAccessToModule } from "@kanpla/system";
import { Homescreen as HomescreenType, Module, Product } from "@kanpla/types";
import { DotDotDot } from "@kanpla/ui";
import { Button } from "antd";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { createContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import serializer from "./utils/serializer";

interface ExtraContentProps {
  flexProducts: Array<Product>;
}

interface Props {
  module: Module;
  contextExtras?: Partial<ExtraContentProps>;
}

interface ContextProps extends Partial<ExtraContentProps> {
  content: HomescreenType.Content;
  module: Module;
}

export const HomescreenContext = createContext<Partial<ContextProps>>({});

const Homescreen = ({ module, contextExtras = {} }: Props) => {
  const { t } = useTranslation(["homescreen"]);

  const { school, child, modules, setTimeNavigation } =
    useContainer(AppContext);

  const router = useRouter();

  const { other } = hasAccessToModule({
    module: module,
    child,
    school,
  });

  const startOver = () => {
    const availableModule = modules.find((m) =>
      module?.scope?.generatedSchoolIds?.includes(school?.id)
    );

    const url = constructNewUrl(school?.id, availableModule?.id);
    router.push(url);
  };

  useEffect(() => {
    setTimeNavigation("none");
  }, []);

  if (!other)
    return (
      <section className="max-w-screen-xl mx-auto text-center flex flex-col items-center">
        <div
          style={{ minHeight: "70vh" }}
          className="flex items-center flex-col justify-center -mt-8"
        >
          <span style={{ fontSize: 120 }} role="img" aria-label="">
            ✋🏻
          </span>
          <h2 className="font-semibold text-2xl md:text-3xl mb-2 text-text-secondary h800">
            {t("homescreen:do-not-have-access")}
          </h2>
          <Button type="primary" className="mt-8" onClick={startOver}>
            {t("homescreen:start-over")}
          </Button>
        </div>
        <img
          src="/images/no_mealplan.svg"
          alt=""
          className="w-full mx-auto mt-8 md:mt-6 lg:-mt-20 pointer-events-none absolute bottom-0"
        />
      </section>
    );

  if (!module)
    return (
      <p>
        {t("homescreen:loading")} <DotDotDot />
      </p>
    );

  const content = JSON.parse(
    JSON.stringify(module?.content || {}).replace(/&nbsp;/g, "")
  ) as HomescreenType.Content;

  if (isEmpty(content))
    return (
      <section className="max-w-screen-xl mx-auto text-center flex flex-col items-center">
        <div
          style={{ minHeight: "70vh" }}
          className="flex items-center flex-col justify-center -mt-8"
        >
          <span style={{ fontSize: 120 }} role="img" aria-label="">
            📭
          </span>
          <h2 className="font-semibold text-2xl md:text-3xl mb-2 text-text-secondary h800">
            {t("homescreen:website-empty")}
          </h2>
        </div>
        <img
          src="/images/no_mealplan.svg"
          alt=""
          className="w-full mx-auto mt-8 md:mt-6 lg:-mt-20 pointer-events-none absolute bottom-0"
        />
      </section>
    );

  return (
    <HomescreenContext.Provider value={{ content, module, ...contextExtras }}>
      {content?.ROOT && serializer(content.ROOT, "0")}
    </HomescreenContext.Provider>
  );
};

export default Homescreen;
