import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Space } from "antd";
import ExportOrdersModal from "apps/frontend/components/modals/ExportOrdersModal";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Export = () => {
  const { t, i18n } = useTranslation(["mealplan2"]);
  moment.locale(i18n.language);

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        <Space>
          {t("mealplan2:export-history")} <FontAwesomeIcon icon={faDownload} />
        </Space>
      </Button>
      <ExportOrdersModal
        open={open}
        setOpen={setOpen}
        modalTitle={t("mealplan2:export-your-history")}
      />
    </>
  );
};

export default Export;
