import { cloneDeep } from "lodash";
import moment from "moment";

const translations = {
  en: {
    "same-day": "same day",
    "day-before": "day before",
    "days-before": "days before",
    "week-before": "week before",
    "time-prefix": "",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  },
  da: {
    "same-day": "samme dag",
    "day-before": "dagen før",
    "days-before": "dage før",
    "week-before": "ugen før",
    "time-prefix": "kl. ",
    monday: "mandag",
    tuesday: "tirsdag",
    wednesday: "onsdag",
    thursday: "torsdag",
    friday: "fredag",
    saturday: "lørdag",
    sunday: "søndag",
  },
};

interface Props {
  deadline: number;
  deadlineWeekRelative: boolean;
  deadlineExcludingWeekends: boolean;
  // displayStringDay?: boolean;
  locale?: "da" | "en" | string;
}

export const deadlineDisplay = ({
  deadline,
  deadlineWeekRelative,
  deadlineExcludingWeekends,
  locale = "da",
}: // displayStringDay = true,
Props) => {
  const translation = translations[locale] || translations["da"];

  const daysBefore = Math.floor((deadline || 0) / 86400);
  const timeModulus = (deadline || 0) % 86400;

  let time = timeModulus;

  if (timeModulus < 0) {
    const date = moment().startOf("day").add(deadline, "seconds");
    const midnight = cloneDeep(date).startOf("day");

    time = date.unix() - midnight.unix();
  }

  const getDayRelativeDisplay = () => {
    if (daysBefore === 0) return translation["same-day"];
    if (daysBefore === -1) return translation["day-before"];
    return `${-daysBefore} ${translation["days-before"]}`;
  };

  const getWeekRelativeDisplay = () => {
    const dayNames = {
      6: translation.sunday,
      5: translation.saturday,
      4: translation.friday,
      3: translation.thursday,
      2: translation.wednesday,
      1: translation.tuesday,
      0: translation.monday,
    };

    const dayIndex = daysBefore + 7;
    return `${dayNames[dayIndex]} ${translation["week-before"]}`;
  };

  const displayDay = deadlineWeekRelative
    ? getWeekRelativeDisplay()
    : getDayRelativeDisplay();
  const displayTime = moment.utc(time * 1000 || 0).format("HH:mm");

  return `${translation["time-prefix"]}${displayTime}, ${displayDay}`;
};
