import { DrawerOrModal } from "@kanpla/ui";
import { Button } from "antd";
import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useBoundingclientrectRef } from "rooks";
import BannerBackgroundImage from "./BannerBackgroundImage";
import { getProportionalFontSize, getProportionalHeightPadding } from "./utils";

interface Props {
  title: string;
  subTitle: string;
  imageUrl: string;
  link: string;
  linkText: string;
  overlayOpacity?: number;
  /** Use this when wrapping in a modal! */
  defaultExpanded?: boolean;
}

const BannerThree = (props: Props) => {
  const {
    title,
    imageUrl,
    link,
    linkText,
    subTitle,
    overlayOpacity,
    defaultExpanded,
  } = props;
  const [bannerRef, boundingClientRect] = useBoundingclientrectRef();
  const [descriptionRef, descriptionRect] = useBoundingclientrectRef();
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation(["translation"]);

  if (!title && !imageUrl) return null;

  const width = boundingClientRect?.width || 0;
  const descHeight = descriptionRect?.height || 0;

  const expandDescription = !defaultExpanded && descHeight > 90;

  return (
    <div
      ref={bannerRef}
      className={`w-full h-full rounded-lg overflow-hidden relative shadow-lg  bg-gray-900 `}
      style={{ minHeight: 200 }}
    >
      <BannerBackgroundImage
        src={imageUrl}
        alt={title}
        opacity={1 - overlayOpacity / 140}
        size={{ w: width }}
      />
      <div
        className={`flex flex-col h-full relative inset-0 p-4 ${getProportionalHeightPadding(
          width,
          "bottom",
          true
        )} text-white`}
        style={{ minHeight: 200 }}
      >
        {title && (
          <h3
            className={`h600 mt-auto text-white text-left ${getProportionalFontSize(
              width,
              "text-xl"
            )}`}
          >
            {title}
          </h3>
        )}
        <p
          className={classNames(
            "text-white text-left overflow-hidden leading-tight",
            getProportionalFontSize(width),
            !defaultExpanded && "max-h-24"
          )}
        >
          <span
            className="block"
            ref={descriptionRef}
            dangerouslySetInnerHTML={{ __html: subTitle }}
          />
        </p>
        {expandDescription && (
          <>
            <Button
              type="link"
              className="px-0 text-left inline-block"
              onClick={() => setOpenModal(true)}
            >
              <span className="hover:opacity-60 transition-opacity">
                {t("translation:read-more")}
              </span>
            </Button>
            <DrawerOrModal open={openModal} setOpen={setOpenModal} noPadding>
              <BannerThree {...props} defaultExpanded />
            </DrawerOrModal>
          </>
        )}

        {link && linkText && (
          <div className="mt-1">
            <button
              className={`bg-primary-main inline-block rounded p-2 px-3 text-left text-primary-contrast hover:text-primary-contrast hover:bg-primary-dark transition mt-4 ${getProportionalFontSize(
                width
              )}`}
            >
              {linkText}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BannerThree;
