import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { DrawerOrModal } from "@kanpla/ui";
import { Alert, Form, Input, message } from "antd";
import React, { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import ButtonLine from "../ButtonLine";
// @ts-ignore
import { IntercomAPI } from "react-intercom";
import { capitalize } from "lodash";
import { useForm } from "antd/lib/form/Form";
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { auth, callFunction, KanplaError } from "@kanpla/system";
import { useRouter } from "next/router";

const IntButton: FC = ({ children }) => {
  return (
    <button
      className="text-warning-main underline hover:text-warning-light transition hover:underline"
      onClick={() => {
        try {
          window?.["ReactNativeWebView"]?.postMessage("showIntercom");
          window?.["ReactNativeWebView"]?.postMessage("triggerIntercom");
        } catch (err) {
          return true;
        }

        IntercomAPI("show");
      }}
    >
      {children}
    </button>
  );
};

const Mail: FC = ({ children }) => {
  return (
    <a
      href={`mailto:${children}`}
      className="text-warning-main underline hover:text-warning-light transition hover:underline"
    >
      {children}
    </a>
  );
};

const DeleteAccount = () => {
  const { user } = useContainer(AppContext);
  const router = useRouter();
  const { t } = useTranslation([
    "settings",
    "translation",
    "account-already-exists",
  ]);
  const [open, setOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [form] = useForm();

  const balance = user?.balance;

  const deleteAccount = async (data) => {
    try {
      setDeleting(true);
      const credential = EmailAuthProvider.credential(
        data.email,
        data.password
      );

      // Validate password
      await reauthenticateWithCredential(auth.currentUser, credential);

      await callFunction("softDeletes-deleteUser", {
        userId: user.id,
        email: data.email,
      });

      setDeleting(false);
      message.success(t("settings:message.success.account-deleted"));
      router.push("/signup");
    } catch (e) {
      setDeleting(false);
      console.error(e);
      const err = new KanplaError(e?.code, e?.message);
      message.error(err?.message);
    }
  };

  return (
    <>
      <ButtonLine
        label={t("settings:delete-user.delete-account")}
        icon={faTrash}
        danger
        onClick={() => setOpen(true)}
        className="border-b-0"
      />
      <DrawerOrModal
        open={open}
        setOpen={setOpen}
        title={t("settings:delete-user.delete-account-question")}
        subtitle={t("settings:delete-user.delete-account-warning")}
        actions={[
          {
            onClick: () => form.submit(),
            label: t("settings:delete-user.delete-account"),
            danger: true,
            loading: deleting,
          },
        ]}
      >
        {balance ? (
          <Alert
            showIcon
            type="warning"
            message={
              <Trans
                t={t}
                i18nKey="settings:delete-user.request-payout"
                values={{ balance }}
              >
                Husk at anmode om en udbetaling inden du sletter din konto
                permanent. Du har {{ balance }} kr. stående på din konto.
                Kontakt vores support <IntButton>gennem chatten</IntButton>
                eller på
                <Mail>support@kanpla.dk</Mail>
              </Trans>
            }
          />
        ) : null}

        <h3 className="h400 mt-6">
          {t("settings:delete-user.validate-email-pwd")}
        </h3>
        <Form
          form={form}
          layout="vertical"
          className="mt-6"
          onFinish={deleteAccount}
        >
          <Form.Item
            name="email"
            label={capitalize(t("translation:email"))}
            rules={[
              {
                type: "email",
                required: true,
                message: t("translation:form.placeholders.email"),
              },
            ]}
          >
            <Input placeholder={t("translation:form.placeholders.email")} />
          </Form.Item>
          <Form.Item
            name="password"
            label={capitalize(t("translation:password"))}
            rules={[
              {
                type: "string",
                required: true,
                message: t("account-already-exists:enter-password"),
              },
            ]}
          >
            <Input.Password
              placeholder={t("account-already-exists:enter-password")}
            />
          </Form.Item>
        </Form>
      </DrawerOrModal>
    </>
  );
};

export default DeleteAccount;
