import { faFileExcel, faPrint } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { downloadBlob, useWindowSize } from "@kanpla/system";
import { DrawerOrModal, Spinner } from "@kanpla/ui";
import { Button, DatePicker, Radio, Switch } from "antd";
import axios from "axios";
import moment, { Moment } from "moment";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import slug from "slug";
import { useContainer } from "unstated-next";
import { getOrdersHistory as fetchOrdersHistory } from "../../lib/firebase.functions";
import { AppContext } from "../contextProvider";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

interface DisplayOrders {
  [childId: string]: {
    name: string;
    childId: string;
    totalAmount: number;
    totalPrice: number;
  };
}

type RadioValues = "week" | "biweek" | "month";
type PastOrFuture = "past" | "future";

const { RangePicker } = DatePicker;

const EmployeesOrders = (props: Props) => {
  const { t, i18n } = useTranslation(["modals", "translation"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const { open, setOpen } = props;

  const { width: screenWidth } = useWindowSize();

  const [radioValue, setRadioValue] = useState<RadioValues>("week");
  const [pastOrFuture, setPastOrFuture] = useState<PastOrFuture>("past");

  const [startDate, setStartDate] = useState(
    pastOrFuture === "past" ? moment().subtract(7, "days") : moment()
  );
  const [endDate, setEndDate] = useState(
    pastOrFuture === "past" ? moment() : moment().add(7, "days")
  );

  const [loading, setLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);

  const [orders, setOrders] = useState<DisplayOrders>(Object);
  const [total, setTotal] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<number>(0);

  const { school } = useContainer(AppContext);

  const addOrSubtractMoment = (
    amount: number,
    unit: any,
    condition: PastOrFuture
  ): Moment =>
    condition === "future"
      ? moment().add(amount, unit)
      : moment().subtract(amount, unit);

  const getOrdersHistory = async (from: Date, to: Date) => {
    setLoading(true);
    setOrders({});

    try {
      const res = await fetchOrdersHistory({
        from: from.getTime(),
        to: to.getTime(),
        schoolId: school?.id,
      });

      const {
        orders,
        total,
        amount,
      }: { orders: DisplayOrders; total: number; amount: number } = res.data;

      setTotal(total);
      setTotalAmount(amount);
      setOrders(orders);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!startDate || !endDate || !open) return;

    getOrdersHistory(
      startDate.utc().utcOffset(2).startOf("D").toDate(),
      endDate.utc().utcOffset(2).endOf("D").toDate()
    );
  }, [startDate, endDate, open]);

  const radioOptions = [
    {
      label: `7 ${t("modals:days")}`,
      value: "week",
    },
    {
      label: `14 ${t("modals:days")}`,
      value: "biweek",
    },
    {
      label: `1 ${t("modals:month")}`,
      value: "month",
    },
  ];

  const onRadioInputChange = async (
    { target },
    _pastOrFuture: PastOrFuture = null
  ) => {
    const { value }: { value: RadioValues } = target;

    const period = _pastOrFuture ? _pastOrFuture : pastOrFuture;

    try {
      setLoading(true);
      setRadioValue(value);

      const baseDateRadio =
        value === "week"
          ? addOrSubtractMoment(7, "days", period)
          : value === "biweek"
          ? addOrSubtractMoment(14, "days", period)
          : addOrSubtractMoment(1, "month", period);

      if (period === "past") {
        setStartDate(baseDateRadio);
        setEndDate(moment());
      } else {
        setStartDate(moment());
        setEndDate(baseDateRadio);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onRangeChange = (date: moment.Moment[]) => {
    if (date.length === 0) return;

    setRadioValue(null);

    const startDate = date[0];
    const endDate = date[1];

    setStartDate(startDate);
    setEndDate(endDate);
  };

  const printAndDownload = async (type: string) => {
    const fileName = `medarbejdernes-bestillinger-${slug(
      school.name
    )}-${startDate.format("MM-DD-YYYY")}-${endDate.format("MM-DD-YYYY")}`;

    try {
      if (type === "pdf") {
        setPrintLoading(true);
        const formatOrders = Object.entries(orders).map(([key, orders]) => {
          return [
            `${orders.name}`,
            `${orders.totalAmount}`,
            `${orders.totalPrice} kr.`,
          ];
        });

        const buildPdf = {
          content: [
            {
              text: `\n\n${t("translation:employee-orders")}`,
              style: "header",
            },
            {
              text: `${t("modals:order-for-school", {
                schoolName: school.name,
                start: startDate.format("L"),
                end: endDate.format("L"),
              })}\n\n`,
            },
            "\n\n",
            {
              style: "table",
              table: {
                widths: [300, "auto", "auto"],
                headerRows: 1,
                body: [
                  [
                    {
                      text: t("translation:name"),
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: t("translation:total-orders"),
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: t("translation:total-price"),
                      style: "tableHeader",
                      alignment: "center",
                    },
                  ],
                  ...formatOrders,
                  [
                    {
                      text: "\nTotal",
                      style: "tableFooter",
                    },
                    {
                      text: `\n${totalAmount}`,
                      style: "tableFooter",
                    },
                    {
                      text: `\n${t("translation:amount-with-value", {
                        value: total,
                      })}`,
                      style: "tableFooter",
                    },
                  ],
                ],
              },
            },
          ],
          styles: {
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 10],
            },
            subheader: {
              fontSize: 16,
              bold: true,
              margin: [0, 10, 0, 5],
            },
            table: {
              margin: [0, 5, 0, 15],
            },
            tableHeader: {
              bold: true,
              fontSize: 13,
              color: "black",
            },
            tableFooter: {
              bold: true,
              fontSize: 16,
              color: "black",
            },
          },
        };

        const res = await axios({
          method: "POST",
          url: "https://europe-west1-kanpla-87be3.cloudfunctions.net/createPdf",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          responseType: "blob",
          data: buildPdf,
        });

        const file: Blob = res.data;

        downloadBlob(file, `${fileName}.pdf`);
      } else {
        setExcelLoading(true);

        const formatOrders = Object.entries(orders).map(([key, orders]) => {
          return [`${orders.name}`, orders.totalAmount, orders.totalPrice];
        });

        const res = await axios({
          method: "POST",
          url: "https://europe-west1-kanpla-87be3.cloudfunctions.net/createExcel",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          responseType: "blob",
          data: {
            docTitle: t("modals:orders-report"),
            content: [
              [
                t("translation:periods", {
                  start: startDate.format("L"),
                  end: endDate.format("L"),
                }),
                t("translation:total-orders"),
                t("translation:total-price"),
              ],
              ...formatOrders,
              ["Total", totalAmount, total],
            ],
          },
        });

        const data =
          typeof res.data === "object"
            ? res.data
            : Buffer.from(res.data, "utf-8");

        const file: Blob = new Blob([data], {
          type: "application/octet-stream",
        });

        downloadBlob(file, `${fileName}.xlsx`);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setPrintLoading(false);
      setExcelLoading(false);
    }
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={t("translation:employee-orders")}
      className="max-w-6xl"
    >
      <div className="flex flex-col md:flex-row md:items-center mt-8 w-full h-auto">
        <div className="flex flex-col md:mr-8 mb-4 md:mb-0">
          <label className="form-label w-full mb-2 flex items-center justify-between">
            {t("modals:fixed-time")}
            <div className="flex items-center text-xs">
              {pastOrFuture === "past"
                ? t("translation:earlier")
                : t("modals:future")}{" "}
              {t("translation:orders")}
              <Switch
                size="small"
                checked={pastOrFuture === "future"}
                onChange={(checked) => {
                  const period: PastOrFuture = checked ? "future" : "past";
                  onRadioInputChange({ target: { value: radioValue } }, period);
                  setPastOrFuture(period);
                }}
                className="ml-2"
              />
            </div>
          </label>
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            // @ts-ignore
            size={screenWidth < 400 && "small"}
            options={radioOptions}
            onChange={onRadioInputChange}
            value={radioValue}
            defaultValue={7}
            disabled={loading}
          />
        </div>

        <div className="flex flex-col mb-4 md:mb-0">
          <label className="form-label mb-2">{t("modals:choose-time")}</label>
          <RangePicker
            onChange={onRangeChange}
            disabledDate={(current) => current < moment().subtract(3, "months")}
            className="relative"
            bordered
          />
        </div>

        <div className="flex flex-col md:ml-8">
          <label className="form-label mb-2">{t("translation:actions")}</label>
          <div className="flex flex-row">
            <Button
              loading={printLoading}
              onClick={() => printAndDownload("pdf")}
              className="h-9 w-16"
            >
              <FontAwesomeIcon icon={faPrint} />
            </Button>
            <Button
              loading={excelLoading}
              className="ml-4 h-9 w-16"
              onClick={() => printAndDownload("xlsx")}
            >
              <FontAwesomeIcon icon={faFileExcel} />
            </Button>
          </div>
        </div>
      </div>
      <div className="w-full border-b border-divider-main mt-6" />
      <div className="mt-8">
        {loading && (
          <div className="w-full h-full">
            <div className="flex justify-center w-full my-4">
              <Spinner size="small" useCurrentColor />
            </div>
            <div className="loader h-60 w-full rounded-sm" />
          </div>
        )}
        {!loading && Object.values(orders).length > 0 && (
          <>
            <h1 className="mb-4 text-text-secondary text-sm">
              {t("modals:displays-order-in-the-period", {
                start: startDate.format("L"),
                end: endDate.format("L"),
              })}
            </h1>
            <table className="table">
              <thead>
                <tr>
                  <th>{t("translation:name")}</th>
                  <th>{t("translation:total-orders")}</th>
                  <th>{t("translation:total-price")}</th>
                </tr>
              </thead>
              <tbody className="h-52 overflow-y-scroll">
                {Object.values(orders).map((order, i) => {
                  return (
                    <tr key={i}>
                      <td>{order.name}</td>
                      <td>{order.totalAmount}</td>
                      <td>
                        {t("translation:amount-with-value", {
                          value: order.totalPrice,
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <b className="text-lg">Total</b>
                  </td>
                  <td>
                    <b className="text-lg">{totalAmount}</b>
                  </td>
                  <td>
                    <b className="text-lg">
                      {t("translation:amount-with-value", {
                        value: total,
                      })}
                    </b>
                  </td>
                </tr>
              </tfoot>
            </table>
          </>
        )}
        {Object.values(orders).length === 0 && !loading && (
          <div className="w-full text-center form-label mt-12">
            {t("modals:no-bookings-long")}
          </div>
        )}
      </div>
    </DrawerOrModal>
  );
};

export default EmployeesOrders;
