import { faCartCirclePlus, faPen } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BasketList, OrderingContext } from "@kanpla/ordering";
import { ConfirmAnimation, DrawerOrModal, Modal } from "@kanpla/ui";
import { Button, Divider, Space } from "antd";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanContext } from "../";
import AnonymousBasket from "../../anonymous/AnonymousBasket";
import { AppContext } from "../../contextProvider";
import EditBasketModal from "../../modals/basket/EditBasketModal";
import BasketPreview from "../kanplaGo/BasketPreview";
import Payment from "./elements/Payment";
import PriceOverview from "./elements/PriceOverview";
import useBasketPurchaseLogic from "./useBasketPurchaseLogic";

const Basket = () => {
  const { t } = useTranslation(["mealplan2", "translation"]);

  const { module, hasKanplaGo } = useContainer(MealplanContext);
  const { mobile, auth } = useContainer(AppContext);
  const { openBasket, setOpenBasket, basketContainerUtils } =
    useContainer(OrderingContext);

  const [editModalOpen, setEditModalOpen] = useState(false);

  // All logic of the basket
  const {
    basketEditMode,
    setBasketEditMode,
    actions,
    basketPrice,
    handleBasketOpening,
    amountShownTakeFirstBanner,
    form,
    isCredit,
    hasMoney,
    setRememberCard,
    rememberCard,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    confirmationModalOpen,
    setConfirmationModalOpen,
  } = useBasketPurchaseLogic();

  // Refs
  const drawerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className="w-full md:w-1/3">
        {mobile && (
          <BasketPreview
            totalPrice={basketPrice}
            totalProducts={basketContainerUtils.totalItemsAmount}
            onClick={() => handleBasketOpening(true)}
          />
        )}
        <DrawerOrModal
          open={openBasket}
          setOpen={handleBasketOpening}
          title={mobile ? null : t("mealplan2:complete-the-order")}
          zIndex={40}
          noPadding={mobile}
          // @ts-ignore
          actions={actions}
        >
          {auth?.user ? (
            <div
              className="px-6 sm:px-4 md:px-0 md:pb-4 text-text-primary select-none"
              ref={drawerRef}
            >
              <Space direction="vertical" size="large" className="w-full">
                {amountShownTakeFirstBanner < 4 &&
                  hasKanplaGo &&
                  !module.plugins.kanplaGo?.hidePickProducts && (
                    <div className="bg-yellow-50 text-yellow-900 border border-yellow-600 rounded-lg p-2 px-3 flex items-center mb-3">
                      <div className="w-full flex justify-center">
                        <p className="font-semibold">
                          {t("mealplan2:remember-to-take-the-item")}
                        </p>
                      </div>
                    </div>
                  )}
                <BasketList
                  type="mealplan"
                  form={form}
                  setEditMode={setBasketEditMode}
                  editModalOpen={editModalOpen}
                  setEditModalOpen={setEditModalOpen}
                />

                <Button
                  type="dashed"
                  className="w-full"
                  onClick={() => setOpenBasket(false)}
                >
                  <Space>
                    <FontAwesomeIcon icon={faCartCirclePlus} />
                    {t("mealplan2:add-more-products")}
                  </Space>
                </Button>

                <Divider />

                {/* Price overview */}
                <PriceOverview
                  basketPrice={basketPrice}
                  isCredit={isCredit}
                  hasMoney={hasMoney}
                />

                {/* Payment */}
                <Payment
                  setRememberCard={setRememberCard}
                  rememberCard={rememberCard}
                  setSelectedPaymentMethod={setSelectedPaymentMethod}
                  selectedPaymentMethod={selectedPaymentMethod}
                  basketPrice={basketPrice}
                  hasMoney={hasMoney}
                />
              </Space>
            </div>
          ) : (
            <AnonymousBasket
              amountShownTakeFirstBanner={amountShownTakeFirstBanner}
              hasKanplaGo={hasKanplaGo}
              basketEditMode={basketEditMode}
              setBasketEditMode={setBasketEditMode}
            />
          )}
        </DrawerOrModal>
      </div>
      {!hasKanplaGo && (
        <Modal
          className="h-96 w-96"
          zMax
          open={confirmationModalOpen}
          setOpen={setConfirmationModalOpen}
          disableLock
        >
          <ConfirmAnimation mobile={mobile} out={confirmationModalOpen} />
        </Modal>
      )}

      <EditBasketModal
        open={editModalOpen}
        setOpen={(nextState: boolean) => {
          setEditModalOpen(nextState);
          setBasketEditMode(nextState);
        }}
        title={t("mealplan2:edit-basket") as string}
        actions={[
          {
            label: (
              <>
                <FontAwesomeIcon icon={faPen} className="mr-2" />{" "}
                {t("translation:done")}{" "}
              </>
            ),
            onClick: () => {
              setEditModalOpen(false);
              setBasketEditMode(false);
            },
            type: "primary",
          },
        ]}
      >
        <BasketList
          type="mealplan"
          form={form}
          editMode={basketEditMode}
          setEditMode={setBasketEditMode}
          editModalOpen={editModalOpen}
          setEditModalOpen={setEditModalOpen}
          hideHeader={true}
        />
      </EditBasketModal>
    </>
  );
};
export default Basket;
