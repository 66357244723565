import { OrderingContext } from "@kanpla/ordering";
import { editConfigAmount, getOrderConfigs } from "@kanpla/system";
import { CombinedOfferItem } from "@kanpla/types";
import { Checkbox, DisplayAttributes, InputAmount, Modal } from "@kanpla/ui";
import { Button } from "antd";
import classnames from "classnames";
import { isEmpty } from "lodash";
import { useMemo, useState } from "react";
import AnimateHeight from "react-animate-height";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";

interface Props {
  ticket: CombinedOfferItem;
  onTicketSelected: (ticketId: string, remove: boolean) => void;
}

const Card = (props: Props) => {
  const { ticket, onTicketSelected } = props;
  const { name, description, photo } = ticket;
  const { supplier, createHapticFeedback } = useContainer(AppContext);
  const { basket, setBasket } = useContainer(OrderingContext);

  const { t } = useTranslation(["libs"]);

  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);
  const [openAllergens, setOpenAllergens] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  const orderConfig = useMemo(() => getOrderConfigs(basket), [basket]);
  const targetTicketConfig = useMemo(
    () => orderConfig.find((c) => c.productId === ticket.id)?.config,
    [orderConfig, ticket.id]
  );

  const onCheck = (checked) => {
    createHapticFeedback({ type: "impact" });

    setOpen(checked);
    onTicketSelected(ticket.id, !checked);
    setSelected(checked);

    setChecked(checked);
  };
  const hasAllergens =
    (!isEmpty(ticket?.allergens) &&
      Object.values(ticket?.allergens || {})?.filter((a) => a)?.length > 0) ||
    !isEmpty(ticket?.labels) ||
    !isEmpty(ticket?.pictograms);

  return (
    <>
      <div
        className={
          "w-full px-4 py-6 flex flex-col bg-slate-400 bg-cover bg-no-repeat bg-center rounded-md shadow-lg hover:shadow-xl mb-4 h-fit"
        }
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 1)), url(${
            photo || ""
          })`,
        }}
        onClick={() => {
          !selected && setOpen((o) => !o);
          !hasAllergens && !description && onCheck(true);
        }}
      >
        <div className="flex justify-between">
          <h1 className="text-xl font-semibold text-white">{name}</h1>
          <Checkbox onChange={onCheck} defaultChecked={checked} />
        </div>
        <div className="flex flex-col text-white">
          <p className={classnames("w-72 text-sm mt-2", { truncate: !open })}>
            {description || ""}
          </p>
          <AnimateHeight
            duration={500}
            height={open ? "auto" : 0}
            animateOpacity
          >
            {hasAllergens && (
              <Button onClick={() => setOpenAllergens(true)}>
                {t("libs:allergens")}
              </Button>
            )}
            {!ticket?.["canOrderOnlyOne"] && selected && (
              <div
                className={classnames(
                  "flex w-full justify-end",
                  hasAllergens ? "mt-2" : "mt-8"
                )}
              >
                <InputAmount
                  amount={basket?.[ticket.id]?.amount || 1}
                  setAmount={(value: number) =>
                    setBasket(
                      editConfigAmount({
                        order: basket,
                        productId: ticket.id,
                        choice: targetTicketConfig.options,
                        amountChange: value,
                        replaceAmount: true,
                      })
                    )
                  }
                  minAmount={1}
                  maxAmount={Infinity}
                  size="small"
                  hapticCallback={() =>
                    createHapticFeedback({
                      type: "impact",
                      impactFeedback: "light",
                    })
                  }
                  stopPropagation
                />
              </div>
            )}
          </AnimateHeight>
        </div>
      </div>
      <Modal
        title={t("libs:allergens")}
        open={openAllergens}
        closableOutside={true}
        setOpen={setOpen}
        actions={[
          {
            label: "Luk",
            onClick: () => setOpenAllergens(false),
          },
        ]}
      >
        <DisplayAttributes
          attributes={{
            ...ticket?.labels,
            ...ticket?.allergens,
            ...ticket?.pictograms,
          }}
          mode="all"
          supplier={supplier}
          colorClassName="text-white"
        />
      </Modal>
    </>
  );
};

export default Card;
