import React, { useEffect, useState } from "react";
import { MultiDropdown, MultiDropdownItem } from "@kanpla/ui";
import {
  constructNewUrl,
  db,
  fetchCollection,
  fetchMultipleDocuments,
  sortModules,
} from "@kanpla/system";
import { Module, School } from "@kanpla/types";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";
import { sortBy } from "lodash";
import { useRouter } from "next/router";

const SalesplaceSwitch = () => {
  const { school, children, setChildId } = useContainer(AppContext);
  const [salesplaces, setSalesplaces] = useState<MultiDropdownItem[]>([]);
  const router = useRouter();

  const fetchAvailableSalesplaces = async () => {
    try {
      const childrenSchoolsIds = children.map((c) => c.schoolId);
      const allSchools = await fetchMultipleDocuments<School>(
        "schools",
        childrenSchoolsIds
      );

      const schools = allSchools.filter((s) => !s.deleted); // !s.hidden &&

      const schoolsSwitchData = (schools || [])?.reduce((acc, school) => {
        return [
          ...acc,
          {
            key: school?.id,
            label: school?.name || "-",
            ariaLabel: school?.name,
            ref: school,
          },
        ];
      }, [] as MultiDropdownItem[]);

      setSalesplaces(schoolsSwitchData);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchAvailableSalesplaces();
  }, [children.length]);

  return (
    <MultiDropdown
      hidden={false}
      defaultKey={school?.id}
      data={{
        items: sortBy(salesplaces, "label"),
        config: {
          activeFilter: (ref: School) => school && ref.id === school.id,
          onClick: async (sch: School) => {
            setChildId(children.find((ch) => ch.schoolId === sch.id)?.id);
            const modules = await fetchCollection<Module>(
              db
                .collection("modules")
                .where("scope.generatedSchoolIds", "array-contains", sch.id)
            );

            const sortedModules = sortModules(modules);

            const redirectToUrl = constructNewUrl(
              sch.id,
              sortedModules?.[0]?.id
            );

            router.push(redirectToUrl);
          },
        },
      }}
    />
  );
};

export default SalesplaceSwitch;
