import { faCheck as faCheckDuotone } from "@fortawesome/pro-duotone-svg-icons";
import { faCheck as faCheckLight } from "@fortawesome/pro-light-svg-icons";
import { faCheck as faCheckRegular } from "@fortawesome/pro-regular-svg-icons";
import { faCheck as faCheckSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import React, { useEffect, useState } from "react";

interface Props {
  /** Default value of the checkbox */
  defaultChecked?: boolean;
  /** Get the change values */
  onChange: (checked: boolean) => void;
  /** Makes the checkbox disabled */
  disabled?: boolean;
  /** Size of the checkbox, default to `md` */
  size?: "sm" | "md" | "lg";
  /** Icon type to use for the check, defaults to `solid` */
  iconType?: "solid" | "duotone" | "light" | "regular";
  /** Additional class names */
  className?: string;
}

const iconTypes = {
  solid: faCheckSolid,
  duotone: faCheckDuotone,
  light: faCheckLight,
  regular: faCheckRegular,
};

const sizes = {
  sm: "w-6",
  md: "w-8",
  lg: "w-12",
};

export const Checkbox = (props: Props) => {
  const {
    defaultChecked = false,
    onChange,
    disabled = false,
    size = "md",
    iconType = "solid",
    className = "",
  } = props;

  const [checked, setChecked] = useState<boolean>(defaultChecked);

  const classNames = classnames(
    `${sizes[size]} aspect-square cursor-pointer rounded-full self-start flex items-center justify-center`,
    {
      "bg-gray-300 pointer-events-none cursor-default": disabled,
      "bg-white": !checked,
      "bg-primary-main pop": checked,
    },
    className
  );

  useEffect(() => setChecked(defaultChecked), [defaultChecked]);

  return (
    <div
      className={classNames}
      onClick={(e) => {
        e.stopPropagation();

        return setChecked((prevValue) => {
          onChange(!prevValue);
          return !prevValue;
        });
      }}
    >
      <FontAwesomeIcon
        icon={iconTypes[iconType]}
        color="white"
        className="text-lg"
      />
    </div>
  );
};
