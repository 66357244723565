import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrderingContext } from "@kanpla/ordering";
import {
  calculateOrderTotal,
  getConfigNameAndId,
  getOrderConfigs,
  priceFormatter,
} from "@kanpla/system";
import { OrderInfo, OrderOrder, Timestamp } from "@kanpla/types";
import { Item, ReceiptWrapper, TooltipHiddenPrices } from "@kanpla/ui";
import { Button, Modal } from "antd";
import moment from "moment";
import { Dispatch, SetStateAction, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanMeetingContext } from ".";
import { MealplanContext } from "..";
import { AppContext } from "../../contextProvider";
import PaidFromAlert from "./views/PaidFromAlert";

interface Props {
  order: OrderOrder;
  orderInfo: OrderInfo;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  date: Timestamp;
}

const Receipt = (props: Props) => {
  const { open, setOpen, order, orderInfo, date } = props;

  const { t, i18n } = useTranslation(["mealplan2", "translation"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);

  const { currentView, setCurrentView, setIsConfirming } = useContext(
    MealplanMeetingContext
  );
  const { module, customBranding } = useContainer(AppContext);
  const { items, setNewOrderInfo } = useContainer(MealplanContext);
  const { basketContainerUtils } = useContainer(OrderingContext);

  const configs = getOrderConfigs(order);

  const dateMoment = moment.unix(date?.seconds || 0);
  const formatDay = dateMoment.format("dddd D.M.");

  const customColor =
    customBranding?.custom?.palette?.background?.primary || "#FFFFFF";

  const totalPrice = calculateOrderTotal(order);

  const { hidePrices } = module?.config || {};

  const close = () => {
    setOpen(false);
    basketContainerUtils.reset();
    setNewOrderInfo({});
    setIsConfirming(false);
    if (currentView === `ordering`) setCurrentView("overview");
  };

  return (
    <Modal
      open={open}
      onCancel={() => close()}
      footer={false}
      closable={false}
      centered
    >
      <div className="bg-background-primary -m-7 p-7">
        <div className="px-4 relative">
          <div className="flex flex-col items-center">
            <FontAwesomeIcon
              icon={faCheckCircle}
              size="2x"
              className="text-primary-main"
            />
            <h1
              className="text-2xl font-semibold mt-4 text-text-primary text-center"
              data-cy="order-confirmation"
            >
              {t("mealplan2:order-has-been-confirmed")}
            </h1>
            <div className="md:px-4 mt-4 w-full text-text-primary">
              <div className="mt-2">
                <h2 className="h300">{t("mealplan2:goods")}</h2>
                {configs.map(({ config, productId, name, price }) => (
                  <Item
                    hidePicture
                    color="lightColor"
                    config={config}
                    name={name}
                    productId={productId}
                    price={price}
                    items={items}
                    key={`${productId}-${
                      getConfigNameAndId(config.options)?.id
                    }`}
                    module={module}
                    dataCy="order-meeting-confirmation"
                  />
                ))}
              </div>
              {/* PAYMENT */}
              <div className="mt-6">
                <h2 className="h300">{t("translation:payment")}</h2>
                <div className="flex justify-between font-medium text-lg text-primary-main my-2">
                  <p>Total</p>
                  <p>
                    {priceFormatter(totalPrice, i18n, false, hidePrices, {
                      wrapper: () => "-",
                      component: () => <TooltipHiddenPrices />,
                    })}
                  </p>
                </div>
                <PaidFromAlert />
              </div>
              {/* INFO */}
              <div className="mt-6">
                <h2 className="h300">Info</h2>
                <p className="text-text-secondary text-xs">{orderInfo?.name}</p>
                <p className="text-text-secondary text-xs">{formatDay}</p>
                <ReceiptWrapper.InfoDisplay
                  orderInfo={orderInfo}
                  module={module}
                />
              </div>
            </div>
            <Button
              className="mt-12"
              onClick={() => {
                close();
              }}
            >
              {t("translation:close")}
            </Button>
          </div>

          <div className="bg-primary-main absolute inset-0 -m-7 -z-10" />
          <div
            className="absolute inset-x-0 bottom-0 -mb-8 -mx-7"
            style={{
              height: 4,
              backgroundRepeat: "repeat-x",
              backgroundImage: `url("data:image/svg+xml,%3Csvg width='12' height='4' viewBox='0 0 12 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 0H0V4H2C2 1.79086 3.79086 0 6 0C8.20914 0 10 1.79086 10 4H12V0H10H6H2Z' fill='%23${customColor.replace(
                "#",
                ""
              )}'/%3E%3C/svg%3E%0A")`,
            }}
          />
          <div
            className="absolute inset-x-0 top-0 -mt-8 -mx-7"
            style={{
              height: 4,
              transform: "scaleY(-1)",
              backgroundRepeat: "repeat-x",
              backgroundImage: `url("data:image/svg+xml,%3Csvg width='12' height='4' viewBox='0 0 12 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 0H0V4H2C2 1.79086 3.79086 0 6 0C8.20914 0 10 1.79086 10 4H12V0H10H6H2Z' fill='%23${customColor.replace(
                "#",
                ""
              )}'/%3E%3C/svg%3E%0A")`,
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default Receipt;
