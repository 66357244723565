import { CombinedOfferItem } from "@kanpla/types";
import { Dictionary, get, groupBy, isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import { MenuPreviewContext } from "./MenuPreviewContext";

export const useCategoriesDictionary = (
  propsDayIndex?: number
): Dictionary<CombinedOfferItem[]> => {
  const { items } = useContainer(MenuPreviewContext);
  const { t } = useTranslation("translation");
  const {
    dayIndex: contextDayIndex,
    schoolId,
    week,
    module,
  } = useContainer(AppContext);
  const dayIndex = propsDayIndex ?? contextDayIndex;
  const [categories, setCategories] = useState({});
  const category = module?.text?.["noCategory/text"] || t("dish-of-the-day");

  const filterAvailableItems = useCallback(
    (item: CombinedOfferItem) => {
      const dailyMenu = get(item, ["dates", week?.[dayIndex]?.seconds, "menu"]);
      const menuAvailable = !isEmpty(dailyMenu);

      const hasInfo = dailyMenu?.name !== "" || dailyMenu?.description !== "";

      const dayDisabled = get(item, ["days", dayIndex, "disabled"]);
      const schoolDayDisabled = get(item, [
        "schools",
        schoolId,
        "days",
        dayIndex,
        "disabled",
      ]);

      return !dayDisabled && !schoolDayDisabled && menuAvailable && hasInfo;
    },
    [dayIndex, schoolId, week]
  );

  useEffect(() => {
    const availableMenus = items
      .filter((i: CombinedOfferItem) => filterAvailableItems(i))
      .map((i) => ({ ...i, category: i?.category || category }));
    const groupedCategories = groupBy(
      availableMenus,
      (product) => product.category
    );
    setCategories(groupedCategories);
  }, [items, filterAvailableItems, category]);

  return categories;
};
