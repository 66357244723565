import { faCheck } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLastFlexOrderChange } from "@kanpla/system";
import { Divider, message } from "antd";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { FlexContext } from "..";
import { OrderingContext } from "../../context";
import LineWrapper from "../LineWrapper";
import HolidaysList from "../standardHolidays/HolidaysList";
import StandardHolidaysButton from "../standardHolidays/StandardHolidaysButton";
import StandardOrderButton from "../standardOrder/StandardOrderButton";
import MenuView from "./MenuView";

const MenuBulk = () => {
  const { t, i18n } = useTranslation(["flex", "translation", "flex-bulk"]);
  const hasLanguage = !isEmpty(i18n);

  const { date } = useContainer(OrderingContext);
  const {
    pastDate,
    deadline,
    softDeadlineMaxAmount,
    order,
    orderDocument,
    numberOfItems,
    isBulk,
    setSettingsOpen,
    weekProducts,
    hasStandardOrdering,
  } = useContainer(FlexContext);

  const { availableChanges } = getLastFlexOrderChange({
    order: orderDocument,
    date,
    deadline,
    localOrder: order,
    softDeadlineMaxAmount,
  });

  // Check soft deadlines max order changes
  useEffect(() => {
    if (!isBulk || !pastDate) return;

    if (availableChanges < 0)
      message.error({
        key: "error-exceeded",
        content: hasLanguage
          ? t("flex:message.error.exceeded")
          : "Du har overskredet maksimum af ændringer efter deadline",
      });
  }, [availableChanges, hasLanguage, isBulk, pastDate]);

  return (
    <div className="w-full">
      <div className="pb-6 pt-1">
        <div
          style={{
            columns: "1",
            breakInside: "avoid",
            columnFill: "balance",
            WebkitColumnFill: "balance",
            MozColumnFill: "balance",
            pageBreakInside: "avoid",
            columnGap: 32,
            MozColumnGap: 32,
            WebkitColumnGap: 32,
          }}
        >
          <MenuView selectedProducts={weekProducts} />
        </div>

        {isBulk && (
          <LineWrapper>
            <p className="text-text-secondary pt-3 pb-4 items-center flex justify-center relative">
              <FontAwesomeIcon icon={faCheck} className="mr-2 text-xl" />
              {hasLanguage
                ? t("flex:ordering-has-been-confirmed", {
                    value: numberOfItems,
                  })
                : `Bestilling på ${numberOfItems} kuverter er bekræftet`}
            </p>
          </LineWrapper>
        )}

        <Divider className="my-8" />

        {hasStandardOrdering && (
          <div className="flex flex-col gap-y-6">
            <div>
              <h3 className="h500">
                {hasLanguage
                  ? t("flex-bulk:default-selection")
                  : "Standardvalg"}
              </h3>
              <p>
                {hasLanguage
                  ? t("flex:default-selection-description")
                  : "Rediger dit standardvalg for de forskellige dage"}
              </p>
              <StandardOrderButton
                setOpen={setSettingsOpen}
                full={false}
                flex={false}
              />
            </div>

            <div>
              <h3 className="h500">
                {hasLanguage ? t("flex:holiday-periods") : "Ferieperioder"}
              </h3>
              <p>
                {hasLanguage
                  ? t("flex:holiday-periods-description")
                  : "I disse perioder får du ikke noget mad."}
              </p>

              <HolidaysList />

              <StandardHolidaysButton />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuBulk;
