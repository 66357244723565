export const getCellDesignSettings = () => {
  return {
    style: {
      backgroundColor: "var(--main-color-400)",
      borderColor: "var(--main-color-600)",
    },
    className: "text-text-primary",
  };
};

export const getHeaderCellDesignSettings = () => {
  return {
    style: {
      backgroundColor: "var(--main-color-600)",
      borderColor: "var(--main-color-800)",
    },
    className: "text-text-primary",
  };
};
