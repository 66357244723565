import { faCartShopping } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrderingContext } from "@kanpla/ordering";
import {
  calculateAmountOfOrderItems,
  db,
  deadlineFormatter,
  getDayIndexFromSeconds,
  getTodayTimestamp,
  getWeekArray,
  getWeekSeconds,
  isDatePastDeadline,
  isOrderIgnored,
  scroll2,
  Timestamp,
  useCollectionListener,
} from "@kanpla/system";
import {
  CombinedOfferItem,
  DayIndex,
  LoadOfferReturn,
  OrderInfo,
  OrderMealplan,
  OrderOrder,
  OrderOrderProduct,
  Timestamp as TimestampType,
} from "@kanpla/types";
import { DrawerOrModal, ModuleLoadingWrapper } from "@kanpla/ui";
import useDeadlineJump from "apps/frontend/lib/useDeadlineJump";
import { orderBy, sortBy } from "lodash";
import moment, { Moment } from "moment";
import { StringParam, useQueryParam } from "next-query-params";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createContainer, useContainer } from "unstated-next";
import CanteenClosed from "../CanteenClosed";
import { AppContext } from "../contextProvider";
import ModuleDescription from "../ModuleDescription";
import NavbarSecondary from "../NavbarSecondary";
import Basket from "./basket/index";
import NewReceipt from "./kanplaGo/receipt/index";
import MealplanMeeting from "./meeting";
import Products from "./Products";
import Receipt from "./Receipt";
import Registering from "./registering";
import { useCategories } from "./useCategories";
import useOrderInfo from "./useOrderInfo";

export interface SelectedCategoryData {
  index: number;
  trusted?: boolean;
}

const ContextState = () => {
  const { t, i18n } = useTranslation(["mealplan2", "payment"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);

  const {
    schoolId,
    setDate,
    setTimeNavigation,
    moduleId,
    module,
    week,
    dayIndex,
    childId,
    userId,
    dateSeconds,
    activePlugins,
    setDayIndex,
    setWeek,
    offer,
  } = useContainer(AppContext);

  const { setOpenBasket } = useContainer(OrderingContext);

  const today = getTodayTimestamp({ Timestamp });

  // KanplaGo receipt
  const [receiptOpen, setReceiptOpen] = useState(false);
  const [checkoutItems, setCheckoutItems] = useState<OrderOrder>({});
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({} as any), []);

  /** The initial index is null to not trigger any scroll while entering the page. */
  const [selectedCategoryIndex, setSelectedCategoryIndex] =
    useState<number>(null);
  const [highlightedCategoryIndex, setHighlightedCategoryIndex] =
    useState<number>(null);

  const [receiptTime, setReceiptTime] = useState<number>(moment().unix());

  const hasKanplaGo = useMemo(
    () => module?.plugins?.kanplaGo?.active,
    [module?.plugins?.kanplaGo?.active]
  );
  const hasPayPerOrder = useMemo(
    () => module?.plugins?.payPerOrder?.active,
    [module?.plugins?.payPerOrder?.active]
  );
  const requiresCredit = useMemo(
    () => module?.paymentMethod === "credit" && !hasKanplaGo && !hasPayPerOrder,
    [module?.paymentMethod, hasKanplaGo, hasPayPerOrder]
  );

  // Show or hide day switch on kanpla Go
  useEffect(() => {
    // if (meetingUI) return;
    setTimeNavigation(hasKanplaGo ? "none" : "todaySwitch");
  }, [hasKanplaGo, moduleId]);

  // Correct time on Kanpla Go
  useEffect(() => {
    const todayDate = getTodayTimestamp({ Timestamp });
    const alreadySet = todayDate.seconds === dateSeconds;
    if (hasKanplaGo && !alreadySet) setDate(todayDate);
  }, [hasKanplaGo, dateSeconds]);

  const {
    items: allItems,
    deadlineInfo = {} as LoadOfferReturn["deadlineInfo"],
    holidayDates = [] as LoadOfferReturn["holidayDates"],
    mealOptions = [] as LoadOfferReturn["mealOptions"],
  } = offer || {};

  // it is now possible for the items to be all filtered out, hence fallback
  const items = useMemo(() => {
    if (!allItems.length) return [];
    else
      return ((allItems as CombinedOfferItem[]) || []).filter((i) => {
        const isAvailable = i.dates?.[dateSeconds]?.available;
        return isAvailable;
      });
  }, [allItems, dateSeconds]);

  const oldDefaultDate = deadlineInfo?.defaultDate;

  // Deadline Jump
  useDeadlineJump({
    defaultDate: oldDefaultDate,
  });

  const noMealplan = items?.length === 0;

  // it is now possible for the items to be all filtered out, hence fallback
  const allOfferDates = useMemo(() => {
    if (!allItems.length) return [];
    else
      return ((allItems as Array<CombinedOfferItem>) || [])
        ?.map((item) =>
          Object.keys(
            Object.fromEntries(
              Object.entries(item.dates).filter(([, value]) => value.available)
            )
          )
        )
        ?.flat()
        ?.map((d) => parseInt(d as string))
        .flat();
  }, [allItems]);

  const hasOfferOnDate = useMemo(
    () => allOfferDates.sort().find((date) => date >= dateSeconds),
    [dateSeconds, allOfferDates]
  );

  /** Used as the next closest date */
  const defaultDate: TimestampType = useMemo(
    () =>
      hasOfferOnDate ? Timestamp.fromMillis(hasOfferOnDate * 1000) : today,
    [hasOfferOnDate, today]
  );

  // Required product
  const hasRequiredProduct =
    module?.plugins?.requiredProduct?.active &&
    items?.some(
      (p: CombinedOfferItem) =>
        p.productId === module?.plugins?.requiredProduct?.productId
    );

  // Order
  const [allOrders = []] = useCollectionListener<OrderMealplan>(
    childId && userId && moduleId && week[dayIndex]
      ? db
          .collection("orders")
          .where("childId", "==", childId)
          .where("userId", "==", userId)
          .where("dateSeconds", "==", week[dayIndex]?.seconds)
          .where("moduleId", "==", moduleId)
      : null
  );

  const orders = useMemo(
    () =>
      orderBy(allOrders, "createdAtSeconds").filter(
        (o) => calculateAmountOfOrderItems(o.order) > 0
      ),
    [allOrders]
  );

  const [ignoredOrderIds, setIgnoredOrderIds] = useState<Array<string>>([]);
  const [allowedOrderIds, setAllowedOrderIds] = useState<Array<string> | null>(
    null
  );

  const orderDocument =
    orders.find(
      (o) =>
        !isOrderIgnored({
          ignoredOrderIds,
          allowedOrderIds,
          orderId: o.id,
        }) && calculateAmountOfOrderItems(o.order) > 0
    ) || ({} as OrderMealplan);

  const order = orderDocument.order || ({} as OrderOrder);
  const orderInfo = orderDocument.info || ({} as OrderInfo);

  const numberOfItems = Object.values(order).reduce(
    (a: number, c: OrderOrderProduct) => a + c.amount,
    0
  ) as number;
  const hasOrdered = numberOfItems > 0;

  // Deadline
  const { deadline, deadlineWeekRelative, deadlineExcludingWeekends } =
    deadlineInfo;
  const deadlineFormatted = deadlineFormatter({
    date: week[dayIndex],
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
  });

  /** Figure out next jump date */
  const hasOfferInFuture = useMemo(
    () =>
      allOfferDates.sort().find(
        (date) =>
          date > dateSeconds &&
          !isDatePastDeadline({
            date: Timestamp.fromMillis(date * 1000),
            deadline,
            deadlineExcludingWeekends,
            deadlineWeekRelative,
          })
      ),
    [
      allOfferDates,
      dateSeconds,
      deadline,
      deadlineExcludingWeekends,
      deadlineWeekRelative,
    ]
  );

  const nextOfferDate: TimestampType = useMemo(
    () => Timestamp.fromMillis(hasOfferInFuture * 1000),
    [hasOfferInFuture]
  );

  /** The user won't be able to see the offer */
  const pastDate = (week[dayIndex]?.seconds || 0) < (defaultDate?.seconds || 0);

  /** The user won't be able to change the order */
  const isPastDeadline = isDatePastDeadline({
    date: week[dayIndex],
    deadline,
    deadlineExcludingWeekends,
    deadlineWeekRelative,
  });

  const isOrderingForToday = week[dayIndex]?.seconds === today.seconds;
  const hasLeftovers = (items || []).some(
    (product) => product?.isLeftover === true
  );

  // Add option with holidays
  let activeHoliday = holidayDates?.[dateSeconds];

  const { newOrderInfo, setNewOrderInfo } = useOrderInfo({ orderInfo });

  /** This is used to add query params to the URL and trigger opening of a product */
  const [productId, setProductId] = useQueryParam("productId", StringParam);

  const todayDayIndex = getDayIndexFromSeconds(today.seconds);
  const isWeekend = todayDayIndex > 4;
  if (isWeekend && activePlugins.kanplaGo) {
    activeHoliday = {
      name: "Weekend",
      design: {
        title: t("mealplan2:holds"),
        text: t("mealplan2:come-back-monday"),
      },
      // Other props (not needed)
      partnerId: null,
      days: [],
      createdAt: null,
      createdBy: "",
      schoolIds: [schoolId],
      moduleIds: [moduleId],
      id: null,
    };
  }

  const handleDateChange = (date: Moment) => {
    const dateSeconds = date.unix();
    const weekSeconds = getWeekSeconds(dateSeconds);
    const weekArray = getWeekArray(weekSeconds, Timestamp);
    const dayIndex = parseInt(
      getDayIndexFromSeconds(dateSeconds).toFixed(0)
    ) as DayIndex;

    setDayIndex(dayIndex);
    setWeek(weekArray);
  };

  // KANPLA GO LISTENER TEMP
  const retrieveAndSubmitOrder = async () => {
    if (!hasKanplaGo) return;
    const sorted = sortBy(orders, (o) => -o?.updatedAtSeconds || -Infinity);
    const latestOrder = sorted?.[0];
    if (!latestOrder) return;

    const secondsNow = moment().unix();
    const timeoutInSeconds = 60 * 2;
    const expired =
      latestOrder.updatedAtSeconds + timeoutInSeconds < secondsNow;
    if (expired) return;

    setOpenBasket(false);
    setReceiptTime(latestOrder.createdAtSeconds);
    setCheckoutItems(latestOrder.order);
    setReceiptOpen(true);
  };
  useEffect(() => {
    retrieveAndSubmitOrder();
  }, [orders?.map((p) => p.id).join(",")]);

  return {
    items,
    orderInfo,
    orderDocument,
    orders,
    ignoredOrderIds,
    setIgnoredOrderIds,
    allowedOrderIds,
    setAllowedOrderIds,
    order,
    hasOrdered,
    noMealplan,

    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    deadlineFormatted,
    defaultDate,
    nextOfferDate,
    pastDate,
    isPastDeadline,

    isOrderingForToday,
    module,
    moduleId,
    requiresCredit,
    hasKanplaGo,
    hasRequiredProduct,
    forceUpdate,

    hasPayPerOrder,

    hasLeftovers,
    receiptOpen,
    setReceiptOpen,
    checkoutItems,
    setCheckoutItems,

    receiptTime,
    setReceiptTime,
    activeHoliday,
    numberOfItems,
    mealOptions,
    holidayDates,
    newOrderInfo,
    setNewOrderInfo,

    productId,
    setProductId,
    selectedCategoryIndex,
    setSelectedCategoryIndex,
    highlightedCategoryIndex,
    setHighlightedCategoryIndex,
    handleDateChange,
  };
};

export const MealplanContext = createContainer(ContextState);

const Mealplan = () => {
  const { module } = useContainer(AppContext);
  const meetingUI = module.flow === "meeting";
  const registeringUI = module.flow === "registering";

  const hasPayPerOrder = module?.plugins?.payPerOrder?.active;

  return (
    <MealplanContext.Provider>
      {meetingUI ? (
        <MealplanMeeting />
      ) : registeringUI ? (
        <Registering />
      ) : (
        <MealplanMealplan />
      )}

      {!hasPayPerOrder && (
        <NewReceipt
          hideSkipQueue={module?.plugins?.kanplaGo?.hideSkipQueue ?? false}
        />
      )}
    </MealplanContext.Provider>
  );
};

const MealplanMealplan = () => {
  const { innerAppLoading } = useContainer(AppContext);
  const {
    activeHoliday,
    defaultDate,
    module,
    deadlineFormatted,
    hasKanplaGo,
    selectedCategoryIndex,
    setHighlightedCategoryIndex,
  } = useContainer(MealplanContext);

  const {
    setShouldNotifyUserAfterRefill,
    shouldNotifyUserAfterRefill,
    setOpenBasket,
  } = useContainer(OrderingContext);

  const { t } = useTranslation([
    "register",
    "payment",
    "mealplan2",
    "design",
    "components",
    "translation",
  ]);

  const categories = useCategories();

  /**
   * Issue: On switching into pages if the previous page was scrolled,
   * then on a mobile device the current page is being opened already scrolled.
   */
  useEffect(() => {
    if (innerAppLoading) return;

    scroll2({ top: 0 });
  }, [innerAppLoading]);

  const confirmButtonText = useMemo(
    () => `${t("design:go-to")} ${t("design:basket").toLowerCase()}`,
    [t]
  );

  return (
    <>
      <DrawerOrModal
        open={shouldNotifyUserAfterRefill}
        setOpen={setShouldNotifyUserAfterRefill}
        actions={[
          {
            type: "primary",
            onClick: () => {
              setShouldNotifyUserAfterRefill(false);
              setOpenBasket(true);
            },
            label: (
              <>
                <FontAwesomeIcon icon={faCartShopping} className="mr-2" />
                {confirmButtonText}
              </>
            ),
          },
        ]}
      >
        <div className="flex justify-center flex-col text-center">
          <FontAwesomeIcon icon={faCartShopping} className="text-6xl" />
          <h2 className="font-semibold text-xl mt-5">
            {t("register:almost-there")}
          </h2>
          <p className="mt-1 text-xs text-text-secondary">
            {t("payment:credit-charge-approved")}
          </p>
          <p className="font-semibold mt-10 text-sm">
            {t("mealplan2:remember-to-confirm")}
          </p>
        </div>
      </DrawerOrModal>
      <NavbarSecondary
        deadlineFormatted={deadlineFormatted}
        timeNavigation={hasKanplaGo ? "none" : "daily"}
        selectedCategoryIndex={selectedCategoryIndex}
        setSelectedCategoryIndex={setHighlightedCategoryIndex}
        categories={categories}
      />
      {activeHoliday ? (
        <CanteenClosed
          defaultDate={defaultDate}
          holidayDesc={activeHoliday.design}
          disableJumpLink={hasKanplaGo}
          t={t}
        />
      ) : (
        <div className="wrapper mt-2 lg:mt-8">
          <Receipt />
          <div className="md:pt-1">
            <ModuleDescription align="left" module={module} />
          </div>
          <ModuleLoadingWrapper loading={innerAppLoading}>
            <Products />
          </ModuleLoadingWrapper>
          <Basket />
        </div>
      )}
    </>
  );
};

export default Mealplan;
