/* eslint-disable react-hooks/rules-of-hooks */
import { FlexBulkStandard } from "@kanpla/types";
import { useCollectionListener } from "../firestore/UseCollectionListener";

interface Props {
  schoolId: string;
  moduleId: string;
  childId: string;
  /** For permissions for individual standard */
  userId: string;
  /** If true, looking for bulk standard */
  isBulk?: boolean;
  /** Database provider */
  db: any;
}

export const getFlexHolidays = (props: Props) => {
  const { schoolId, moduleId, childId, isBulk, db, userId } = props;

  const individualRef = db
    .collection("flexStandards")
    .where("childId", "==", childId || null)
    .where("schoolId", "==", schoolId || null)
    .where("moduleId", "==", moduleId || null)
    .where("userId", "==", userId || null);

  const ref = individualRef;

  const [settings, holidaysLoading] =
    useCollectionListener<FlexBulkStandard>(ref);

  const holidays = (settings?.[0]?.holidays ||
    []) as FlexBulkStandard["holidays"];

  return { holidays, holidaysLoading };
};
