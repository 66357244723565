import { getDaysInRange } from "@kanpla/system";
import { FlexStandard } from "@kanpla/types";
import moment from "moment";

export type HolidayRange = Record<keyof FlexStandard["holidays"][0], number>;

export const getRanges = (periods: HolidayRange[] = []) => {
  if (!periods?.length) return [];

  return periods
    .filter((h) => Boolean(h.fromSeconds && h.toSeconds))
    .map((holiday) => {
      const startDate = moment.unix(holiday.fromSeconds);
      const endDate = moment.unix(holiday.toSeconds);

      return {
        ...holiday,
        ranges: getDaysInRange(startDate, endDate, "unix") as number[],
      };
    });
};
