import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { constructNewUrl } from "@kanpla/system";
import { Modal } from "@kanpla/ui";
import { Button } from "antd";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useCallback } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanContext } from "..";
import { AppContext } from "../../contextProvider";
import useBasketPurchaseLogic from "../basket/useBasketPurchaseLogic";
import Content from "./Content";
import Header from "./Header";

interface Props {
  onTicketSelected: (ticketId: string, remove: boolean) => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const RegisteringModal = ({ onTicketSelected, open, setOpen }: Props) => {
  const { module, schoolId, previousModuleId } = useContainer(AppContext);
  const { items } = useContainer(MealplanContext);

  const { actions } = useBasketPurchaseLogic();
  const { t } = useTranslation(["mealplan2", "translation"]);
  const router = useRouter();

  const hasNoProducts = isEmpty(items);

  const goBack = useCallback(() => {
    const newUrl = constructNewUrl(schoolId, previousModuleId);
    router.push(newUrl);
  }, [previousModuleId, router, schoolId]);

  return isMobile ? (
    open && (
      <div className="max-w-md mx-auto md:border rounded-lg mt-8">
        <Header />
        <Content onTicketSelected={onTicketSelected} />
      </div>
    )
  ) : (
    <Modal
      open={open}
      setOpen={setOpen}
      title={
        module?.text?.["description/title"] ||
        t("mealplan2:registration.it-is-lunchtime")
      }
      subtitle={
        module?.text?.["description/text"] || t("mealplan2:registration.how-to")
      }
      // @ts-ignore
      actions={
        hasNoProducts
          ? [
              {
                label: t("translation:ga-back"),
                onClick: () => goBack(),
                type: "primary",
              },
            ]
          : actions
      }
      extraTopContent={
        !hasNoProducts && (
          <Button size="small" className="mb-2" onClick={() => goBack()}>
            <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
            {t("translation:back")}
          </Button>
        )
      }
    >
      <Content onTicketSelected={onTicketSelected} hideActions />
    </Modal>
  );
};

export default RegisteringModal;
