import {
  calculateSubscriptionPrice,
  deadlineProvider,
  fn,
  getReepayErrorText,
  Timestamp,
} from "@kanpla/system";
import { SubscriptionPeriod, SubscriptionProduct } from "@kanpla/types";
import { Product } from "@kanpla/ui";
import { message } from "antd";
import useSubscriptions from "apps/frontend/lib/useSubscriptions";
import { groupBy, isEmpty } from "lodash";
import moment, { Moment } from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { SubscriptionContext } from "..";
import useChargeSession from "../../../lib/payment/UseChargeSession";
import { useConfirmation } from "../../confirmProvider";
import { AppContext } from "../../contextProvider";
import useWindowPayment from "../../../lib/payment/useWindowPayment";
import ErrorModal, { CardErrorProps } from "../../modals/payment/ErrorModal";
import ChooseDate from "./ChooseDate";
import ChooseName from "./ChooseName";
import OrderSummary from "./OrderSummary";

interface Props {
  product: SubscriptionProduct;
  periods: Array<SubscriptionPeriod>;
}

const ProductWrapper = (props: Props) => {
  const { product, periods } = props;

  const { t } = useTranslation(["subscription", "translation"]);

  const { child, card, customBranding } = useContainer(AppContext);

  const [loading, setLoading] = useState(false);

  const productId = product.id;
  const confirm = useConfirmation();

  const subscriptions = useSubscriptions();
  const existingSubscription = subscriptions.find(
    (sub) => sub.productId === product.id && sub.to >= Timestamp.now()
  );

  const allAvailablePeriods = periods.filter(
    (period) =>
      period.products.map((p) => p.id).includes(productId) &&
      period.to?.seconds > moment().unix()
  );

  const [selectedName, setSelectedName] = useState(
    allAvailablePeriods[0]?.name || ""
  );
  const [selectedPeriod, setSelectedPeriod] = useState<SubscriptionPeriod>(
    allAvailablePeriods[0]
  );

  const { school } = useContainer(AppContext);
  const { module } = useContext(SubscriptionContext);

  const nowSeconds = useMemo(() => moment().unix(), []);

  const { deadline } = deadlineProvider({ module, school });
  const signupSeconds = useMemo(
    () =>
      (selectedPeriod?.signupDates || [])
        .map((d) => d.seconds)
        .filter((s) => s + deadline > nowSeconds)
        .sort((a, b) => a - b),
    [deadline, nowSeconds, selectedPeriod?.signupDates]
  );

  const [startDate, setStartDate] = useState<Moment>(
    moment.unix(signupSeconds[0])
  );

  const [existingCardSelected, setExistingCardSelected] = useState(!!card);

  const [acceptedTerms, setAcceptedTerms] = useState(false);

  useEffect(() => {
    const thisPeriod = allAvailablePeriods
      .sort((a, b) => a.from.seconds - b.from.seconds)
      .find(
        (p) =>
          p.name === selectedName &&
          p.signupDates?.some(
            (date) =>
              // @ts-ignore
              (date?.seconds || date?._seconds) +
                (module?.config?.deadline || 0) >
              nowSeconds
          )
      );

    if (!thisPeriod) return;

    setSelectedPeriod(thisPeriod);

    const newSignupDates = [
      thisPeriod.from.seconds,
      ...thisPeriod.signupDates.map((d) => d.seconds),
    ];

    // If currently selected start is valid, return
    if (newSignupDates.includes(startDate.unix())) return;

    const firstAvailableSignup = newSignupDates
      .sort()
      .find((s) => s > nowSeconds);
    setStartDate(moment.unix(firstAvailableSignup));
  }, [selectedName, nowSeconds]);

  const { callbackUrl } = useWindowPayment({
    mode: "subscription",
  });

  const onSuccess = () => {
    message.success(t("subscription:message.success.payment-successfull"));
  };

  const [cardErrorModal, setCardErrorModal] = useState(false);
  const [cardError, setCardError] = useState<CardErrorProps>({
    text: t("translation:payment-failed"),
  });

  const { loadChargeSession } = useChargeSession({
    setLoading,
    onError: (err) => {
      setCardError(getReepayErrorText(err.error));
      setCardErrorModal(true);
    },
  });

  const onPurchase = async () => {
    if (loading) return;
    if (!acceptedTerms) {
      throw new Error(
        t("subscription:message.error.must-accept-the-subscription-terms")
      );
    }

    try {
      if (existingSubscription && !isEmpty(existingSubscription)) {
        await confirm({
          title: t("subscription:already-have-this-subscription"),
          catchOnCancel: true,
        });
      }

      setLoading(true);

      const periodId = selectedPeriod?.id;

      if (!periodId) return;

      const orderData = {
        periodId,
        productId,
        startSeconds: startDate.unix(),
        childId: child.id,
        callbackUrl: callbackUrl,
        isWindowSession: true,
      };

      message.loading(
        t("subscription:message.loading.payment-being-processed")
      );

      try {
        if (existingCardSelected) {
          const orderWithExistingCard = fn.httpsCallable(
            "subscriptionOrderExistingCard"
          );
          await orderWithExistingCard(orderData);
          onSuccess();
        } else {
          const orderWithNewCard = fn.httpsCallable("subscriptionOrderNewCard");
          const res = await orderWithNewCard(orderData);
          const sessionId = res.data;
          await loadChargeSession(sessionId);
          onSuccess();
        }
      } catch (err) {
        message.error(t("translation:payment-failed"));
      } finally {
        setLoading(false);
      }
    } catch (e) {
      console.info("You don't want this subscription");
      return;
    }
  };

  const getPrice = (period: SubscriptionPeriod) => {
    const targetProduct = period.products?.find((p) => p.id === productId);
    const periodPrice = calculateSubscriptionPrice(targetProduct, 1);
    return periodPrice;
  };

  const prices = [...new Set(allAvailablePeriods.map((p) => getPrice(p)))].sort(
    (a, b) => a - b
  );
  const differentPrices = prices.length > 1;

  const isChoosingName =
    Object.keys(groupBy(allAvailablePeriods, (p) => p.name || "")).length >= 2;

  const deliveryDates = selectedPeriod?.deliveryDates?.filter(
    (d) => d.seconds >= startDate?.valueOf() / 1000
  );
  const numberOfDates = deliveryDates?.length || 0;

  const price = prices[0];
  const periodPrice = calculateSubscriptionPrice(product, numberOfDates);

  return (
    <>
      <Product
        customBranding={customBranding}
        product={{
          ...product,
          price,
          periodPrice,
          multiplePrices: differentPrices,
          extraPriceLabelText:
            product["priceType"] === "period"
              ? ` ${t("subscription:per-days", { value: numberOfDates })}`
              : ` ${t("subscription:per-day")}`,
        }}
        module={module}
        initialAmount={numberOfDates}
        onPurchase={onPurchase}
        hidePriceInLabel
        purchaseLabel={t("subscription:order-and-pay")}
        // Disables animation
        fromAdmin
        hideAmount={true}
        orderButtonDisabled={!acceptedTerms}
        tooltip={{
          hasTooltip: !acceptedTerms,
          title: t("subscription:accept-terms") as string,
          style: { width: "100%" },
        }}
        setAcceptedTerms={setAcceptedTerms}
        customInputs={
          <>
            <ChooseName
              selectedName={selectedName}
              setSelectedName={setSelectedName}
              periods={periods}
              isChoosingName={isChoosingName}
            />

            {selectedName !== null && (
              <ChooseDate
                productId={productId}
                startDate={startDate}
                setStartDate={setStartDate}
                setSelectedPeriod={setSelectedPeriod}
                periods={allAvailablePeriods.filter(
                  (p) => !selectedName || p.name === selectedName
                )}
                isChoosingName={isChoosingName}
              />
            )}

            {startDate && (
              <OrderSummary
                productId={productId}
                selectedPeriod={selectedPeriod}
                startDate={startDate}
                loading={loading}
                existingCardSelected={existingCardSelected}
                setExistingCardSelected={setExistingCardSelected}
                isChoosingName={isChoosingName}
                acceptedTerms={acceptedTerms}
                setAcceptedTerms={setAcceptedTerms}
                module={module}
              />
            )}
          </>
        }
      />
      <ErrorModal
        open={cardErrorModal}
        setOpen={setCardErrorModal}
        cardError={cardError}
        setCardError={setCardError}
      />
    </>
  );
};

export default ProductWrapper;
