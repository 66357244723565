import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Space } from "antd";
import React from "react";
import { useBoundingclientrectRef } from "rooks";
import BannerBackgroundImage from "./BannerBackgroundImage";
import { getProportionalFontSize, getProportionalHeightPadding } from "./utils";

interface Props {
  title: string;
  imageUrl: string;
  link: string;
  linkText: string;
  overlayOpacity?: number;
}

const BannerTwo = ({
  title,
  imageUrl,
  link,
  linkText,
  overlayOpacity,
}: Props) => {
  const [bannerRef, boundingClientRect] = useBoundingclientrectRef();
  if (!title && !imageUrl) return null;
  const width = boundingClientRect?.width || 0;

  return (
    <div
      ref={bannerRef}
      className={`w-full h-full rounded-lg overflow-hidden relative shadow-lg bg-gray-900 `}
      style={{ minHeight: 128 }}
    >
      <BannerBackgroundImage
        src={imageUrl}
        alt={title}
        size={{ w: width }}
        opacity={1 - overlayOpacity / 140}
      />
      <div
        className={`flex flex-col items-start justify-end relative z-20 inset-0 p-4 ${getProportionalHeightPadding(
          width,
          "bottom",
          true
        )} h-full text-white`}
        style={{ minHeight: 128 }}
      >
        {title && (
          <h3
            className={`h500 text-white text-left ${getProportionalFontSize(
              width,
              "text-xl"
            )}`}
          >
            {title}
          </h3>
        )}

        {link && linkText && (
          <span className="text-white hover:text-white group transition-opacity hover:opacity-80">
            <Space
              className={`text-white ${getProportionalFontSize(
                width,
                "text-sm"
              )} text-left`}
            >
              {linkText}
              <FontAwesomeIcon
                icon={faArrowRight}
                className="group-hover:translate-x-1 transform transition-transform"
              />
            </Space>
          </span>
        )}
      </div>
    </div>
  );
};

export default BannerTwo;
