import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PopupConstructor } from "@kanpla/types";
import { Image, Modal as UIModal } from "@kanpla/ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  popup: PopupConstructor.Modal;
  closePopup: () => void;
}

const Modal = ({ popup, closePopup }: Props) => {
  const { t } = useTranslation(["translation"]);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (!open) setTimeout(closePopup, 500);
  }, [open]);

  const [image, title, text, cta] = popup?.blocks || [];

  const sizes = {
    h1: "h700",
    h2: "h600",
    h3: "h500",
    h4: "h400",
  };

  return (
    <UIModal
      open={open}
      containerClassName="flex items-center"
      className="overflow-hidden"
      setOpen={() => setOpen(false)}
    >
      {/* Close button */}
      <button
        aria-label={t("translation:close-banner")}
        className="absolute z-max right-3 top-3 p-1 px-2 rounded-full bg-primary-main hover:bg-primary-main transition-colors flex items-center justify-center w-10 h-10 shadow-lg"
        onClick={() => setOpen(false)}
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="text-background-primary text-lg"
        />
      </button>

      {/* Image */}
      {image?.src && (
        <div className="-mx-8 -mt-8 w-auto h-56 mb-8 object-center overflow-hidden object-cover relative">
          <Image
            size={{ w: 500 }}
            src={image.src}
            alt={image.alt || title?.text || "Illustrative banner image"}
            className="h-full w-full object-cover object-center block"
          />
        </div>
      )}

      {/* Title */}
      {title && (
        <p className={`${sizes[title.size || "h2"]} mb-2 -mx-2 md:mx-0`}>
          {title.text}
        </p>
      )}

      {/* Text */}
      <p
        className="-mx-2 md:mx-0"
        dangerouslySetInnerHTML={{ __html: text?.text }}
      />

      {/* Cta button */}
      {cta?.label && cta?.link && (
        <a
          href={cta.link}
          target="_blank"
          className="w-auto -mx-8 -mb-8 block"
          rel="noreferrer"
        >
          <button
            onClick={() => setOpen(false)}
            className="bg-primary-main text-background-primary min-w-full w-auto block p-3 md:p-6 mt-8 text-center transition-colors hover:bg-primary-main"
          >
            {cta.label}
          </button>
        </a>
      )}
    </UIModal>
  );
};

export default Modal;
