import { faArrowLeft } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  LoadFlexBulkProps,
  LoadFlexBulkReturn,
  SubmitFlexBulkOrderProps,
  SubmitFlexBulkOrderReturn,
} from "@kanpla/services";
import {
  callInternalApi,
  constructNewUrl,
  deadlineDisplay,
  deadlineFormatter,
  hasAccessToModule,
  isBelowMinimum,
  isDatePastDeadline,
  shouldHaveStandardOrdering,
  Timestamp,
  useFetch,
  useSubmit,
} from "@kanpla/system";
import {
  CombinedOfferItem,
  DayIndex,
  flexBulkOrder,
  FlexBulkStandard,
  Holiday,
  HolidaysRecurring,
  Module,
  OrderOrder,
  Timestamp as TimestampType,
} from "@kanpla/types";
import { ButtonSave, ModuleLoadingWrapper } from "@kanpla/ui";
import { Button, message, Space } from "antd";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { createContainer, useContainer } from "unstated-next";
import { OrderingContext } from "../context";
import Menu from "./Menu";

interface WeekOrders {
  [dayIndex: number]: OrderOrder;
}

interface WeekDocuments {
  [dayIndex: number]: flexBulkOrder;
}

const ContextState = () => {
  const { t, i18n } = useTranslation(["translation", "flex-bulk"]);

  const {
    school,
    week,
    child,
    schoolId,
    childId,
    date,
    setTimeNavigation,
    module,
    moduleId,
    setIsBulk,
    offer,
    userId,
    fromAdmin,
    adminId,
    modules,
  } = useContainer(OrderingContext);

  const router = useRouter();
  const hasLanguage = !isEmpty(i18n);

  useEffect(() => {
    const ha = hasAccessToModule({
      child,
      module,
      school,
    });

    if (!ha.bulk) {
      const newUrl = constructNewUrl(schoolId, moduleId);
      setIsBulk(false);
      router.replace(newUrl);
    }
  }, []);

  useEffect(() => {
    setTimeNavigation("calendarWeekly");
  }, []);

  // Standard
  const [standardOrders, setStandardOrders] =
    useState<FlexBulkStandard["standard"]>(null);

  // Order
  const [weeklyOrders, setWeeklyOrders] = useState<WeekOrders>(null);
  const [orderDocuments, setOrderDocuments] = useState<WeekDocuments>(null);

  const keys = useMemo(
    () => ({
      moduleId,
      groupName: child?.groupName,
      schoolId,
      weekSeconds: week?.[0]?.seconds,
    }),
    [child?.groupName, moduleId, schoolId, week]
  );

  const { data, setData } = useFetch<LoadFlexBulkProps, LoadFlexBulkReturn>(
    "load/flexBulk",
    keys
  );

  useEffect(() => {
    if (!data) return;
    const { weekOrders, weekDocuments, standard } = data;
    // Reset everything on load
    setStandardOrders(standard);
    setWeeklyOrders(weekOrders);
    setOrderDocuments(weekDocuments);
  }, [data, router.pathname]);

  // Standard View
  const [saved, setSaved] = useState(true);
  const [isStandardView, setStandardView] = useState(false);

  const orders = isStandardView ? standardOrders : weeklyOrders;

  const setOrders = (newValue: WeekOrders) => {
    setSaved(false);
    if (isStandardView) {
      setStandardOrders(newValue);
    } else {
      setWeeklyOrders(newValue);
    }
  };

  useEffect(() => {
    setSaved(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [week?.[0]?.seconds]);

  // Offers
  const { items, holidayDates, deadlineInfo, mealOptions } = offer || {};

  const isProductOrdered = (product: CombinedOfferItem) => {
    const productId = product.id;
    const isOrdered = Object.values(orders || {}).some(
      (order) => order?.[productId]?.amount > 0
    );
    return isOrdered;
  };

  // Deadline
  const {
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    deadlineSoft,
    defaultDate,
    defaultSoftDate,
  } = deadlineInfo;
  const deadlineFormatted = deadlineFormatter({
    date,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
  });

  const softDeadlineMaxAmount = school?.contract?.softDeadlineMaxAmount || null;

  // Submit standard
  const { submit } = useSubmit({
    path: "submit/flexBulkStandard",
    setData,
    requestConstructor: (standardOrders) => ({
      standard: standardOrders,
      groupName: child.groupName,
      schoolId,
      moduleId,
      childId,
      userId,
    }),
    responseDestructor: (standard) => ({
      standard,
    }),
  });
  const saveStandard = () => {
    submit(standardOrders);
    setSaved(true);
    setStandardView(false);
    message.success(
      hasLanguage
        ? t("translation:message.success.standards-updated")
        : "Standarder opdateret."
    );
  };

  const saveOrder = async () => {
    try {
      message.loading(
        hasLanguage
          ? t("flex-bulk:message.loading.orders-saved")
          : "Bestilling gemmes",
        0
      );

      const promises = week.map(async (date, dayIndex) => {
        const targetOrder = weeklyOrders[dayIndex];
        if (!targetOrder) return;

        const isOrderPastDeadline = isDatePastDeadline({
          date,
          deadline,
          deadlineExcludingWeekends,
          deadlineWeekRelative,
        });

        if (isOrderPastDeadline && !fromAdmin) return;

        await callInternalApi<
          SubmitFlexBulkOrderProps,
          SubmitFlexBulkOrderReturn
        >("submit/flexBulkOrder", {
          schoolId,
          dateSeconds: date.seconds,
          order: targetOrder,
          moduleId,
          groupName: child.groupName,
          adminId: fromAdmin ? adminId : undefined,
        });
      });

      await Promise.all(promises);

      message.destroy();
      message.success(
        hasLanguage
          ? t("flex-bulk:message.success.order-saved")
          : "Bestilling gemt"
      );
      setSaved(true);
    } catch (err) {
      console.error(err);
      message.destroy();
      message.error(
        err?.message || hasLanguage
          ? t("flex-bulk:message.error.order-could-not-saved")
          : "Bestilling kunne ikke gemmes"
      );
    }
  };

  const daysHolidays = week.map((_, dayIndex: DayIndex) => {
    const dateSeconds = week[dayIndex]?.seconds;
    const holidayOrNull = holidayDates?.[dateSeconds];
    return !!holidayOrNull;
  });

  const actualHolidays = week.map((_, dayIndex: DayIndex) => {
    const dateSeconds = week[dayIndex]?.seconds;
    const holidayOrNull = holidayDates?.[dateSeconds];
    return holidayOrNull;
  }) as (Holiday | HolidaysRecurring | null)[];

  const hasStandardOrdering = shouldHaveStandardOrdering({
    modules,
    school,
    moduleId,
  });

  return {
    module,
    moduleId: module.id,
    items,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    defaultDate,
    softDeadline: deadlineSoft,
    defaultSoftDate,
    softDeadlineMaxAmount,
    deadlineFormatted,
    orders,
    orderDocuments,
    setOrders,
    isStandardView,
    daysHolidays,
    isProductOrdered,
    saved,
    saveStandard,
    saveOrder,
    setStandardView,
    mealOptions,
    hasStandardOrdering,
    actualHolidays,
  };
};

export const FlexBulkContext = createContainer(ContextState);

interface OverviewComponentProps {
  availableModules: Array<Module>;
}
interface ModuleDescriptionProps {
  module: Module;
  align?: "left" | "center" | "right";
}
interface NavbarSecondaryProps {
  timeNavigation: "daily" | "weekly" | "none";
  deadlineFormatted: string;
  extraContent: React.ReactChild;
}

interface CanteenClosedProps {
  defaultDate: TimestampType;
  holidayDesc?: {
    title: string;
    text: string;
    image?: string;
  };
  /** Disables jumping link, e.g. in case of Kanpla Go */
  disableJumpLink?: boolean;
}

interface ExtendingComponentsProps {
  Overview?: ({ availableModules }: OverviewComponentProps) => React.ReactChild;
  ModuleDescription?: (props: ModuleDescriptionProps) => React.ReactChild;
  NavbarSecondary?: (props: NavbarSecondaryProps) => React.ReactChild;
  useDeadlineJump?: ({ defaultDate }: { defaultDate: TimestampType }) => void;
  CanteenClosed?: (props: CanteenClosedProps) => JSX.Element;
}

export const FlexBulk = (props: ExtendingComponentsProps) => {
  return (
    <FlexBulkContext.Provider>
      <View {...(props || {})} />
    </FlexBulkContext.Provider>
  );
};

const View = (props: ExtendingComponentsProps) => {
  const { t, i18n } = useTranslation(["flex-bulk", "translation"]);
  const {
    Overview,
    ModuleDescription,
    NavbarSecondary,
    useDeadlineJump = () => null,
    CanteenClosed,
  } = props;

  const {
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    isStandardView,
    saved,
    saveStandard,
    saveOrder,
    module,
    setStandardView,
    orders,
    defaultDate,
    hasStandardOrdering,
    actualHolidays,
  } = useContainer(FlexBulkContext);
  const { innerAppLoading, school, week } = useContainer(OrderingContext);

  // Deadline Jump
  useDeadlineJump({ defaultDate: defaultDate });

  const hasActiveModuleSwitchHoliday =
    actualHolidays.every((h) => h) &&
    actualHolidays.some((h) => (h as Holiday)?._proPluginModuleSwitch);

  const someDaysAreBelow = () => {
    const someAreBelow = week.some((_, dayIndex) => {
      const isBelow = isBelowMinimum({
        school,
        localOrder: orders?.[dayIndex] || {},
      });

      return isBelow;
    });

    return someAreBelow;
  };

  const disabledProp = useMemo(() => {
    const disabled = someDaysAreBelow();

    return disabled ? { disabled } : {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(week), JSON.stringify(orders), school]);

  const hasLanguage = !isEmpty(i18n);

  return (
    <>
      {NavbarSecondary
        ? NavbarSecondary({
            timeNavigation: "weekly",
            deadlineFormatted: deadlineDisplay({
              deadline,
              deadlineWeekRelative,
              deadlineExcludingWeekends,
              locale: i18n.resolvedLanguage,
            }),
            extraContent: !hasActiveModuleSwitchHoliday ? (
              <ButtonSave
                saved={saved}
                onClick={() => (isStandardView ? saveStandard() : saveOrder())}
                {...disabledProp}
                size="large"
                className="w-full md:w-auto"
              />
            ) : null,
          })
        : !hasActiveModuleSwitchHoliday && (
            <ButtonSave
              saved={saved}
              onClick={() => (isStandardView ? saveStandard() : saveOrder())}
              {...disabledProp}
              size="large"
              className="w-full md:w-auto float-right"
            />
          )}

      <div className="wrapper">
        {/* Passing a description of the module */}
        {ModuleDescription
          ? ModuleDescription({ align: "left", module })
          : null}

        <ModuleLoadingWrapper loading={innerAppLoading}>
          {hasActiveModuleSwitchHoliday ? (
            CanteenClosed ? (
              CanteenClosed({
                disableJumpLink: true,
                holidayDesc: actualHolidays[0]?.design,
                defaultDate: Timestamp.now(),
              })
            ) : null
          ) : (
            <>
              {isStandardView && (
                <Space direction="vertical">
                  <Button
                    onClick={() => setStandardView(false)}
                    className="-mt-3 mb-3"
                  >
                    <Space>
                      <FontAwesomeIcon icon={faArrowLeft} />
                      {hasLanguage
                        ? t("flex-bulk:back-to-order")
                        : "Tilbage til bestilling"}
                    </Space>
                  </Button>
                  <p className="text-text-secondary max-w-2xl pb-3">
                    <Trans t={t} i18nKey="flex-bulk:here-you-can">
                      Her kan du
                      <span className="font-semibold">
                        tilføje og redigere din automatiske standard bestilling
                      </span>
                      på vegne af din virksomhed. Vi anbefaler desuden, at du
                      altid gennemgår dine kommende bestillinger efter ændring i
                      standardvalget.
                    </Trans>
                  </p>
                </Space>
              )}

              <Menu />

              {!isStandardView && hasStandardOrdering && (
                <div className="lg:grid grid-cols-2 gap-4 px-3 lg:px-0">
                  <div className="mb-4">
                    <h4 className="h500 pb-2">
                      {hasLanguage
                        ? t("flex-bulk:default-selection")
                        : "Standardvalg"}
                    </h4>
                    <p className="text-sm text-text-primary">
                      {hasLanguage
                        ? t("flex-bulk:set-default-choice")
                        : "Angiv din standardvalg, så behøver du ikke komme ind hver uge og bestille. Det kan altid ændres."}
                    </p>
                    <Button
                      type="primary"
                      className="mt-3"
                      onClick={() => setStandardView(true)}
                    >
                      {hasLanguage
                        ? t("translation:edit-default-selection")
                        : "Rediger standardvalg"}
                    </Button>
                  </div>
                  {/* Sidenav overview for prints and exports */}
                  <div className="mb-4">
                    {typeof Overview !== "undefined" ? (
                      <Overview availableModules={[module]} />
                    ) : null}
                  </div>
                </div>
              )}
            </>
          )}
        </ModuleLoadingWrapper>
      </div>
    </>
  );
};
