import { faCartShopping } from "@fortawesome/pro-duotone-svg-icons";
import { faHouse } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { constructNewUrl } from "@kanpla/system";
import { DrawerOrModal } from "@kanpla/ui";
import { useRouter } from "next/router";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";

const Empty = () => {
  const { mobile, previousModuleId, schoolId } = useContainer(AppContext);
  const { t } = useTranslation(["mealplan2", "translation"]);
  const router = useRouter();

  const [open, setOpen] = useState<boolean>(true);

  return mobile ? (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      actions={[
        {
          type: "primary",
          label: (
            <>
              <FontAwesomeIcon icon={faHouse} className="mr-2" />
              {t("translation:ga-back")}
            </>
          ),
          onClick: () => {
            const newUrl = constructNewUrl(schoolId, previousModuleId);
            router.push(newUrl);
            setOpen(false);
          },
        },
      ]}
    >
      <div className="w-full h-full flex justify-center items-center flex-col px-4">
        <FontAwesomeIcon
          icon={faCartShopping}
          size="2x"
          className="text-primary-main mb-4"
        />
        <h1 className="text-primary-main text-xl">
          {t("mealplan2:registration.no-products")}
        </h1>
        <p className="mt-2 text-center">
          {t("mealplan2:registration.no-products-description")}
        </p>
      </div>
    </DrawerOrModal>
  ) : (
    <div className="w-full h-full flex justify-center items-center flex-col pt-4">
      <FontAwesomeIcon
        icon={faCartShopping}
        size="2x"
        className="text-primary-main mb-4"
      />
      <h1 className="text-primary-main text-xl">
        {t("mealplan2:registration.no-products")}
      </h1>
      <p className="mt-2 text-center">
        {t("mealplan2:registration.no-products-description")}
      </p>
    </div>
  );
};

export default Empty;
