import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  calculateAmountOfOrderItems,
  isBelowMinimum,
  timestampToMoment,
} from "@kanpla/system";
import { Alert, Button, Tooltip } from "antd";
import classnames from "classnames";
import { isEmpty, sortBy } from "lodash";
import moment from "moment";
import { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { FlexBulkContext } from ".";
import { OrderingContext } from "../context";
import GlobalVariant from "./GlobalVariant";
import Product from "./Product";

export const firstColumnStyles = "w-full md:w-5/12 flex-0 pb-3 md:pb-0";

const Menu = () => {
  const { t, i18n } = useTranslation(["translation", "flex-bulk"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);

  const { week, school } = useContainer(OrderingContext);
  const { items, orders, isStandardView, isProductOrdered, daysHolidays } =
    useContainer(FlexBulkContext);

  const { flexBulkMinimum } = school?.contract || {};

  const [showingAllProducts, setShowingAllProducts] = useState(false);

  const hasExtraProducts = items.some((p) => !isProductOrdered(p));

  const checkIsBelow = useCallback(
    (dayIndex: number) => {
      const isBelow = isBelowMinimum({
        school,
        localOrder: orders?.[dayIndex] || {},
      });

      return isBelow;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(orders), school]
  );

  const hasLanguage = !isEmpty(i18n);

  return (
    <>
      {flexBulkMinimum && (
        <Alert
          message={
            hasLanguage ? (
              <Trans t={t} i18nKey="flex-bulk:alert-min-order">
                Minimumsordre for hver dag er
                <b>{{ minProducts: flexBulkMinimum }}</b> produkter, du kan
                enten bestille <b>0</b> eller mindst
                <b>{{ minProducts: flexBulkMinimum }}</b> produkter
              </Trans>
            ) : (
              <p>
                Minimumsordre for hver dag er <b>{flexBulkMinimum}</b>{" "}
                produkter, du kan enten bestille <b>0</b> eller mindst{" "}
                <b>{flexBulkMinimum}</b> produkter
              </p>
            )
          }
          showIcon
          className="w-1/2 my-6 text-sm"
        />
      )}
      <div className={`mb-16 ${!flexBulkMinimum ? "mt-12" : ""}`}>
        {/* Dates */}
        <div className="flex flex-wrap mb-2 px-4">
          <div className={`${firstColumnStyles}`}></div>
          {week.map((date, dayIndex) => {
            const isBelow = checkIsBelow(dayIndex);

            const isHoliday = daysHolidays[dayIndex];

            const classNames = classnames({
              "text-danger-main": isBelow,
              "flex-1 text-sm font-medium text-center": true,
              "flex flex-wrap justify-center": isHoliday,
            });

            return (
              <div key={date.seconds} className={classNames}>
                {isStandardView
                  ? timestampToMoment(date || 0)?.format(
                      `[${hasLanguage ? t("flex-bulk:every") : "hver"}] ddd`
                    )
                  : timestampToMoment(date || 0)?.format("ddd D.M.")}
                {isHoliday ? (
                  <Tooltip
                    title={
                      hasLanguage
                        ? t("flex-bulk:kitchen-closed")
                        : "Køkkenet holder lukket på denne dag."
                    }
                    trigger={["hover", "click"]}
                  >
                    <FontAwesomeIcon
                      icon={faWarning}
                      className="ml-2 text-warning-main cursor-pointer"
                    />
                  </Tooltip>
                ) : null}
              </div>
            );
          })}
        </div>

        {/* Totals */}
        <div className="flex flex-wrap items-center bg-background-secondary px-4 py-2 rounded-t-lg border border-divider-main">
          <div className={`${firstColumnStyles} h300`}>
            {hasLanguage ? t("translation:total") : "Total"}:
          </div>
          {week.map((date, dayIndex) => {
            const total = calculateAmountOfOrderItems(orders?.[dayIndex] || {});

            const isBelow = checkIsBelow(dayIndex);

            const classNames = classnames({
              "text-danger-main": isBelow,
              "flex-1 text-center": true,
            });

            return (
              <div key={date.seconds} className={classNames}>
                {total}
              </div>
            );
          })}
        </div>
        {!items.length && (
          <>
            <div className="loader h-16 rounded-lg mb-2" />
            <div className="loader h-16 rounded-lg mb-2" />
            <div className="loader h-16 rounded-lg mb-2" />
          </>
        )}

        <GlobalVariant />

        {/* Products */}
        {sortBy(items, "name")
          .filter((p) => showingAllProducts || isProductOrdered(p))
          .map((item) => (
            <Product
              item={item}
              firstColumnStyles={firstColumnStyles}
              key={item.id}
            />
          ))}
        {hasExtraProducts && (
          <Button
            className="w-full p-0"
            type="text"
            onClick={() => setShowingAllProducts(!showingAllProducts)}
          >
            <span className="block w-full px-2 py-4 border-x border-b rounded-b-lg text-text-secondary focus:text-text-disabled">
              {showingAllProducts
                ? hasLanguage
                  ? t("flex-bulk:hide-extra-products")
                  : "Skjul extra produkter"
                : hasLanguage
                ? t("flex-bulk:show-all-products")
                : "Vis alle produkter"}
              <FontAwesomeIcon
                icon={showingAllProducts ? faAngleUp : faAngleDown}
                className="ml-1.5"
              />
            </span>
          </Button>
        )}
      </div>
    </>
  );
};

export default Menu;
