import { faForward } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Space } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { useLocalstorageState } from "rooks";

const SkipQueueBanner = () => {
  const { t } = useTranslation(["mealplan2"]);
  const [skipQueueBannerHidden, setSkipQueueBannerHidden] =
    useLocalstorageState("skip-queue-banner-hidden", false);

  const [hideForNow, setHideForNow] = useState(false);

  if (skipQueueBannerHidden || hideForNow) return null;

  return (
    <Portal>
      <div
        className="fixed inset-0 bg-background-secondary p-10 flex flex-col items-center justify-center overflow-y-auto text-text-primary"
        style={{ zIndex: 99999999 }}
      >
        <FontAwesomeIcon icon={faForward} className="mb-8 text-5xl" />
        <h2 className="h800 !font-black uppercase leading-tight text-center">
          {t("mealplan2:skip-queue")}
        </h2>
        <p className="text-center mt-2">{t("mealplan2:just-take-the-food")}</p>

        <Space direction="vertical" align="center" className="w-full">
          <Button
            title={t("mealplan2:show-receipt")}
            onClick={() => setHideForNow(true)}
          />
          <div className="my-4 flex justify-center">
            <Button
              type="primary"
              className="text-center mx-auto"
              onClick={() => setSkipQueueBannerHidden(true)}
            >
              {t("mealplan2:do-not-show-this-again")}
            </Button>
          </div>
        </Space>
      </div>
    </Portal>
  );
};

export default SkipQueueBanner;
