import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import {
  faChevronDown,
  faEye,
  faPen,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrderingContext } from "@kanpla/ordering";
import {
  callInternalApi,
  getDayIndexFromSeconds,
  getWeekArray,
  getWeekSeconds,
  Timestamp,
} from "@kanpla/system";
import { OrderMealplan } from "@kanpla/types";
import { Modal } from "@kanpla/ui";
import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Menu,
  message,
  Popconfirm,
  Space,
} from "antd";
import moment, { Moment } from "moment";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanMeetingContext } from ".";
import { MealplanContext } from "..";
import { AppContext } from "../../contextProvider";
import Receipt from "./Receipt";

interface Props {
  order: OrderMealplan;
}

const OrderOptions = ({ order }: Props) => {
  const { t, i18n } = useTranslation(["mealplan2", "translation"]);
  /**
   * Util to change the localization of moment.js
   * dow and doy explanation: https://github.com/moment/momentjs.com/issues/279
   */
  moment.updateLocale(i18n.language, { week: { dow: 1, doy: 4 } });

  const { setCurrentView, setEditingOrder, setIsConfirming, setCalendarDate } =
    useContext(MealplanMeetingContext);
  const { defaultDate, setNewOrderInfo, handleDateChange } =
    useContainer(MealplanContext);
  const { setWeek, setDayIndex, week, dayIndex } = useContainer(AppContext);
  const { setBasket } = useContainer(OrderingContext);

  const [expanded, setExpanded] = useState<boolean>(false);
  const [receiptOpen, setReceiptOpen] = useState<boolean>(false);
  const [duplicateDate, setDuplicateDate] = useState<Moment>(
    moment.unix(week[dayIndex]?.seconds)
  );
  const [loading, setLoading] = useState(false);
  const [duplicateOpen, setDuplicateOpen] = useState<boolean>(false);

  const orderName =
    order.info?.name ||
    t("mealplan2:order-to-d", {
      value: moment.unix(order.dateSeconds).format("D/M Y"),
    });

  const deleteOrder = async () => {
    // Submit the order
    await callInternalApi("ordering/submitOrder", {
      dateSeconds: order.dateSeconds,
      childId: order.childId,
      order: {},
      moduleId: order.moduleId,
      info: {},
      allowedOrderIds: [order.id],
    });

    message.success(t("mealplan2:message.success.order-deleted"));
  };

  const duplicateOrder = async () => {
    try {
      setLoading(true);

      const orderInfo = {
        ...(order.info || {}),
        name: t("mealplan2:duplicate", { value: orderName }),
      };

      // Validate the order
      const validatedOrder = await callInternalApi("ordering/validateOrder", {
        dateSeconds: duplicateDate.unix(),
        childId: order.childId,
        order: order.order,
        moduleId: order.moduleId,
        info: orderInfo,
      });

      setNewOrderInfo(orderInfo);
      handleDateChange(duplicateDate);
      setCurrentView("ordering");
      setIsConfirming(true);

      setBasket(validatedOrder, undefined, undefined, duplicateDate.unix());
    } catch (err) {
      console.error(err);
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const tooLateToChange = order.dateSeconds < moment().unix();
  const disabledClassName =
    tooLateToChange && "filter grayscale pointer-events-none opacity-50";

  const editingOptions = (
    <Menu onClick={() => setExpanded(false)}>
      <Menu.Item key={0} className="py-2" onClick={() => setReceiptOpen(true)}>
        <Space direction="horizontal" align="center">
          <FontAwesomeIcon icon={faEye} />
          {t("mealplan2:view")}
        </Space>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key={1}
        className={`py-2 ${disabledClassName || ""}`}
        disabled={tooLateToChange}
        onClick={() => {
          setCalendarDate(moment.unix(order?.dateSeconds));

          setCurrentView("ordering");

          const newWeek = getWeekArray(
            getWeekSeconds(order.dateSeconds),
            Timestamp
          );

          const newDayIndex = getDayIndexFromSeconds(order.dateSeconds);

          setWeek(newWeek);

          setDayIndex(newDayIndex);

          setEditingOrder(order);
        }}
      >
        <Space direction="horizontal" align="center">
          <FontAwesomeIcon icon={faPen} />
          {t("translation:edit")}
        </Space>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key={2}
        className="py-2"
        onClick={() => setDuplicateOpen(true)}
      >
        <Space direction="horizontal" align="center">
          <FontAwesomeIcon icon={faCopy} />
          {t("mealplan2:duplicates")}
        </Space>
      </Menu.Item>
      <Menu.Divider />
      <Popconfirm
        title={t("mealplan2:popup.titles.sure-remove-order")}
        onConfirm={deleteOrder}
        placement="rightTop"
      >
        <Menu.Item
          key={3}
          className={`py-2 ${disabledClassName || ""}`}
          danger
          disabled={tooLateToChange}
        >
          <Space direction="horizontal" align="center">
            <FontAwesomeIcon icon={faTrash} />
            {t("translation:delete")}
          </Space>
        </Menu.Item>
      </Popconfirm>
    </Menu>
  );

  return (
    <>
      <Dropdown
        overlay={editingOptions}
        trigger={["click"]}
        onVisibleChange={(visible) => setExpanded(visible)}
      >
        <Button
          className={`ml-3 text-sm text-text-secondary transition-all duration-500`}
          style={{
            transform: expanded ? "rotate(0deg)" : "rotate(-90deg)",
          }}
          shape="circle"
          size="small"
          onClick={() => setExpanded(!expanded)}
        >
          <FontAwesomeIcon icon={faChevronDown} />
        </Button>
      </Dropdown>

      <Modal
        open={duplicateOpen}
        setOpen={setDuplicateOpen}
        title={t("mealplan2:modal.titles.duplicate-order")}
        actions={[
          {
            label: t("translation:confirm"),
            onClick: duplicateOrder,
            className: "primary",
            loading,
          },
        ]}
      >
        <div className="flex justify-center pt-3">
          <Form.Item label={t("mealplan2:form.labels.new-date")}>
            <DatePicker
              value={duplicateDate}
              allowClear={false}
              format={(m) => m.format("ll")}
              onChange={(d) =>
                setDuplicateDate(moment(d).utc(true).startOf("day"))
              }
              disabledDate={(d) =>
                d.get("day") === 0 ||
                d.get("day") === 6 ||
                defaultDate?.seconds > d.unix()
              }
              // style={{ height: "48px" }}
            />
          </Form.Item>
        </div>
      </Modal>
      <Receipt
        open={receiptOpen}
        setOpen={setReceiptOpen}
        order={order.order}
        date={order.date}
        orderInfo={order.info}
      />
    </>
  );
};

export default OrderOptions;
