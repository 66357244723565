import { Button, ButtonProps } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props extends Partial<ButtonProps> {
  text?: string;
  loadingText?: string;
  className?: string;
}

export const ButtonSubmit = (props: Props) => {
  const { t } = useTranslation(["translation"]);

  const {
    text = t("translation:confirm"),
    loadingText = t("translation:loading"),
    loading,
    onClick,
    htmlType = "submit",
    className,
    disabled = false,
  } = props;

  return (
    <Button
      type="primary"
      id="sign-up"
      size="large"
      style={{ animationDuration: "2s" }}
      onClick={onClick}
      htmlType={htmlType}
      className={`w-full mt-5 ${className}`}
      disabled={disabled}
    >
      {loading ? loadingText : text}
    </Button>
  );
};

// export default ButtonSubmit
