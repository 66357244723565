import React from "react";
import { Button } from "antd";

interface Props {
  text: string;
  imageUrl?: string;
  action?: {
    label: string;
    url: string;
  };
  showingToday?: boolean;
}

const PopupPreview = (props: Props) => {
  const { text, imageUrl, action, showingToday = false } = props;

  return (
    <div className="w-full h-full flex flex-col">
      {imageUrl && (
        <img
          src={imageUrl}
          alt={text}
          className="object-cover w-20 h-12 rounded-sm mb-3"
        />
      )}
      <div className="w-full flex items-center justify-between">
        {text}
        {action && (
          <a href={action.url} target="_blank" rel="noreferrer">
            <Button size="small" type={showingToday ? "primary" : "default"}>
              {action.label}
            </Button>
          </a>
        )}
      </div>
    </div>
  );
};

export default PopupPreview;
