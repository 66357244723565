import { SelectProductModal, VariantsModal } from "@kanpla/ui";
import React from "react";
import { useContainer } from "unstated-next";
import { FlexContext } from ".";
import { OrderingContext } from "../context";

const FlexModalsWrapper = () => {
  const {
    deadlineFormatted,
    module,
    selectProductOpen,
    setSelectProductOpen,
    dayProducts,
    isBulk,
    standardProducts,
    dayDateSeconds,
    weekProducts,
    isStandard,
    openVariants,
    setOpenVariants,
    data,
    setData,
    selectedProduct,
    setSelectedProduct,
    mealOptions,
    submit,
    onPurchase,
    selectProductByDay,
    setDayDateSeconds,
    setStandardWeek,
    standardWeek,
  } = useContainer(FlexContext);
  const { week, mobile } = useContainer(OrderingContext);

  const { hidePrices } = module?.config || {};

  return (
    <>
      <SelectProductModal
        open={selectProductOpen}
        setOpen={setSelectProductOpen}
        products={dayProducts}
        isBulk={isBulk}
        selectedProducts={isStandard ? standardProducts : weekProducts}
        date={dayDateSeconds}
        week={week}
        deadlineFormatted={deadlineFormatted}
        isStandard={isStandard}
        mobile={mobile}
        hidePrices={hidePrices}
        setSelectedProduct={setSelectedProduct}
        setOpenVariants={setOpenVariants}
        setDayDateSeconds={setDayDateSeconds}
        selectProductByDay={selectProductByDay}
        onPurchase={onPurchase}
        submit={submit}
        setStandardWeek={setStandardWeek}
        weekProducts={weekProducts}
        setVariants={setData}
        standardWeek={standardWeek}
      />
      <VariantsModal
        open={openVariants}
        setOpen={setOpenVariants}
        product={selectedProduct}
        currentDay={selectedProduct?.dates?.[dayDateSeconds]}
        mealOptions={mealOptions}
        module={module}
        data={data}
        setData={setData}
        onPurchase={onPurchase}
        date={dayDateSeconds}
        selectProductByDay={selectProductByDay}
        setStandardWeek={setStandardWeek}
        fromStandard={isStandard}
      />
    </>
  );
};

export default FlexModalsWrapper;
