import {
  Product,
  OfferProduct,
  Module,
  CombinedOfferItem,
} from "@kanpla/types";

/** !Only used for `flex` modules
 *  Will be soon deprecated (planned for oct 2022)
 */

export const detectProductPrice = (
  product: Product | OfferProduct | CombinedOfferItem,
  module: Module,
  schoolId?: string
) => {
  const productId =
    (product as Product)?.id || (product as OfferProduct)?.productId;

  const productGlobalPrice: number = product?.price || 0;
  const modulePrice: number = module?.products?.[productId]?.price;
  const moduleSchoolPrice: number =
    schoolId && module?.products?.[productId]?.schools?.[schoolId]?.price;

  // PLUGIN: SchoolBasedPrice
  const hasSchoolBasedPrice = module?.plugins?.schoolBasedPrice?.active;
  const schoolDefaultPrice =
    module?.plugins?.schoolBasedPrice?.prices?.[schoolId];

  const basePrice = hasSchoolBasedPrice
    ? schoolDefaultPrice
    : productGlobalPrice;

  return moduleSchoolPrice ?? modulePrice ?? basePrice ?? null;
};
