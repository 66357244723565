import {
  constructNewUrl,
  db,
  deadlineDisplay,
  deadlineFormatter,
  fn,
  getFlexHolidays,
  getFlexOrder,
  getFlexStandard,
  getOrderConfigs,
  groupAllItems,
  groupItemsByDayAndByCategory,
  hasAccessToModule,
  isDatePastDeadline,
  shouldHaveStandardOrdering,
  Timestamp,
} from "@kanpla/system";
import {
  CombinedOfferItem,
  CustomOrderContent,
  FlexBulkStandard,
  FlexStandard,
  Holiday,
  IBaseProducts,
  IGetCurrentProductsProps,
  Module,
  OrderInfo,
  OrderOrder,
  OrderOrderProduct,
  Timestamp as TimestampType,
} from "@kanpla/types";
import { ModuleLoadingWrapper } from "@kanpla/ui";
import { message } from "antd";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createContainer, useContainer } from "unstated-next";
import { OrderingContext } from "../context";
import { createStandardOrder } from "../lib/flex/createStandardOrder";
import { getRanges, HolidayRange } from "../lib/flex/getRanges";
import { getWeekProducts } from "../lib/flex/getWeekProducts";
import { HolidayPeriod } from "../lib/flex/HolidaysModal";
import {
  setBaseProducts,
  SetBaseProductsProps,
} from "../lib/flex/setBaseProducts";
import { setBaseStandardProducts } from "../lib/flex/setBaseStandardProducts";
import FlexLoader from "./FlexLoader";
import FlexModalsWrapper from "./FlexModalsWrapper";
import Menu from "./flexView/Menu";
import StandardHolidays from "./standardHolidays/StandardHolidays";
import StandardSettings from "./standardOrder/StandardSettings";

const ContextState = () => {
  const { t } = useTranslation(["flex", "translation"]);

  const {
    school,
    week,
    child,
    userId,
    schoolId,
    childId,
    date,
    setTimeNavigation,
    module,
    moduleId,
    setIsBulk,
    offer,
    fromAdmin,
    adminId,
    modules,
  } = useContainer(OrderingContext);

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [allowOrder, setAllowOrder] = useState(false);

  const router = useRouter();

  const hasVariantsLimitPlugin = module?.plugins?.variantsLimit?.active;
  const [hasVariantsLimit, setHasVariantsLimit] = useState(false);

  useEffect(() => {
    const ha = hasAccessToModule({
      child,
      module,
      school,
    });

    if (!ha.individual && ha.bulk) {
      const newUrl = constructNewUrl(schoolId, moduleId);
      setIsBulk(true);
      router.replace(newUrl);
    } else if (!ha.individual && !ha.bulk) {
      setIsBulk(false);
      router.replace("/app");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Offers
  const { items, holidayDates, deadlineInfo, mealOptions } = offer || {};

  const groupedItems = groupItemsByDayAndByCategory({
    items: items as CombinedOfferItem[],
    week,
  });

  const activeHoliday = useMemo(() => {
    const holidays = week.map((day) => {
      const targetHoliday = Object.entries(holidayDates).find(
        ([holidaySeconds]) => Number(holidaySeconds) === day.seconds
      )?.[1];

      return targetHoliday;
    });

    return holidays;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(week), JSON.stringify(holidayDates)]);

  const allGroupedItems = groupAllItems({
    items: items as CombinedOfferItem[],
    week,
  });

  // Standard
  const { standard, standardLoading } = getFlexStandard({
    moduleId,
    childId,
    userId,
    schoolId,
    isBulk: false,
    db,
  });

  // Holidays
  const { holidays, holidaysLoading } = getFlexHolidays({
    moduleId,
    childId,
    userId,
    schoolId,
    isBulk: false,
    db,
  });

  // Order
  const { order, orderLoading, orderDocument, orderInfo, weekOrders } =
    getFlexOrder({
      schoolId,
      moduleId,
      childId,
      db,
      date: date || null,
      standard,
      week,
    });

  useEffect(() => {
    const setNoLunchForHolidaysFromTheKitchen = async ({ weekOrders }) => {
      const dates = Object.entries(holidayDates).reduce(
        (acc, [dateSeconds, holiday]) => {
          const isStandard =
            Object.values(weekOrders?.[dateSeconds] || {})?.[0]?.["amount"] > 0;

          const isAlreadyNoLunch =
            isEmpty(weekOrders?.[dateSeconds]?.order) && !isStandard;

          const pastDeadline = isDatePastDeadline({
            date: new Timestamp(Number(dateSeconds), 0),
            deadline,
            deadlineWeekRelative,
            deadlineExcludingWeekends,
          });

          if (isAlreadyNoLunch) return acc;

          if (holiday && !pastDeadline) return [...acc, dateSeconds.toString()];

          return acc;
        },
        []
      );

      const promises = dates.map((date) =>
        submit({}, {}, date.toString(), false)
      );

      await Promise.all(promises);
    };

    if (weekOrders && !isEmpty(weekOrders)) {
      setNoLunchForHolidaysFromTheKitchen({ weekOrders });
    }
  }, [weekOrders]);

  const numberOfItems = Object.values(order).reduce(
    (a: number, c: OrderOrderProduct) => a + c.amount,
    0
  ) as number;
  const hasOrdered = numberOfItems > 0;

  useEffect(() => {
    setTimeNavigation("todaySwitch");
  }, []);

  // Submit order
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(
    () =>
      saving
        ? message.loading(t("flex:message.loading.order-being-saved"), 0)
        : message.destroy(),
    [saving]
  );

  useEffect(() => {
    if (hasVariantsLimitPlugin && order) {
      const orderConfigs = getOrderConfigs(order);
      const variantsLimit = module?.plugins?.variantsLimit?.limit;

      if (orderConfigs.length === variantsLimit) {
        setHasVariantsLimit(true);
      } else {
        setHasVariantsLimit(false);
      }
    }
  }, [order]);

  const submit = async (
    newOrder: OrderOrder,
    info: OrderInfo,
    dateSeconds: string,
    needToSetSaving = true
  ) => {
    if (!schoolId) return;

    const orderData = newOrder;

    try {
      if (needToSetSaving) setSaving(true);

      if (hasVariantsLimitPlugin) {
        const orderConfigs = getOrderConfigs(orderData);
        const variantsLimit = module?.plugins?.variantsLimit?.limit;

        if (orderConfigs.length > (variantsLimit || 0))
          throw new Error(
            t("translation:plural.limitOrder", {
              value: variantsLimit,
              count: variantsLimit,
            })
          );
      }

      const submitOrderToServer = fn.httpsCallable("submitFlexOrder");
      await submitOrderToServer({
        schoolId,
        dateSeconds: Number(dateSeconds),
        order: orderData,
        orderInfo: info,
        moduleId,
        childId,
        adminId: fromAdmin ? adminId : undefined,
      });

      setAllowOrder(false);
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      if (needToSetSaving) setSaving(false);
    }
  };

  // Deadline
  const {
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    deadlineSoft,
    defaultDate,
  } = deadlineInfo || {};
  const pastDate = isDatePastDeadline({
    date,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
  });
  const deadlineFormatted = deadlineFormatter({
    date,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
  });

  const softDeadlineMaxAmount = school?.contract?.softDeadlineMaxAmount || null;

  const showReceipt = !allowOrder;
  const loading = orderLoading || standardLoading;

  /** Default selected product should be "No Lunch" || "Ingen Frokost" */
  const baseProducts = useCallback(
    ({
      setStandard,
      both,
    }: Pick<SetBaseProductsProps, "both" | "setStandard">) => {
      const orders = setBaseProducts({
        setStandard,
        both,
        allGroupedItems,
        weekOrders,
        items,
      });

      return orders;
    },
    [JSON.stringify(weekOrders)]
  );

  const baseStandardProducts = useMemo(() => {
    const products = setBaseStandardProducts({
      allGroupedItems,
      standard,
      items,
    });

    return products;
  }, [JSON.stringify(weekOrders)]);

  /** NEW FLOW FOR INDIVIDUAL FLEX */
  /** Standard products */
  const [standardProducts, setStandardProducts] = useState<IBaseProducts>(null);
  /** Day by day products */
  const [weekProducts, setWeekProducts] = useState<IBaseProducts>(null);

  /** Set the week products every time that the week switch */
  useEffect(() => {
    setWeekProducts(baseProducts({ setStandard: false, both: true }));
    setStandardProducts(baseStandardProducts);
  }, [JSON.stringify(weekOrders)]);

  /** Week standard products to save on db */
  const [standardWeek, setStandardWeek] =
    useState<FlexBulkStandard["standard"]>(standard);

  useEffect(() => {
    setStandardWeek(standard);
  }, [JSON.stringify(standard)]);

  /** Standard products modal */
  const [selectProductOpen, setSelectProductOpen] = useState(false);
  /** Products to display for each day inside the modal */
  const [dayProducts, setDayProducts] =
    useState<{ [key: string]: CombinedOfferItem[] }>(null);
  /** Seconds needed to find the current products */
  const [dayDateSeconds, setDayDateSeconds] = useState("");

  const [selectedProduct, setSelectedProduct] =
    useState<CombinedOfferItem>(null);

  const [isStandard, setIsStandard] = useState(false);

  /** Variants and Infos of the product */
  const [openVariants, setOpenVariants] = useState(false);
  const [data, setData] = useState<CustomOrderContent>(Object);

  /** Find the products to display based on the seconds of the week */
  const getCurrentProducts = ({
    label,
    isStandard = false,
    allGroupedItems,
  }: IGetCurrentProductsProps) => {
    getWeekProducts({
      label,
      isStandard,
      allGroupedItems,
      groupedItems,
      actions: {
        setDayDateSeconds,
        setIsStandard,
        setDayProducts,
        setSelectProductOpen,
      },
    });
  };

  const selectProductByDay = ({
    product,
    dateSeconds,
    noLunch,
  }: {
    dateSeconds: string;
    product?: CombinedOfferItem;
    noLunch?: boolean;
  }) => {
    (isStandard ? setStandardProducts : setWeekProducts)((prevState) => {
      const date = Object.keys(prevState).find((prev) => prev === dateSeconds);
      const productId = noLunch ? "noLunch" : product["id"];

      return {
        ...prevState,
        [date]: {
          id: productId,
          dateSeconds,
        },
      };
    });
  };

  const onPurchase = async (
    product: CombinedOfferItem,
    data: CustomOrderContent,
    date: string
  ) => {
    const newOrder: OrderOrder = createStandardOrder({ product, data });

    const newInfo: OrderInfo = {};

    await submit(newOrder, newInfo, date);
  };

  const submitStandard = async ({ fromAdmin }: { fromAdmin?: boolean }) => {
    try {
      message.loading({
        key: "loading-saving-standard",
        content: t("translation:message.loading.standards-are-saved"),
      });

      // Delete empty lunches before submitting
      delete standardWeek?.[0]?.["no_lunch"];
      delete standardWeek?.[1]?.["no_lunch"];
      delete standardWeek?.[2]?.["no_lunch"];
      delete standardWeek?.[3]?.["no_lunch"];
      delete standardWeek?.[4]?.["no_lunch"];

      const submitToServer = fn.httpsCallable("submitFlexStandard");

      await submitToServer({
        standard: standardWeek,
        schoolId,
        moduleId,
        userId,
        childId,
        fromAdmin,
      });

      message.destroy();
      message.success({
        key: "success-saving-standard",
        content: t("translation:message.success.standards-updated"),
      });
    } catch (err) {
      message.error({
        key: "error-saving-standard",
        content: t("translation:message.error.changes-could-not-be-saved"),
      });
    } finally {
      setSettingsOpen(false);
    }
  };

  const submitStandardHolidays = async (periodsValue: HolidayPeriod) => {
    try {
      message.loading({
        key: "loading-holidays-saving",
        content: t("flex:message.loading.saving-holidays"),
      });

      const submitFlexHolidays = fn.httpsCallable("flex-submitFlexHolidays");

      const allRanges: Array<number> = getRanges(
        periodsValue as HolidayRange[]
      ).flatMap((arr) => arr.ranges);

      const range = [...new Set(allRanges)];

      await submitFlexHolidays({
        holidays: periodsValue,
        range,
        schoolId,
        moduleId,
        userId,
        childId,
      });
      setPeriods(periodsValue);

      message.destroy("loading-holidays-saving");

      message.success({
        key: "success-holidays-saving",
        content: t("flex:message.success.holidays-saved"),
      });
    } catch (error) {
      message.error({
        key: "error-holidays-saving",
        content: t("translation:message.error.changes-could-not-be-saved"),
      });
    }
  };

  // Holidays modal
  const [openStandardHolidays, setOpenStandardHolidays] = useState(false);
  const [periods, setPeriods] = useState<FlexStandard["holidays"]>([
    { fromSeconds: null, toSeconds: null },
  ]);

  useEffect(() => {
    if (!holidays?.length) return;
    setPeriods(holidays);
  }, [holidays]);

  const periodsRanges = useMemo(() => {
    const ranges = getRanges({ periods });

    return ranges;
  }, [periods]);

  const hasStandardOrdering = shouldHaveStandardOrdering({
    modules,
    school,
    moduleId,
  });

  return {
    module,
    items,
    groupedItems,
    moduleId: module.id,

    deadline,
    deadlineWeekRelative,
    defaultDate,
    pastDate,
    softDeadline: deadlineSoft,

    softDeadlineMaxAmount,
    deadlineFormatted,

    standard,
    standardLoading,

    holidays,
    holidaysLoading,

    orderDocument,
    order,
    numberOfItems,
    hasOrdered,
    submit,
    saving,
    setSaving,
    isBulk: false,
    settingsOpen,
    setSettingsOpen,

    hasVariantsLimit,
    setHasVariantsLimit,

    orderInfo,
    showReceipt,
    loading,
    activeHoliday,
    setAllowOrder,
    mealOptions,

    getCurrentProducts,

    standardProducts,
    setStandardProducts,

    selectProductOpen,
    setSelectProductOpen,

    dayProducts,
    setDayProducts,

    dayDateSeconds,
    setDayDateSeconds,

    weekProducts,
    setWeekProducts,

    isStandard,

    openVariants,
    setOpenVariants,

    data,
    setData,

    selectedProduct,
    setSelectedProduct,

    onPurchase,

    selectProductByDay,

    standardWeek,
    setStandardWeek,

    submitStandard,

    deadlineExcludingWeekends,

    allGroupedItems,

    openStandardHolidays,
    setOpenStandardHolidays,

    periods,
    setPeriods,

    periodsRanges,

    submitStandardHolidays,

    holidayDates,

    hasStandardOrdering,
  };
};

export const FlexContext = createContainer(ContextState);

interface ModuleDescriptionProps {
  module: Module;
  align?: "left" | "center" | "right";
}
interface NavbarSecondaryProps {
  timeNavigation: "daily" | "weekly" | "none";
  deadlineFormatted: string;
}

interface CanteenClosedProps {
  defaultDate: TimestampType;
  holidayDesc?: {
    title: string;
    text: string;
    image?: string;
  };
  /** Disables jumping link, e.g. in case of Kanpla Go */
  disableJumpLink?: boolean;
}

interface ExtendingComponentsProps {
  ModuleDescription?: (props: ModuleDescriptionProps) => React.ReactChild;
  NavbarSecondary?: (props: NavbarSecondaryProps) => React.ReactChild;
  useDeadlineJump?: ({ defaultDate }: { defaultDate: TimestampType }) => void;
  CanteenClosed?: (props: CanteenClosedProps) => JSX.Element;
}

export const Flex = (props: ExtendingComponentsProps) => (
  <FlexContext.Provider>
    <View {...props} />
  </FlexContext.Provider>
);

const View = (props: ExtendingComponentsProps) => {
  const {
    loading,
    module,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    defaultDate,
    activeHoliday,
  } = useContainer(FlexContext);
  const { i18n } = useTranslation(["translation"]);
  const { innerAppLoading } = useContainer(OrderingContext);
  const {
    ModuleDescription,
    NavbarSecondary,
    useDeadlineJump = () => null,
    CanteenClosed,
  } = props;

  // Deadline Jump
  useDeadlineJump({ defaultDate: defaultDate });

  const hasActiveModuleSwitchHoliday =
    activeHoliday.every((h) => h) &&
    activeHoliday.some((h) => (h as Holiday)?._proPluginModuleSwitch);

  return (
    <>
      {NavbarSecondary
        ? NavbarSecondary({
            timeNavigation: "weekly",
            deadlineFormatted: deadlineDisplay({
              deadline,
              deadlineWeekRelative,
              deadlineExcludingWeekends,
              locale: i18n.resolvedLanguage,
            }),
          })
        : null}

      <div className="py-3 md:pb-12 wrapper select-none overflow-hidden">
        {ModuleDescription
          ? ModuleDescription({ align: "center", module })
          : null}
        <ModuleLoadingWrapper loading={innerAppLoading}>
          {hasActiveModuleSwitchHoliday ? (
            CanteenClosed ? (
              CanteenClosed({
                disableJumpLink: true,
                holidayDesc: activeHoliday[0]?.design,
                defaultDate: Timestamp.now(),
              })
            ) : null
          ) : (
            <>
              {loading ? <FlexLoader /> : <Menu />}
              <StandardSettings />
              <StandardHolidays />
              <FlexModalsWrapper />
            </>
          )}
        </ModuleLoadingWrapper>
      </div>
    </>
  );
};
