import { Notification, ScheduledNotification } from "@kanpla/types";
import { Divider } from "antd";
import { NormalizedPopup } from "apps/frontend/pages/api/internal/popups/fetchPopupHistory";
import InteractionPreview from "./previews/Interaction.preview";
import Message from "./previews/Message.preview";
import PopupPreview from "./previews/Popup.preview";

interface Props {
  message: NormalizedPopup | (Notification & ScheduledNotification);
  isForToday?: boolean;
  isRecent?: boolean;
}

const MessageLine = (props: Props) => {
  const { message, isForToday, isRecent = false } = props;

  const isPopup = message.type === "popup" || message.type === "banner";
  const isInteraction = message.type === "interaction";

  const interactionPopupOptions = isInteraction && message.interactionOptions;
  const isActive = isInteraction && !interactionPopupOptions?.votedFor;

  const { title, body, type } = message;

  const messageType = (
    type:
      | NormalizedPopup["type"]
      | (Notification & ScheduledNotification)["type"]
  ) => {
    switch (type) {
      case "popup":
        return "popup";
      case "banner":
        return "banner";
      case "interaction":
        return "interaction";
      default:
        return "notification";
    }
  };

  return (
    <>
      <Message
        type={messageType(type)}
        title={title}
        description={
          isPopup ? (
            <PopupPreview
              text={body}
              imageUrl={message.pictureUrl}
              action={message.action}
              showingToday={isForToday}
            />
          ) : isInteraction ? (
            <InteractionPreview
              popup={interactionPopupOptions.popup}
              type={interactionPopupOptions.type}
              votedFor={interactionPopupOptions?.votedFor}
              imageUrl={interactionPopupOptions?.imageUrl}
              closedAt={interactionPopupOptions?.closedAt}
              text={body}
            />
          ) : (
            body
          )
        }
        dateSeconds={message.createdAtSeconds}
        active={
          isRecent || (isInteraction && !interactionPopupOptions?.votedFor)
        }
      />
      <Divider
        style={{
          margin: isActive ? "0px 0px 12px 0px" : "12px 0px",
        }}
      />
    </>
  );
};

export default MessageLine;
