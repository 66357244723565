import {
  displayDayString,
  findHoliday,
  isDatePastDeadline,
  isLastItem,
  Timestamp,
} from "@kanpla/system";
import { GroupedCombinedOffer, IBaseProducts } from "@kanpla/types";
import { MenuViewMobile, ProductsList } from "@kanpla/ui";
import classnames from "classnames";
import { isEmpty } from "lodash";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { FlexContext } from "..";
import { OrderingContext } from "../../context";
import HolidayFeedback from "../standardHolidays/HolidayFeedback";

interface Props {
  selectedProducts?: IBaseProducts;
}

const MenuView = ({ selectedProducts }: Props) => {
  const { t, i18n } = useTranslation(["translation", "flex"]);
  const hasLanguage = !isEmpty(i18n);

  const {
    groupedItems,
    isBulk,
    getCurrentProducts,
    items,
    selectProductOpen,
    setSelectedProduct,
    setOpenVariants,
    setDayDateSeconds,
    selectProductByDay,
    onPurchase,
    submit,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    weekProducts,
    periodsRanges,
    setOpenStandardHolidays,
    activeHoliday,
    setData,
  } = useContainer(FlexContext);
  const { mobile, week, module, schoolId, supplier, fromAdmin } =
    useContainer(OrderingContext);

  const { hidePrices } = module?.config || {};

  const isHolidayFromAdmin = useCallback(
    (dayIndex: number) => {
      const holiday = activeHoliday?.[dayIndex];

      return Boolean(holiday);
    },
    [JSON.stringify(activeHoliday)]
  );

  if (mobile)
    return (
      <MenuViewMobile
        getCurrentProducts={getCurrentProducts}
        selectedProducts={selectedProducts}
        plainProducts={items}
        week={week}
        deadline={deadline}
        deadlineWeekRelative={deadlineWeekRelative}
        deadlineExcludingWeekends={deadlineExcludingWeekends}
        selectProductOpen={selectProductOpen}
        periodsRanges={periodsRanges}
        setOpenStandardHolidays={setOpenStandardHolidays}
        activeHolidays={activeHoliday}
      />
    );

  return (
    <div>
      {Object.entries((groupedItems as GroupedCombinedOffer) || {}).map(
        ([date, categories], index) => {
          const day = displayDayString({
            dateSeconds: date,
            todayString: hasLanguage ? t("translation:today") : "I dag",
          });

          const isLastDay = isLastItem({
            array: Object.keys(groupedItems || {}),
            index,
          });

          const isPast = isDatePastDeadline({
            date: new Timestamp(Number(date), 0),
            deadline,
            deadlineWeekRelative,
            deadlineExcludingWeekends,
          });

          const { isHoliday } = findHoliday({
            periodsRanges,
            dateSeconds: date,
          });

          const isKitchenClosed = isHolidayFromAdmin(index);

          return (
            <div
              key={index}
              className={classnames({
                "": isLastDay,
                "mb-8": !isLastDay,
              })}
            >
              <div
                className={classnames({
                  flex: isHoliday || isKitchenClosed,
                  "gap-x-5": isHoliday,
                  "items-baseline gap-x-3": isKitchenClosed,
                  "mb-2": true,
                })}
              >
                <h3 className="h500">{day}</h3>
                <HolidayFeedback
                  dateSeconds={date}
                  isHolidayFromAdmin={isKitchenClosed}
                />
              </div>

              <ProductsList
                categories={categories}
                isBulk={isBulk}
                date={date}
                selectedProducts={weekProducts}
                weekProducts={weekProducts}
                hidePrices={hidePrices}
                schoolId={schoolId}
                setSelectedProduct={setSelectedProduct}
                setOpenVariants={setOpenVariants}
                setDayDateSeconds={setDayDateSeconds}
                selectProductByDay={selectProductByDay}
                onPurchase={onPurchase}
                submit={submit}
                isPast={isPast && !fromAdmin}
                supplier={supplier}
                isHoliday={isHoliday}
                isHolidayFromAdmin={isKitchenClosed}
                setVariants={setData}
              />
            </div>
          );
        }
      )}
    </div>
  );
};

export default MenuView;
