import { ButtonSubmit } from "@kanpla/ui";
import { Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

const AdhocSettings = ({ child }) => {
  const { t } = useTranslation(["modals", "translation"]);

  const { updateCurrentChild } = useContainer(AppContext);

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const limit = child?.adhocLimit;

  useEffect(() => {
    form.setFieldsValue({ "new-name-input": limit });
  }, [limit]);

  const submit = async (data) => {
    try {
      setLoading(true);
      const adhocLimit =
        data["new-name-input"] === "" ? 0 : parseInt(data["new-name-input"]);
      await updateCurrentChild({ adhocLimit });
      message.success(t("modals:message.success.settings-saved"));
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="h500 mb-2">{t("modals:payment-in-the-canteen")}</div>
      <Form
        onFinish={submit}
        // To catch the submit event on a custom form
        onSubmitCapture={(event) => event.preventDefault()}
        requiredMark={false}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label={t("modals:form.labels.limit-payment")}
          htmlFor="new-name-input"
          name="new-name-input"
          help={
            <p className="text-sm text-text-secondary opacity-70 mt-1 mb-2">
              {t("modals:form.notes.standard-limit")}
            </p>
          }
        >
          <Input type="number" placeholder="200" min={0} />
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <ButtonSubmit
              loading={loading}
              text={t("modals:form.buttons.set-limit")}
              loadingText={t("translation:loading")}
            />
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default AdhocSettings;
