import { priceFormatter } from "@kanpla/system";
import { Module } from "@kanpla/types";
import { TooltipHiddenPrices } from "@kanpla/ui";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  /** Payment method */
  paymentMethod?: string;
  /** Total of the order */
  orderTotal?: number;
  /** Taxes */
  taxTotal?: number;
  module?: Pick<Module, "paymentMethod" | "displayName" | "name" | "config">;
}

const TotalOrder = ({ paymentMethod, orderTotal, taxTotal, module }: Props) => {
  const { t, i18n } = useTranslation(["translation", "modals"]);

  const modifiedOrderTotal =
    paymentMethod === "billing" ? orderTotal : orderTotal - taxTotal;

  const { hidePrices } = module?.config || {};

  if (!paymentMethod) return <div />;

  return (
    <div className="text-text-secondary">
      <div>
        <span>{t("translation:excl-taxes")}:</span>{" "}
        <span className="font-semibold">
          {priceFormatter(
            modifiedOrderTotal,
            { language: i18n.language },
            false,
            hidePrices,
            { wrapper: () => "-", component: () => <TooltipHiddenPrices /> }
          )}
        </span>
      </div>
      <div>
        <span className="text-sm">{t("translation:VAT")}:</span>{" "}
        <span className="font-semibold">
          {priceFormatter(
            taxTotal,
            { language: i18n.language },
            false,
            hidePrices,
            { wrapper: () => "-", component: () => <TooltipHiddenPrices /> }
          )}
        </span>
      </div>
      <div className="flex justify-end">
        <Divider className="my-1 w-2/4 min-w-0" />
      </div>
      <div className="text-primary-main font-semibold">
        {t("translation:total")}:{" "}
        {priceFormatter(
          modifiedOrderTotal + taxTotal,
          {
            language: i18n.language,
          },
          false,
          hidePrices,
          {
            wrapper: () => <span className="text-primary-main">-</span>,
            component: () => <TooltipHiddenPrices color="text-primary-main" />,
          }
        )}
      </div>
    </div>
  );
};

export default TotalOrder;
