import { useTranslation } from "react-i18next";
import ExportOrders from "./ExportOrders";

const Export = () => {
  const { t } = useTranslation(["settings"]);

  return (
    <div>
      <h2 className="h500 border-b-2 border-divider-main pb-2">
        {t("settings:export")}
      </h2>

      <ExportOrders />
    </div>
  );
};

export default Export;
