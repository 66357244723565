import { useFetch } from "@kanpla/system";
import { Popup } from "@kanpla/types";
import { FetchPopupProps } from "apps/frontend/pages/api/internal/popups/fetchPopup";
import { useLocalstorageState } from "rooks";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import Banner from "./Banner";
import Interaction from "./Interaction";
import Modal from "./Modal";

const Provider = () => {
  const { child, school } = useContainer(AppContext);
  const [savedPopup, setSavedPopup] = useLocalstorageState<Popup>(
    "saved-popup",
    null
  );

  useFetch<FetchPopupProps, Popup>(
    "popups/fetchPopup",
    {
      childId: child?.id,
      schoolId: child?.schoolId,
    },
    {
      refreshInterval: 1000 * 60 * 5,
      onSuccess: (data) => setSavedPopup(data),
    }
  );

  if (!child || !school) return null;

  if (!savedPopup) return null;

  if (savedPopup.type === "banner")
    return <Banner popup={savedPopup} closePopup={() => setSavedPopup(null)} />;

  if (savedPopup.type === "modal")
    return <Modal popup={savedPopup} closePopup={() => setSavedPopup(null)} />;

  if (savedPopup.type === "interaction")
    return (
      <Interaction popup={savedPopup} closePopup={() => setSavedPopup(null)} />
    );

  return null;
};

export default Provider;
