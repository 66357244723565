import { priceFormatter } from "@kanpla/system";
import { Module, SubscriptionProduct } from "@kanpla/types";
import { TooltipHiddenPrices } from "@kanpla/ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  subsequentProducts: SubscriptionProduct[];
  nextStart: string | number;
  module: Module;
}

const ChooseProduct = ({
  subsequentProducts = [],
  nextStart,
  module,
}: Props) => {
  const { t, i18n } = useTranslation(["subscription"]);

  const [selected, setSelected] = useState({});

  const { hidePrices } = module?.config || {};

  const set = (value: SubscriptionProduct) => {
    setSelected(value);
  };

  return (
    <div>
      {nextStart ? (
        <p className="text-text-secondary text-sm text-center -mt-1 mb-2">
          {t("subscription:change-effective-on", { value: nextStart })}
        </p>
      ) : null}
      {subsequentProducts.length === 0 && (
        <div className="text-center text-danger-main mt-10 mb-4">
          Ingen produkter at vælge. Vent venligst indtil køkkenet bekræfter
          produkterne til næste periode.
        </div>
      )}
      {subsequentProducts.map((product) => (
        <label
          className={`block py-2 px-3 my-1 rounded cursor-pointer ${
            selected["id"] === product.id && "bg-info-light"
          }`}
          key={product.name}
        >
          <input
            type="radio"
            className="form-radio"
            name={product.name}
            value={product.id}
            checked={selected["id"] === product.id}
            onChange={() => set(product)}
          />
          <span className="ml-2">
            {product.name}

            <span className="ml-3 text-text-secondary">
              {priceFormatter(
                product.price - product.subsidies,
                { language: i18n.language },
                false,
                hidePrices,
                { wrapper: () => "-", component: () => <TooltipHiddenPrices /> }
              )}
            </span>
          </span>
        </label>
      ))}
    </div>
  );
};

export default ChooseProduct;
