import { DrawerOrModal } from "@kanpla/ui";
import { Button } from "antd";
import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useBoundingclientrectRef } from "rooks";
import BannerBackgroundImage from "./BannerBackgroundImage";
import { getProportionalFontSize, getProportionalHeightPadding } from "./utils";

interface Props {
  title: string;
  subTitle: string;
  imageUrl: string;
  topTitle: string;
  overlayOpacity?: number;
  defaultExpanded?: boolean;
}

const BannerFour = (props: Props) => {
  const {
    title,
    imageUrl,
    topTitle,
    subTitle,
    overlayOpacity,
    defaultExpanded,
  } = props;
  const [bannerRef, boundingClientRect] = useBoundingclientrectRef();
  const [descriptionRef, descriptionRect] = useBoundingclientrectRef();
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation(["translation"]);

  if (!title && !imageUrl) return null;

  const width = boundingClientRect?.width || 0;
  const descHeight = descriptionRect?.height || 0;
  const expandDescription = !defaultExpanded && descHeight > 90;

  return (
    <div
      ref={bannerRef}
      className={`w-full h-full rounded-lg overflow-hidden relative shadow-lg`}
    >
      <BannerBackgroundImage src={imageUrl} alt={title} size={{ w: width }} />
      <div
        className={`absolute h-full inset-0 bg-gradient-to-t from-black opacity-60 ${getProportionalHeightPadding(
          width,
          "bottom",
          true
        )}`}
        style={{ opacity: overlayOpacity / 140 }}
      />
      <div className="flex flex-col h-full relative inset-0 p-4 justify-end text-white">
        {topTitle && (
          <p
            className={`text-left text-white ${getProportionalFontSize(
              width,
              "text-xs"
            )}`}
          >
            {topTitle}
          </p>
        )}
        {title && (
          <h3
            className={`h600 text-white text-left ${getProportionalFontSize(
              width,
              "text-xl"
            )}`}
          >
            {title}
          </h3>
        )}
        {subTitle && (
          <>
            <p
              className={classNames(
                "text-white text-left overflow-hidden leading-tight",
                getProportionalFontSize(width),
                !defaultExpanded && "max-h-24"
              )}
            >
              <span
                ref={descriptionRef}
                dangerouslySetInnerHTML={{ __html: subTitle }}
              />
            </p>
            {expandDescription && (
              <>
                <Button
                  type="link"
                  className="px-0 text-left hover:opacity-60 transition-opacity"
                  onClick={() => setOpenModal(true)}
                >
                  <span className="hover:opacity-60 transition-opacity">
                    {t("translation:read-more")}
                  </span>
                </Button>
                <DrawerOrModal
                  open={openModal}
                  setOpen={setOpenModal}
                  noPadding
                >
                  <BannerFour {...props} defaultExpanded />
                </DrawerOrModal>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default BannerFour;
