import { useTranslation } from "react-i18next";
import Link from "next/link";
import { useContainer } from "unstated-next";
import useSubscriptionPeriods from "../lib/useSubscriptionPeriods";
import { AppContext } from "./contextProvider";
import { constructNewUrl } from "@kanpla/system";

interface Props {
  noBorder?: boolean;
}

const SubscriptionBanner = ({ noBorder }: Props) => {
  const { modules } = useContainer(AppContext);
  const subscriptionModuleId = modules.find(
    (m) => m.type === "subscription"
  )?.id;

  const periods = useSubscriptionPeriods(subscriptionModuleId);
  let subscriptionTypes = Object.keys(periods);

  if (subscriptionTypes.length === 0) return null;

  return (
    <div
      className={`max-w-screen-xl mx-auto grid md:grid-cols-2 lg:grid-cols-3 gap-4 pt-8 ${
        !noBorder ? "border-t mt-3 border-divider-main" : "my-8"
      }`}
    >
      {subscriptionTypes.map((type) => {
        if (type === "mælk")
          return (
            <Banner
              name={type}
              image="/images/subscription/milk.png"
              key={type}
            />
          );
        if (type === "mad")
          return (
            <Banner
              name={type}
              image="/images/subscription/food.png"
              key={type}
            />
          );
        if (type === "frugt")
          return (
            <Banner
              name={type}
              image="/images/subscription/fruit.png"
              key={type}
            />
          );
        return null;
      })}
    </div>
  );
};

const Banner = ({ image, name }) => {
  const { t } = useTranslation(["components"]);
  const { modules, schoolId } = useContainer(AppContext);
  const subscriptionModule = modules.find((m) => m.type === "subscription");
  if (!subscriptionModule) return null;

  const redirectToUrl = constructNewUrl(schoolId, subscriptionModule.id);

  return (
    <Link href={redirectToUrl}>
      <a className="block">
        <img
          src={image}
          alt={t("components:order-by-subscription", { value: name })}
        />
      </a>
    </Link>
  );
};

export default SubscriptionBanner;
