import React from "react";
import { useBoundingclientrectRef } from "rooks";
import BannerBackgroundImage from "./BannerBackgroundImage";
import { getProportionalFontSize, getProportionalHeightPadding } from "./utils";

interface Props {
  title: string;
  imageUrl: string;
  overlayOpacity?: number;
}

const BannerOne = ({ title, imageUrl, overlayOpacity }: Props) => {
  const [bannerRef, boundingClientRect] = useBoundingclientrectRef();
  if (!title && !imageUrl) return null;
  const width = boundingClientRect?.width || 0;

  return (
    <div
      ref={bannerRef}
      className={`w-full h-full rounded-lg overflow-hidden relative shadow-lg bg-gray-900`}
      style={{ minHeight: 128 }}
    >
      <BannerBackgroundImage
        src={imageUrl}
        alt={title}
        opacity={1 - overlayOpacity / 140}
        size={{ w: width }}
      />
      <div
        className="flex items-center justify-center relative inset-0 h-full text-white"
        style={{ minHeight: 128 }}
      >
        {title && (
          <h3
            className={`h600 ${getProportionalFontSize(
              width,
              "text-xl"
            )} text-white text-center p-4 ${getProportionalHeightPadding(
              width,
              "center"
            )} `}
          >
            {title}
          </h3>
        )}
      </div>
    </div>
  );
};

export default BannerOne;
