import { OrderingContext } from "@kanpla/ordering";
import { calculateAmountOfOrderItems } from "@kanpla/system";
import { Button } from "antd";
import { isEmpty, omit } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanContext } from "..";
import { AppContext } from "../../contextProvider";
import Receipt from "../Receipt";
import RegisteringModal from "./RegisteringModal";

const Registering = () => {
  const { order, items, receiptOpen } = useContainer(MealplanContext);
  const { module } = useContainer(AppContext);
  const { setBasket, basket, basketContainerUtils } =
    useContainer(OrderingContext);

  const { t } = useTranslation(["mealplan2", "translation"]);

  const [showReceipt, setShowReceipt] = useState(false);
  const [open, setOpen] = useState(true);
  const [openAddMore, setOpenAddMore] = useState(false);

  const hasOrder = calculateAmountOfOrderItems(order);

  useEffect(() => {
    if (hasOrder || receiptOpen) {
      setOpenAddMore(false);
      setShowReceipt(true);
    }
  }, [hasOrder, receiptOpen]);

  const onTicketSelected = (ticketId: string, remove: boolean) => {
    const ticket = items.find((i) => i.id === ticketId);

    if (remove) {
      const newBasket = omit(basket, ticket.id);
      if (isEmpty(newBasket)) basketContainerUtils.reset();

      setBasket(newBasket);
      return;
    }

    setBasket({
      ...basket,
      [ticket.id]: {
        amount: 1,
        productLineId: ticket.productLineId,
        name: ticket.name,
        price: ticket.price,
        config: [],
      },
    });
  };

  if (showReceipt)
    return (
      <>
        <div className="max-w-md md:border rounded-lg mx-4 md:mx-auto md:mt-4">
          <div className="md:px-14">
            <Receipt />
          </div>
          {module?.hasMultipleOrdersPerDay ? (
            <div className="flex flex-col">
              <p className="text-text-disabled mt-4 text-center px-4">
                {t("mealplan2:registration.add-ticket-description")}
              </p>
              <Button
                onClick={() => setOpenAddMore(true)}
                className="w-100 m-4"
              >
                {t("mealplan2:registration.add-ticket")}
              </Button>
            </div>
          ) : (
            <p className="text-text-disabled mt-4 text-center p-4">
              {t("mealplan2:registration.already-ordered")} 😊
            </p>
          )}
        </div>
        {module?.hasMultipleOrdersPerDay && (
          <RegisteringModal
            open={openAddMore}
            setOpen={setOpenAddMore}
            onTicketSelected={onTicketSelected}
          />
        )}
      </>
    );

  return (
    <RegisteringModal
      open={open}
      setOpen={setOpen}
      onTicketSelected={onTicketSelected}
    />
  );
};

export default Registering;
