import { constructNewUrl } from "@kanpla/system";
import { Homescreen } from "@kanpla/types";
import { Button as AntdButton } from "antd";
import { redirectToModule } from "apps/frontend/lib/router";
import { useRouter } from "next/router";
import React from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";

const Button = ({ singleBlock }: { singleBlock: Homescreen.ContentInner }) => {
  const router = useRouter();

  const { modules, schoolId } = useContainer(AppContext);

  if (!singleBlock || !singleBlock.props) return <span></span>;

  const { title, url } = singleBlock.props;

  if (!title || !url) return <span></span>;

  const moduleUrlID = url?.split("/m/")?.[1]?.split("/")?.[0];

  const redirectToUrl = constructNewUrl(schoolId, moduleUrlID);

  const moduleIsIncluded = modules.map((m) => m.id).includes(moduleUrlID);

  return (
    <AntdButton
      onClick={() =>
        redirectToModule({
          router,
          link: moduleIsIncluded ? redirectToUrl : url,
        })
      }
      size="small"
      type="link"
      className="my-2 xxs:my-0 px-0 whitespace-pre-line"
      style={{ maxWidth: "15ch" }}
    >
      {title}
    </AntdButton>
  );
};

export default Button;
