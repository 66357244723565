import {
  calculateOrderTotal,
  callInternalApi,
  checkIfChildHasCreditEnough,
  db,
  KanplaError,
} from "@kanpla/system";
import {
  OrderInfo,
  OrderOrder,
  OrderOrderProduct,
  PaymentProvider,
  Plugins,
} from "@kanpla/types";
import { message } from "antd";
import { cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanContext } from ".";
import { AppContext } from "../contextProvider";

interface SubmitOrderProps {
  updater: (oldOrder: OrderOrder) => OrderOrder;
  infoUpdater?: (oldFields: OrderInfo) => OrderInfo;
  orderFromParams?: OrderOrder;
  paymentProvider?: PaymentProvider;
}

const useSubmit = () => {
  const { t } = useTranslation(["translation"]);
  const {
    setPleasePayOpen,
    setBalance,
    balance,
    setIsSaving,
    setDeficit,
    week,
    dayIndex,
    childId,
    moduleId,
    module,
    user,
    userId,
    getPosoneBalance,
    auth,
    child,
  } = useContainer(AppContext);
  const { requiresCredit, order, orderInfo, ignoredOrderIds, allowedOrderIds } =
    useContainer(MealplanContext);

  const plugins = module?.plugins || ({} as Plugins.Array);

  /** Performs an update on OrderOrder and submits it to the server */
  const submitOrder = async (props: SubmitOrderProps) => {
    const {
      updater,
      infoUpdater = (info) => info || {},
      orderFromParams = null,
      paymentProvider = null,
    } = props;

    const chooseOrder = orderFromParams ? orderFromParams : order;
    const newOrder = updater(cloneDeep(chooseOrder));
    // console.log(
    //   "🚀 ~ file: useSubmit.ts ~ line 58 ~ useSubmit ~ newOrder",
    //   newOrder
    // );
    const newInfo = infoUpdater(cloneDeep(orderInfo));

    // Calculate the cost of the order
    const oldCost = calculateOrderTotal(order);
    const newCost = calculateOrderTotal(newOrder);
    const cost = newCost - oldCost;

    try {
      let hasEnough: any = false;
      if (requiresCredit) {
        // Reject if not enough credit

        checkIfChildHasCreditEnough(
          cost,
          balance,
          (price) => setDeficit(price),
          (open) => {
            setPleasePayOpen(open);
            if (open) {
              setIsSaving((s) => s - 1);
              throw new KanplaError(
                "kanpla/not-enough-credit",
                t("translation:not-enough-credit")
              );
            }
          }
        );
      }

      // Signup offer (plugin)
      const hasSignupOfferProduct =
        plugins?.signupOffer?.active &&
        Object.keys(newOrder).includes(plugins?.signupOffer?.productId);

      const signupOfferExpired =
        !hasSignupOfferProduct || child?.takenSignupOffer === true;

      if (hasSignupOfferProduct && !signupOfferExpired) {
        newOrder[plugins.signupOffer.productId] = {
          ...(newOrder[plugins.signupOffer.productId] ||
            ({} as OrderOrderProduct)),
          amount: 1,
        };
      }

      if (hasSignupOfferProduct) {
        await db
          .collection("users")
          .doc(userId)
          .update({
            pluginData: {
              ...(user.pluginData || {}),
              signupOffer: { taken: true },
            },
          });
      }

      // Start saving
      setIsSaving((s: number) => s + 1);

      // Submit the order
      await callInternalApi("ordering/submitOrder", {
        dateSeconds: week[dayIndex]?.seconds,
        childId,
        order: newOrder,
        moduleId,
        info: newInfo,
        ignoredOrderIds,
        allowedOrderIds,
        paymentProvider,
        ...(auth?.user?.uid !== userId ? { adminId: auth?.user?.uid } : {}),
      });

      if (requiresCredit) setBalance((oldBalance: number) => oldBalance - cost);
      if (module.paymentMethod === "posOne") {
        // Check frequently posone balance
        setTimeout(() => getPosoneBalance(), 1000);
        setTimeout(() => getPosoneBalance(), 3000);
        setTimeout(() => getPosoneBalance(), 5000);
        setTimeout(() => getPosoneBalance(), 10000);
      }

      // Analytics event: purchase
      // fb.analytics().logEvent('purchase', {
      //   currency: 'DKK',
      //   value: newOrder.isAdding ? price : -price,
      //   items: [
      //     {
      //       id: newOrder.productId,
      //       price: price,
      //       name: orderProduct.name,
      //       category: orderProduct.category,
      //       quantity: newOrder.isAdding ? 1 : -1,
      //       variant: newOrder.variant,
      //     },
      //   ],
      // })

      // Update UI
      setIsSaving((s: number) => {
        return s - 1;
      });

      return true;
    } catch (error) {
      !error.message.includes(t("translation:not-enough-credit")) &&
        message.error(error.message);

      // Remove one saving process in case of error
      setIsSaving((s) => {
        return 0;
      });
      throw new KanplaError(
        error?.details?.kanplaCode || error?.code,
        error?.message
      );
    }
  };

  return submitOrder;
};

export default useSubmit;
