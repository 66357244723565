import { faCircleCheck } from "@fortawesome/pro-duotone-svg-icons";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { priceFormatter } from "@kanpla/system";
import { Module, OrderInfo, OrderMealplan, Plugins } from "@kanpla/types";
import { isEmpty } from "lodash";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, useSpring } from "react-spring";
import { TextInputDisplay, TimeInputDisplay, TooltipHiddenPrices } from "..";

interface Props {
  children: ReactNode; // React children (element)
  mobile?: boolean;
  bottomActions?: Array<ReactNode | JSX.Element>;
  wrapperChildren?: ReactNode; // React children (element)
  pulseCounter?: number; // used to trigger receipt pulse animaiton
  center?: boolean;
  className?: string;
  isSaving?: boolean;
  withPadding?: boolean;
  svgColor?: string;
  fullWidth?: boolean;
  ordersAmount?: number;
  orderDocument?: OrderMealplan;
  orderNumber?: number;
  receiptConfig?: {
    title?: string | JSX.Element;
    subtitle?: string;
  };
  /** If you need to trigger initial open of the receipt */
  initialOpen?: boolean;
}

export const ReceiptWrapper = (props: Props) => {
  const {
    children,
    center = false,
    className,
    fullWidth,
    mobile = true,
    receiptConfig,
    initialOpen,
  } = props;

  const { title: receiptTitle = "", subtitle: receiptSubtitle = "" } =
    receiptConfig || {};

  const [openReceipt, setOpenReceipt] = useState(initialOpen || !mobile);

  // Animation

  const ref = useRef(null);
  const [receiptAnimationProps, setReceiptAnimationProps] = useSpring(
    () => ({ height: "0px" }),
    []
  );

  /** If the edit mode is true we need to add some height to show the bottom actions */
  useEffect(() => {
    setReceiptAnimationProps({
      height: `${openReceipt ? ref.current.offsetHeight : 0}px`,
    });
  }, [children]);

  useEffect(() => {
    setReceiptAnimationProps({
      height: `${openReceipt ? ref.current.offsetHeight : 0}px`,
    });
  }, [openReceipt]);

  return (
    <div className="-mx-2 md:-mx-6">
      <div
        className={`${
          center ? "md:mx-auto" : "md:pl-8 lg:pl-12 xl:pl-32 md:pt-0"
        } ${
          fullWidth
            ? "w-full sm:w-3/4 sm:float-right md:w-full md:float-none"
            : "w-full md:w-1/2 lg:w-3/7 xl:w-1/2"
        }  ${
          className || ""
        } mb-3 relative rounded-lg overflow-hidden max-w-[500px]`}
        style={{ zIndex: 11 }}
      >
        <div
          onClick={() => setOpenReceipt(!openReceipt)}
          className={`${
            openReceipt
              ? "rounded-tl-lg rounded-tr-lg shadow-lg"
              : "rounded-lg delay-300"
          } transition-all bg-primary-main py-3 px-6 flex flex-row justify-between items-center cursor-pointer hover:bg-primary-dark text-primary-contrast`}
        >
          <div>
            <div className="flex flex-row items-center gap-x-2 opacity-80">
              <FontAwesomeIcon icon={faCircleCheck} />{" "}
              <span className="uppercase text-xs">{receiptSubtitle}</span>
            </div>
            <h3 className="h500">{receiptTitle}</h3>
          </div>
          <div className="text-2xl">
            <FontAwesomeIcon
              icon={faChevronRight}
              className={`${openReceipt ? "rotate-90" : ""} transition-all`}
            />
          </div>
        </div>
        <animated.div style={{ ...receiptAnimationProps }}>
          <div ref={ref}>
            <div>{children}</div>
          </div>
        </animated.div>
      </div>
    </div>
  );
};

interface InfoDisplayProps {
  orderInfo: OrderInfo;
  module: Module;
}

ReceiptWrapper.InfoDisplay = ({ orderInfo, module }: InfoDisplayProps) => {
  const { t, i18n } = useTranslation(["translation"]);

  return (
    <div className="text-xs text-text-primary">
      {module?.plugins?.multipleOrders?.active && (
        <div>
          {isEmpty(i18n) ? "Navn" : t("translation:name")}: {orderInfo?.name}
        </div>
      )}
      {module?.plugins?.timeInput?.active && (
        <TimeInputDisplay timeInput={orderInfo?.timeInput} />
      )}
      {module?.plugins?.invoiceReference?.active &&
        orderInfo?.reference &&
        orderInfo?.reference !== "" && (
          <div>Reference: {orderInfo?.reference}</div>
        )}
      <TextInputDisplay
        textInput={orderInfo?.textInput}
        plugin={module?.plugins?.textInput as Plugins.TextInput}
      />
    </div>
  );
};

interface FooterProps {
  total?: number;
  children?: ReactNode;
  /** @deprecated */
  wasPaidWithMobilePay?: boolean;
  module?: Module;
}

ReceiptWrapper.Footer = (props: FooterProps) => {
  const { total = null, children = null, module } = props;

  const { t, i18n } = useTranslation(["libs"]);

  const { hidePrices } = module?.config || {};

  return (
    <div className="bg-background-primary pb-6 border-divider-main rounded-t w-full relative">
      <div className=" flex justify-between text-xs text-text-primary">
        <div className="pt-4">{children}</div>
        {total !== null ? (
          <div className="flex flex-col items-end">
            <div className="ml-auto pt-4">
              {isEmpty(i18n) ? (
                `${total} kr. i alt`
              ) : (
                <>
                  {priceFormatter(
                    total,
                    { language: i18n.language },
                    false,
                    hidePrices,
                    {
                      wrapper: () => "-",
                      component: () => <TooltipHiddenPrices />,
                    }
                  )}{" "}
                  {hidePrices ? "" : t("libs:kr-total")}
                </>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

interface BottomActionsProps {
  bottomActions: Array<ReactNode | JSX.Element>;
}

ReceiptWrapper.BottomActions = ({ bottomActions }: BottomActionsProps) => {
  return (
    <div className="border-x-2 border-b-2 rounded-bl-lg rounded-br-lg overflow-hidden">
      <div className="flex flex-row flex-wrap justify-evenly pt-2 pb-5">
        {bottomActions.map((action) => {
          return action;
        })}
      </div>
    </div>
  );
};
