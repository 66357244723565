import { Popper as PopperMui, Fade, ClickAwayListener } from "@mui/material";
import React, { useState, ReactElement } from "react";
import classnames from "classnames";
import { Lock } from "@kanpla/ui";

interface Props {
  /** The element that triggers the display of the Popper */
  actionElement: ReactElement;
  /** Any element that will be wrapper by the Popper */
  children: ReactElement;
  /** Additional class names on the wrapper of the Popper */
  className?: string;
  /** Adds padding to the `Popper` */
  withPadding?: boolean;
  /** Sets a min width on the `Popper` */
  minWidth?: number;
  /** Sets a max width on the `Popper` */
  maxWidth?: number;
  /** Sets a fixed with on the `Popper` */
  width?: number;
  /** Set the zIndex of the `Popper` */
  zIndex?: string;
  /** Locks the body scroll if the `Popper` is open */
  lockOnOpen?: boolean;
}

/**
 * Wraps a `Popper` provided by `@mui/material`
 * Creates a "dropdown" triggered by any element
 * @param props
 * @returns A `Popper` element
 */
export const Popper = (props: Props) => {
  const {
    actionElement,
    children,
    className,
    withPadding = true,
    minWidth = 0,
    maxWidth = null,
    width = null,
    zIndex = null,
    lockOnOpen = false,
  } = props;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOnClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleOnClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleOnClose}>
      <div>
        <div className="w-fit h-auto cursor-pointer" onClick={handleOnClick}>
          {actionElement}
        </div>
        {open && (
          <PopperMui
            anchorEl={anchorEl}
            open={open}
            placement="bottom-end"
            className={classnames({ "z-max": !zIndex }, zIndex)}
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <div
                  className={classnames(
                    "rounded-lg shadow-xl border border-divider-main bg-background-primary",
                    className,
                    { "p-4": withPadding }
                  )}
                  style={{
                    minWidth: `${minWidth}px`,
                    maxWidth: maxWidth && `${maxWidth}px`,
                    width: width && `${width}px!important`,
                  }}
                >
                  {children}
                </div>
              </Fade>
            )}
          </PopperMui>
        )}
        {lockOnOpen && open && <Lock />}
      </div>
    </ClickAwayListener>
  );
};
