import { OrderingContext } from "@kanpla/ordering";
import { editConfigAmount } from "@kanpla/system";
import { Modal } from "@kanpla/ui";
import { InputNumber } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanMeetingContext } from ".";

const OrderForAmountOfPeople = () => {
  const { t } = useTranslation(["mealplan2", "translation"]);
  const { editingOrder, setEditingOrder, currentView } = useContext(
    MealplanMeetingContext
  );
  const { basket, setBasket } = useContainer(OrderingContext);
  const [popupOpen, setPopupOpen] = useState(false);

  const numberOfPeople = editingOrder?.info?.numberOfPeople;
  useEffect(() => {
    if (typeof numberOfPeople !== `number`) {
      if (currentView === "ordering") setPopupOpen(true);
      return;
    }

    let newBasket = basket || {};

    const allConfigs = Object.entries(newBasket)
      .map(([productId, orderProduct]) => {
        const allSubConfigs =
          orderProduct.config?.map((c) => ({
            productId,
            choice: c.options,
          })) || [];

        return allSubConfigs;
      })
      .flat();

    for (const conf of allConfigs) {
      const modifiedOrder = editConfigAmount({
        ...conf,
        order: newBasket,
        replaceAmount: true,
        amountChange: numberOfPeople,
      });

      newBasket = modifiedOrder;
    }
    setBasket(newBasket);
  }, [numberOfPeople, currentView]);

  return (
    <Modal
      open={popupOpen}
      setOpen={setPopupOpen}
      title={t("mealplan2:enter-number-people")}
      actions={[
        {
          label: t("translation:loading"),
          onClick: () => setPopupOpen(false),
        },
      ]}
    >
      <InputNumber
        pattern="[0-9]*"
        inputMode="numeric"
        required={true}
        value={editingOrder?.info?.numberOfPeople || 0}
        onChange={(numberOfPeople: number) =>
          setEditingOrder((o) => ({
            ...o,
            info: { ...(o?.info || {}), numberOfPeople },
          }))
        }
      />
    </Modal>
  );
};

export default OrderForAmountOfPeople;
