import { faFileExcel } from "@fortawesome/pro-light-svg-icons";
import { ColumnTableList } from "@kanpla/ui";
import { Form } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocalstorageState } from "rooks";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import ExportOrdersModal from "../../modals/ExportOrdersModal";
import ButtonLine from "../ButtonLine";
import SelectModule from "./SelectModule";

const ExportOrders = () => {
  const { t } = useTranslation(["translation", "mealplan2"]);

  const { modules } = useContainer(AppContext);

  const [open, setOpen] = useState(false);
  const [selectedModuleId, setSelectedModuleId] = useLocalstorageState<string>(
    "export-module-id",
    null
  );

  const defaultColumns = useMemo(() => {
    const cols = [
      {
        title: t("translation:date"),
        path: "dateSeconds",
      },
      {
        title: t("mealplan2:name-of-the-order"),
        path: "orderInfo_name",
      },
      {
        title: t("translation:total-price"),
        path: "priceTotalIncludingTax",
      },
      {
        title: t("translation:VAT"),
        path: "taxTotal",
      },
    ];

    return cols;
  }, []);

  const allCols = useMemo(() => {
    const cols = (modules || []).reduce((acc, module) => {
      return {
        ...acc,
        [`export-${module?.id}-columns`]: defaultColumns,
      };
    }, {});

    return cols;
  }, [modules, defaultColumns]);

  const [allColumns, setAllColumns] = useLocalstorageState<{
    [key: string]: ColumnTableList;
  }>("export-all-columns", allCols);

  const selectedColumns = useMemo(() => {
    const cols = allColumns?.[`export-${selectedModuleId}-columns`];

    return cols;
  }, [allColumns, selectedModuleId]);

  const [form] = Form.useForm();

  return (
    <>
      <ButtonLine
        label={t("translation:export-orders")}
        onClick={() => setOpen(true)}
        icon={faFileExcel}
        dataCy="export-orders-btn"
      />
      <ExportOrdersModal
        open={open}
        setOpen={setOpen}
        setAllColumns={setAllColumns}
        selectedColumns={selectedColumns}
        allColumns={allColumns}
        SelectModule={SelectModule}
        selectModuleProps={{
          setSelectedModuleId,
          selectedModuleId,
          form,
        }}
        modalTitle={t("translation:export-orders")}
      />
    </>
  );
};

export default ExportOrders;
