import { Actions } from "@kanpla/ui";
import { isEmpty } from "lodash";
import React from "react";
import { useContainer } from "unstated-next";
import { MealplanContext } from "..";
import useBasketPurchaseLogic from "../basket/useBasketPurchaseLogic";
import Card from "./Card";
import Empty from "./Empty";
import OrderPreview from "./OrderPreview";

interface Props {
  onTicketSelected: (ticketId: string, remove: boolean) => void;
  hideActions?: boolean;
}

const Content = (props: Props) => {
  const { hideActions = false } = props;

  const { onTicketSelected } = props;
  const { items } = useContainer(MealplanContext);
  const { actions } = useBasketPurchaseLogic();

  if (isEmpty(items)) return <Empty />;

  return (
    <>
      <div className="px-3">
        {items.map((item) => (
          <Card
            key={item.id}
            ticket={item}
            onTicketSelected={onTicketSelected}
          />
        ))}
        <OrderPreview />
      </div>
      {!hideActions && (
        <div
          className="h-fit p-2 bottom-20 w-full fixed z-20 bg-background-primary"
          style={{
            boxShadow: "0px -6px 8px 0px rgba(0,0,0,0.06)",
          }}
        >
          <Actions actions={actions} />
        </div>
      )}
    </>
  );
};

export default Content;
