import { faAt, faKeySkeleton, faUser } from "@fortawesome/pro-light-svg-icons";
import { DrawerOrModal } from "@kanpla/ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import ChangeEmail from "../../modals/changeEmail";
import ChangePassword from "../../modals/changePassword";
import Notifications from "../../modals/Notifications";
import ButtonLine from "../ButtonLine";
import DeleteAccount from "./DeleteAccount";

const Settings = () => {
  const [open, setOpen] = useState(false);
  const { isPosone, user, auth } = useContainer(AppContext);
  const [emailOpen, setEmailOpen] = useState(false);
  const [passwordOpen, setPasswordOpen] = useState(false);

  const { t } = useTranslation(["translation", "settings"]);
  const providerData = auth?.user?.providerData?.[0];

  return (
    <>
      <ButtonLine
        onClick={() => setOpen(true)}
        label={t("settings:your-account")}
        icon={faUser}
        dataCy="user-settings-btn"
      ></ButtonLine>
      <DrawerOrModal
        setOpen={setOpen}
        open={open}
        title={t("settings:your-account")}
      >
        {!isPosone && (
          <ButtonLine
            label={t("settings:change-email", {
              value: auth.user ? " (" + user?.email + ")" : " her",
            })}
            onClick={() => setEmailOpen(true)}
            disabled={providerData?.providerId === "microsoft.com"}
            dataCy="change-email"
            icon={faAt}
          />
        )}
        {!isPosone && (
          <ButtonLine
            label={t("translation:change-password")}
            onClick={() => setPasswordOpen(true)}
            dataCy="change-password"
            icon={faKeySkeleton}
          />
        )}
        <DeleteAccount />
      </DrawerOrModal>
      {/* Modals */}
      <ChangeEmail open={emailOpen} setOpen={setEmailOpen} />
      <ChangePassword open={passwordOpen} setOpen={setPasswordOpen} />
    </>
  );
};

export default Settings;
