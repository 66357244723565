import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  constructNewUrl,
  getMidnightSeconds,
  hasAccessToModule,
} from "@kanpla/system";
import { Module } from "@kanpla/types";
import {
  BottomNavigation,
  fontAwesomeCollection,
  getTabIcons,
} from "@kanpla/ui";
import { isArray, isEmpty } from "lodash";
import moment from "moment";
import { useRouter } from "next/router";
import { useContainer } from "unstated-next";
import useBasketPreventClick from "./BasketPreventClick";
import { moduleDefaultIcons } from "./anonymous/AnonymousTabs";
import { AppContext } from "./contextProvider";

const Tabs = () => {
  const { modules, school, child, module, mobile, appLoading } =
    useContainer(AppContext);

  if (isEmpty(module)) return null;

  const tabs = modules.reduce((acc, m) => {
    const hide = mobile
      ? m?.displayOptions?.hideMobileTab
      : m?.displayOptions?.hideDesktopTab;

    if (hide) return acc;

    if (m.type === "flex") {
      const hasAccess = hasAccessToModule({ module: m, school, child });

      if (hasAccess.individual)
        acc.push(
          <TabLink
            key={`${m.id}-individual`}
            label={`${m?.displayName || m.name}`}
            m={m}
          />
        );
      if (hasAccess.bulk)
        acc.push(
          <TabLink
            key={`${m.id}-admin`}
            moduleVariant="admin"
            label={m.adminDisplayName || "Admin"}
            m={m}
          />
        );
    } else {
      acc.push(<TabLink key={m.id} label={m?.displayName || m.name} m={m} />);
    }

    return acc;
  }, []);

  if (tabs.length <= 1) return null;

  if (mobile)
    return (
      <BottomNavigation loading={appLoading}>
        {tabs.map((tab) => tab)}
      </BottomNavigation>
    );

  if (appLoading)
    return (
      <div className="flex ml-4">
        <div className="loader h-9 w-24 mx-3 rounded" />
        <div className="loader h-9 w-24 mx-3 rounded" />
        <div className="loader h-9 w-24 mx-3 rounded" />
      </div>
    );

  return (
    <div className="overflow-x-auto mx-4 rounded-lg lg:mx-0">
      <div className="hidden md:flex pb-1 pt-1 text-center text-text-primary flex-none relative z-10 lg:justify-end">
        {tabs.map((tab) => (
          <>{tab}</>
        ))}
      </div>
    </div>
  );
};

export default Tabs;

interface LinkProps {
  label: string;
  moduleVariant?: "admin" | "";
  m: Module;
}

export const TabLink = ({ label, moduleVariant = "", m }: LinkProps) => {
  const { module, isBulk, schoolId, mobile } = useContainer(AppContext);
  const router = useRouter();

  const active =
    module.type === "flex"
      ? moduleVariant === "admin"
        ? m.id === module.id && isBulk
        : m.id === module.id && !isBulk
      : m.id === module.id;

  const confirm = useBasketPreventClick({
    disabled: false,
  });

  const handleClick = async () => {
    // Await confirmation from `BasketPreventClick`
    if (
      m.paymentMethod !== module.paymentMethod ||
      m.type !== module.type ||
      (m?.flow && module?.flow && m?.flow !== module?.flow)
    ) {
      await confirm(m, module);
    }

    // Outside of first `if` because very similar modules may have this property that makes them differs
    // if (m?.isShop !== module?.isShop) {
    //   await confirm(m, module);
    // }

    const url = constructNewUrl(schoolId, m.id, {
      isBulk: moduleVariant === "admin",
    });

    const today = getMidnightSeconds(moment().unix());
    router.push(`${url}?date=${today}`);
  };

  const adminIcon = moduleVariant === "admin" ? m?.adminDisplayIcon : null;

  const iconKey = adminIcon || m?.displayIcon || moduleDefaultIcons[m.type];

  return (
    <button
      onClick={handleClick}
      id={`navbar_${m.id}`}
      className={`focus:no-underline items-center justify-center flex ${
        mobile
          ? "flex-col flex-1 w-0 px-0"
          : "rounded-lg mr-1 group relative overflow-hidden px-4"
      } transition-colors py-2 whitespace-nowrap font-medium ${
        active ? "text-primary-dark" : "text-text-primary"
      }`}
    >
      {/* Background */}
      {!mobile && (
        <div
          className={`z-0 ${
            active ? "text-primary-main" : "text-background-secondary"
          }`}
        >
          <div
            className={`${
              active ? "group-hover:opacity-10" : "group-hover:opacity-100"
            } opacity-0 transition-opacity bg-current absolute inset-0 rounded-lg overflow-hidden`}
          />
        </div>
      )}
      <div
        className={`relative flex items-center ${
          mobile ? "flex-col flex-1 w-full" : "mr-1"
        }`}
      >
        {isArray(iconKey) ? (
          <FontAwesomeIcon icon={fontAwesomeCollection.fad[iconKey?.[1]]} />
        ) : (
          getTabIcons(mobile ? "regular" : "small", "currentColor").find(
            (icon) => icon.key === iconKey
          )?.icon
        )}
        <span
          className={`${
            mobile ? "mt-1 w-full overflow-hidden" : "ml-2"
          } text-sm`}
          style={{ fontSize: mobile && 11 }}
        >
          {label}
        </span>
      </div>
    </button>
  );
};
