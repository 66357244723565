import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isLastItem } from "@kanpla/system";
import classnames from "classnames";
import moment from "moment";
import { useContainer } from "unstated-next";
import { FlexContext } from "..";

const HolidaysList = () => {
  const { periods } = useContainer(FlexContext);

  return periods.some((period) => period?.fromSeconds && period?.toSeconds) ? (
    <div className="mt-4 mb-1">
      <ul>
        {periods.map((period, index) => {
          const startDate = moment.unix(period?.fromSeconds || 0).format("LL");
          const endDate = moment.unix(period?.toSeconds || 0).format("LL");

          if (!period?.fromSeconds || !period?.toSeconds) return null;

          const isLast = isLastItem({ array: periods, index });

          return (
            <div
              key={index}
              className={`flex items-center text-warning-main ${classnames({
                "mb-1": !isLast,
                "": isLast,
              })}`}
            >
              <FontAwesomeIcon
                icon={faCircle}
                style={{ fontSize: "7px" }}
                className="mr-2"
              />
              <li className="font-semibold">
                {startDate} - {endDate}
              </li>
            </div>
          );
        })}
      </ul>
    </div>
  ) : null;
};

export default HolidaysList;
