import { faPen } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BasketList, OrderingContext } from "@kanpla/ordering";
import { getOrderConfigs, useBasketResult } from "@kanpla/system";
import { Plugins } from "@kanpla/types";
import { AppContext } from "apps/frontend/components/contextProvider";
import EditBasketModal from "apps/frontend/components/modals/basket/EditBasketModal";
import { isEmpty } from "lodash";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { BasketProduct, MealplanMeetingContext } from "../..";
import { BasketResult } from "../../BasketResult";
import ConfirmButton from "../../ConfirmButton";
import { EmptyBasket } from "../../MobileBasket";

interface Props {
  hideButton?: boolean;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  /** This triggers opening of the modal, when the state changes to edit, used e.g. in meeting */
  openModalWithEdit?: boolean;
}

const BasketStep = ({
  hideButton = false,
  editMode,
  setEditMode,
  openModalWithEdit,
}: Props) => {
  const { t } = useTranslation(["mealplan2", "translation"]);

  const [editModalOpen, setEditModalOpen] = useState(false);

  const { module } = useContainer(AppContext);
  const { basket } = useContainer(OrderingContext);
  const { setIsConfirming } = useContext(MealplanMeetingContext);

  useEffect(() => {
    if (!openModalWithEdit) return;
    if (!editMode) return;

    setEditModalOpen(editMode);
  }, [editMode, openModalWithEdit]);

  const configs = useMemo(() => getOrderConfigs(basket || {}) || [], [basket]);
  const { totalAmount, totalPrice } = useBasketResult();

  const selectedProducts: BasketProduct[] = useMemo(
    () =>
      configs.map(({ price, productId, config }) => {
        return {
          amount: config.amount,
          price,
          productId,
          config,
        };
      }),
    [configs]
  );

  // Ignore required product
  const plugins = module?.plugins || ({} as Plugins.Array);
  const requiredProductId = plugins?.requiredProduct?.productId;

  const showEmptyBasket =
    !hideButton &&
    isEmpty(selectedProducts.filter((p) => requiredProductId !== p.productId));

  return showEmptyBasket ? (
    <EmptyBasket />
  ) : (
    <>
      <div className="flex flex-col">
        <BasketList
          type="meeting"
          setEditMode={setEditMode}
          editModalOpen={editModalOpen}
          setEditModalOpen={setEditModalOpen}
        />
        <div className="h-4" />
        <BasketResult amount={totalAmount} price={totalPrice} />
        {!hideButton ? (
          <ConfirmButton
            text={t("mealplan2:proceed-to-confirmation")}
            className="mt-4"
            onClick={() => setIsConfirming(true)}
            withIcon
            dataCy="continue-confirmation"
          />
        ) : null}
      </div>

      <EditBasketModal
        open={editModalOpen}
        setOpen={(nextState: boolean) => {
          setEditModalOpen(nextState);
          setEditMode(nextState);
        }}
        title={t("mealplan2:edit-basket")}
        actions={[
          {
            label: (
              <>
                <FontAwesomeIcon icon={faPen} className="mr-2" />
                {t("translation:done")}
              </>
            ),
            onClick: () => {
              setEditModalOpen(false);
              setEditMode(false);
            },
            type: "primary",
          },
        ]}
      >
        <BasketList
          type="meeting"
          editMode={editMode}
          setEditMode={setEditMode}
          editModalOpen={editModalOpen}
          setEditModalOpen={setEditModalOpen}
          hideHeader={true}
        />
      </EditBasketModal>
    </>
  );
};

export default BasketStep;
