/* eslint-disable react-hooks/rules-of-hooks */
import { DayIndex } from "@kanpla/types";
import { ModuleLoadingWrapper } from "@kanpla/ui";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import CanteenClosed from "../CanteenClosed";
import { AppContext } from "../contextProvider";
import NavbarSecondary from "../NavbarSecondary";
import Day from "./Day";
import DayDetail from "./DayDetail";
import { MenuPreviewContainer, MenuPreviewContext } from "./MenuPreviewContext";

const MenuPreview = () => {
  const { innerAppLoading } = useContainer(AppContext);

  return (
    <MenuPreviewContainer>
      <ModuleLoadingWrapper loading={innerAppLoading}>
        <View />
      </ModuleLoadingWrapper>
    </MenuPreviewContainer>
  );
};

const View = () => {
  const { week } = useContainer(AppContext);
  const { activeHoliday, defaultDate } = useContainer(MenuPreviewContext);

  const { t } = useTranslation(["translation", "components", "mealplan2"]);

  if (activeHoliday)
    return (
      <CanteenClosed
        defaultDate={defaultDate}
        holidayDesc={activeHoliday.design}
        t={t}
      />
    );

  return (
    <div className="bg-background-primary lg:pt-12 pb-2 max-w-4xl xl:max-w-5xl 2xl:max-w-6xl mx-auto">
      <div className="relative">
        <div className="flex flex-row">
          <div className="md:pb-4 sticky h-fit w-full md:w-auto">
            <NavbarSecondary timeNavigation="weekly" />
            {week.map((date, dayIndex: DayIndex) => (
              <Day
                key={`${dayIndex}-${date.toDate()}`}
                date={date}
                dayIndex={dayIndex}
              />
            ))}
          </div>
          <div className="hidden md:block flex-1 z-10">
            <DayDetail />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuPreview;
