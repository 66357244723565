import { hasAccessToModule, rules } from "@kanpla/system";
import { Form, FormInstance, Select } from "antd";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";

interface Props {
  setSelectedModuleId: Dispatch<SetStateAction<string>>;
  selectedModuleId: string;
  form: FormInstance<any>;
}

const SelectModule = ({
  setSelectedModuleId,
  selectedModuleId,
  form,
}: Props) => {
  const { t } = useTranslation(["translation"]);

  const { modules, school, child } = useContainer(AppContext);

  const filteredModules = useMemo(() => {
    const filtered = (modules || [])
      .filter(
        (module) =>
          module?.type !== "homescreen" &&
          module?.flow !== "meeting" &&
          module?.flow !== "menuPreview"
      )
      .filter((module) => {
        const access = hasAccessToModule({
          child,
          school,
          module,
          ignoreActive: true,
        });

        return access?.bulk;
      });

    return filtered;
  }, [child, modules, school]);

  return (
    <div className="mb-8">
      <h3 className="h500 mb-1">{t("translation:select-module")}</h3>
      <Form form={form}>
        <Form.Item
          initialValue={selectedModuleId}
          rules={[
            rules({
              rule: "required",
              ruleValue: true,
              message: t("translation:form.errors.required-a", {
                value: t("translation:module"),
              }),
            }),
          ]}
          name="select-module"
        >
          <Select
            onChange={(moduleId) => setSelectedModuleId(moduleId)}
            placeholder={t("translation:select-module")}
            className="w-full"
          >
            {(filteredModules || []).map((module, index) => (
              <Select.Option value={module.id} key={`${module.id}-${index}`}>
                {module.displayName || module.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SelectModule;
