import { faPlus } from "@fortawesome/pro-duotone-svg-icons";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getDaysInRange,
  getMidnightSeconds,
  isDatePastDeadline,
  Timestamp,
} from "@kanpla/system";
import {
  HolidayPeriod,
  LoadOfferReturn,
  Timestamp as Tsp,
} from "@kanpla/types";
import { ActionType, DrawerOrModal } from "@kanpla/ui";
import { Alert, Button, DatePicker, Form } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import classnames from "classnames";
import { isEmpty } from "lodash";
import moment, { Moment } from "moment";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

type RangeValue = RangePickerProps["value"];

interface Props {
  onConfirm: (periods: HolidayPeriod[]) => Promise<void> | void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  open?: boolean;
  periods?: HolidayPeriod[];
  holidayDates: LoadOfferReturn["holidayDates"];
  deadline?: number | false;
  deadlineWeekRelative?: boolean;
  deadlineExcludingWeekends?: boolean;
}

const initialPeriod = [{ fromSeconds: null, toSeconds: null }];

const seconds2Timestamp = (
  seconds?: number | null
): [Moment | null, Tsp | undefined] => {
  if (!seconds) return [null, undefined];

  const momentDate = moment.unix(seconds);
  const tsp = new Timestamp(momentDate.unix(), 0);

  return [momentDate, tsp];
};

export const HolidaysModal: FC<Props> = ({
  onConfirm,
  setOpen,
  open = false,
  periods: periodsValue = initialPeriod,
  holidayDates,
  deadline = false,
  deadlineWeekRelative = false,
  deadlineExcludingWeekends = false,
}) => {
  const { t, i18n } = useTranslation(["translation", "flex"]);
  const hasLanguage = !isEmpty(i18n);

  const [periods, setPeriods] = useState<HolidayPeriod[]>(periodsValue);

  useEffect(() => setPeriods(periodsValue), [periodsValue]);

  const [overlappingPeriods, setOverlappingPeriods] = useState<HolidayPeriod[]>(
    []
  );

  const [form] = Form.useForm();

  const actions: ActionType[] = useMemo(
    () => [
      {
        label: hasLanguage ? t("translation:confirm") : "Bekræft",
        onClick: () => onConfirm(periods),
      },
    ],
    [hasLanguage, onConfirm, t, periods]
  );

  const activeHolidays: string[] = useMemo(() => {
    const holidayEntries = Object.entries(holidayDates) || [];
    const holidays = holidayEntries.reduce((acc, [seconds, holiday]) => {
      if (!holiday) return acc;
      return [...acc, seconds];
    }, [] as string[]);

    return holidays;
  }, [holidayDates]);

  const addPeriod = useCallback(() => {
    setPeriods((prevState) => [
      ...prevState,
      {
        fromSeconds: null,
        toSeconds: null,
      },
    ]);
  }, []);

  const deletePeriod = useCallback(
    (index: number) => {
      setPeriods((prevPeriods) => {
        const item = prevPeriods?.[index];

        // Delete when holiday-document already exists
        if (item?.id) {
          return periods.map((p, ind) => {
            return ind === index ? { ...p, deleted: true } : p;
          });
        }

        // Delete when holiday-period is added new
        return periods.filter((_, indexPeriod) => indexPeriod !== index);
      });
    },
    [periods]
  );

  const choosePeriod = useCallback((event: RangeValue, index: number) => {
    const [startDate = null, endDate = null] = event || [];

    const newFromSeconds = startDate
      ? getMidnightSeconds(startDate.unix())
      : null;
    const newToSeconds = endDate ? getMidnightSeconds(endDate.unix()) : null;

    setPeriods((prevState) => {
      const newState = prevState.map((period, indexPeriod) => {
        if (indexPeriod === index)
          return { fromSeconds: newFromSeconds, toSeconds: newToSeconds };
        return period;
      });

      const overlappedPeriods = newState.filter((period) => {
        const { fromSeconds: currentFromSeconds, toSeconds: currentToSeconds } =
          period;

        // Check if all from-to's exists
        if (
          !currentFromSeconds ||
          !currentToSeconds ||
          !newFromSeconds ||
          !newToSeconds
        )
          return false;

        return (
          newFromSeconds < currentFromSeconds && newToSeconds > currentToSeconds
        );
      });

      const newPeriodIsOverlapping = Boolean(overlappedPeriods.length);

      if (newPeriodIsOverlapping)
        setOverlappingPeriods((prevState) => [
          ...prevState,
          { fromSeconds: newFromSeconds, toSeconds: newToSeconds },
        ]);

      return newState;
    });
  }, []);

  const todayIsPast = useMemo(() => {
    return isDatePastDeadline({
      date: new Timestamp(moment().unix(), 0),
      deadline,
      deadlineWeekRelative,
      deadlineExcludingWeekends,
    });
  }, [deadline, deadlineWeekRelative, deadlineExcludingWeekends]);

  const usedDays = useMemo(() => {
    const range = periods.reduce((acc: number[], period) => {
      const { fromSeconds, toSeconds } = period;
      if (!fromSeconds || !toSeconds) return acc;

      const momentStartDate = moment.unix(fromSeconds);
      const momentEndDate = moment.unix(toSeconds);

      const currentRange: number[] = getDaysInRange(
        momentStartDate,
        momentEndDate,
        "unix"
      ) as number[];

      return [...acc, ...currentRange];
    }, []);

    return [...new Set(range)] as number[];
  }, [periods]);

  const disabledDate = useCallback(
    (
      current: moment.Moment,
      startDate?: moment.Moment | null,
      endDate?: moment.Moment | null
    ) => {
      const currentRange = getDaysInRange(
        startDate,
        endDate,
        "unix"
      ) as number[];

      const targetRange = usedDays.filter(
        (seconds) => !currentRange.includes(seconds)
      );

      const isDayAlreadyPresent = targetRange.includes(
        getMidnightSeconds(current.unix())
      );

      const isKitchenClosed = activeHolidays.includes(
        getMidnightSeconds(current.unix()).toString()
      );

      const isDisabled =
        (current &&
          (todayIsPast
            ? current < moment().endOf("day")
            : current < moment().startOf("day"))) ||
        isDatePastDeadline({
          date: new Timestamp(current?.unix(), 0),
          deadline,
          deadlineWeekRelative,
          deadlineExcludingWeekends,
        }) ||
        isDayAlreadyPresent ||
        isKitchenClosed;

      return isDisabled;
    },
    [
      activeHolidays,
      usedDays,
      deadline,
      deadlineExcludingWeekends,
      deadlineWeekRelative,
      todayIsPast,
    ]
  );

  const periodsData = useMemo(() => {
    const overlappingPeriodsSet = new Set(
      overlappingPeriods.map((op) => `${op.fromSeconds}-${op.toSeconds}`)
    );

    return periods.map((period) => {
      const { fromSeconds, toSeconds } = period;

      const [startDate, startTsp] = seconds2Timestamp(fromSeconds);
      const [endDate, endTsp] = seconds2Timestamp(toSeconds);

      const isStartDateInPastValue = isDatePastDeadline({
        date: startTsp,
        deadline,
        deadlineWeekRelative,
        deadlineExcludingWeekends,
      });
      const isEndDateInPastValue = isDatePastDeadline({
        date: endTsp,
        deadline,
        deadlineWeekRelative,
        deadlineExcludingWeekends,
      });
      const isStartDateInPast = startDate ? isStartDateInPastValue : false;
      const isEndDateInPast = endDate ? isEndDateInPastValue : false;

      const isOverlapping = overlappingPeriodsSet.has(
        `${fromSeconds}-${toSeconds}`
      );

      return {
        ...period,
        startDate,
        endDate,
        isInPast: isStartDateInPast || isEndDateInPast,
        isStartDateInPast,
        isEndDateInPast,
        isOverlapping,
      };
    });
  }, [
    periods,
    deadline,
    deadlineExcludingWeekends,
    deadlineWeekRelative,
    overlappingPeriods,
  ]);

  return (
    <DrawerOrModal
      actions={actions}
      open={open}
      setOpen={setOpen}
      title={hasLanguage ? t("flex:holiday-periods") : "Ferieperioder"}
      subtitle={
        hasLanguage
          ? t("flex:holiday-periods-description")
          : "I disse perioder får du ikke noget mad."
      }
    >
      <div className="mb-16 mt-12">
        <div className="mb-5">
          <Form form={form} name="holidaysForm">
            {periodsData.map(
              (
                {
                  fromSeconds,
                  toSeconds,
                  startDate,
                  endDate,
                  isStartDateInPast,
                  isEndDateInPast,
                  isInPast,
                  isOverlapping,
                  deleted,
                  id,
                },
                index
              ) => (
                <div
                  key={`${fromSeconds}-${toSeconds}-${index}-${id}`}
                  className={classnames(deleted && "hidden")}
                >
                  <Form.Item
                    name={`period-${fromSeconds}`}
                    help={
                      isOverlapping && (
                        <div className="ant-form-item-explain-warning mt-2 mb-3">
                          {hasLanguage
                            ? t("flex:overlapping-error")
                            : "*Denne periode overlapper andre helligdage"}
                        </div>
                      )
                    }
                    className="mb-8"
                  >
                    <div
                      className={classnames({
                        "flex gap-x-8 items-center": true,
                      })}
                    >
                      <DatePicker.RangePicker
                        allowClear={false}
                        onChange={(event) => {
                          choosePeriod(event, index);
                        }}
                        disabled={[isStartDateInPast, isEndDateInPast]}
                        value={[startDate, endDate]}
                        disabledDate={(date) =>
                          disabledDate(date, startDate, endDate)
                        }
                        status={isOverlapping ? "warning" : ""}
                        format="LL"
                        className="w-full"
                      />
                      {isInPast ? null : (
                        <FontAwesomeIcon
                          onClick={() => deletePeriod(index)}
                          icon={faTrash}
                          color="#e53e3e"
                          className="cursor-pointer"
                        />
                      )}
                    </div>
                  </Form.Item>
                </div>
              )
            )}
          </Form>
        </div>
        <Button onClick={addPeriod}>
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          {hasLanguage ? t("flex:new-holiday-period") : "Ny ferieperioen"}
        </Button>
      </div>
      <Alert
        message={
          hasLanguage
            ? t("flex:alert-holidays")
            : "Alle bestillinger i valgte perioder slettes"
        }
        type="warning"
        showIcon
      />
    </DrawerOrModal>
  );
};
