import { Module, Product } from "@kanpla/types";
import { isEmpty, sortBy } from "lodash";

interface WithId {
  id: string;
}

interface WithProductId {
  productId: string;
}

interface Props<T> {
  /** Products you want to sort */
  items: Array<T>;
  /** List of productIds, saved at the productBank document */
  list: Module["list"];
  /**
   * If an array of categories is available, we add them to the sorting
   * We multiply category placement by 1000, to enforce sorting
   */
  categories?: Array<string>;
}

// type SortableProductList = WithId | WithProductId;

export function sortProducts<Type = Product>(props: Props<Type>): Array<Type> {
  const { items = [], list = [], categories = [] } = props;

  if (isEmpty(categories)) {
    const sorted = sortBy(items, (item) => {
      return list.indexOf(
        (item as unknown as WithProductId)?.productId ||
          (item as unknown as WithId)?.id
      );
    });

    return sorted;
  }

  const sorted = sortBy(items, (item) => {
    const productIndex = list.indexOf(
      (item as unknown as WithProductId)?.productId ||
        (item as unknown as WithId)?.id
    );

    const categoryIndex = categories.indexOf(item?.category);

    return categoryIndex * 1000 + productIndex;
  });

  return sorted;
}
