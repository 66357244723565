/* eslint-disable react-hooks/rules-of-hooks */
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CombinedOfferItem, DayIndex } from "@kanpla/types";
import { DisplayAttributes, DrawerOrModal } from "@kanpla/ui";
import { Button } from "antd";
import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

interface Props {
  product: CombinedOfferItem;
  dayIndex: DayIndex;
  detailed?: boolean;
}

const Product = ({ product, dayIndex, detailed = false }: Props) => {
  const { t } = useTranslation(["libs"]);
  const [allergensModalOpen, setAllergensModalOpen] = useState(false);

  const { supplier, week } = useContainer(AppContext);
  const dateSeconds = week[dayIndex]?.seconds;
  const menuItem = product.dates?.[dateSeconds]?.menu;

  const shouldHide =
    menuItem?.name === "X" || menuItem?.name === "x" || !menuItem;
  if (shouldHide) return null;

  const productName = product.name;
  const dishName = menuItem?.name;

  const tags = {
    ...(product.pictograms || {}),
    ...(menuItem?.pictograms || {}),
    ...((product as any)?.labels || {}),
    ...(menuItem?.labels || {}),
  };

  const allergens = {
    ...(menuItem?.allergens || {}),
    ...((product as any)?.allergens || {}),
  };

  return (
    <div className="py-6 inline-block w-full border-divider-main relative last:pb-12">
      {detailed && (
        <>
          <p
            className={classNames(
              productName && dishName && "text-text-secondary",
              !dishName && "text-text-primary",
              "font-bold"
            )}
          >
            {productName.trim().toUpperCase()}
          </p>
          {dishName && (
            <p className={classNames("font-semibold mt-2")}>{dishName}</p>
          )}
        </>
      )}
      <p className="whitespace-pre-line first-letter:uppercase mt-3">
        {detailed
          ? menuItem?.description || ""
          : productName || menuItem?.description || ""}
      </p>
      {detailed && (
        <>
          <div
            className={classNames(
              "flex mt-3 items-baseline",
              !Object.values(tags).includes(true)
                ? "justify-end"
                : "justify-between"
            )}
          >
            <DisplayAttributes
              attributes={tags}
              supplier={supplier}
              mode="all"
            />
            {Object.values(allergens || {}).includes(true) && (
              <Button
                type="text"
                className="px-0 text-text-secondary"
                onClick={() => setAllergensModalOpen(true)}
              >
                <span className="mr-2 underline">{t("libs:allergens")}</span>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Button>
            )}
          </div>
          <DrawerOrModal
            title={t("libs:allergens")}
            open={allergensModalOpen}
            setOpen={setAllergensModalOpen}
          >
            <DisplayAttributes
              attributes={allergens}
              mode="description"
              supplier={supplier}
              className="bg-transparent"
            />
          </DrawerOrModal>
        </>
      )}
    </div>
  );
};

export default Product;
