import { useFetch } from "@kanpla/system";
import {
  GetUserHistoryProps,
  HistoryItem,
} from "apps/frontend/pages/api/internal/user/history";
import {
  faArrowsRotate,
  faBagShopping,
  faMoneyCheck,
  faMoneySimpleFromBracket,
  faWarning,
} from "@fortawesome/pro-duotone-svg-icons";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";

const getIcon = (iconName: HistoryItem["icon"]) => {
  switch (iconName) {
    case "subscription":
      return faArrowsRotate;
    case "payout":
      return faMoneySimpleFromBracket;
    case "payment-valid":
      return faMoneyCheck;
    case "payment-error":
      return faWarning;
    case "order":
      return faBagShopping;
    default:
      return null;
  }
};

const useHistory = () => {
  const { userId } = useContainer(AppContext);
  const { t } = useTranslation(["modals", "translation"]);

  const { data, isValidating } = useFetch<
    GetUserHistoryProps,
    Array<HistoryItem>
  >("user/history", { userId }, { refreshInterval: 200000 });

  const itemsLoading = isValidating && isEmpty(data);

  const translatedItems = data?.map((item) => ({
    ...item,
    icon: getIcon(item.icon),
    title: t(item.title?.key, item.title?.value),
    paymentDescription: t(item.paymentDescription),
  }));

  return { items: translatedItems, fetchItems: () => null, itemsLoading };
};

export default useHistory;
