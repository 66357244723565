import { SubscriptionOrder } from "@kanpla/types";
import { ReceiptWrapper } from "@kanpla/ui";
import moment from "moment";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { SubscriptionContext } from "../.";
import { AppContext } from "../../contextProvider";
import Item from "./Item";

const Index = () => {
  const { t } = useTranslation(["subscription"]);

  const { subscriptions, periods } = useContext(SubscriptionContext);
  const { customBranding, module, mobile } = useContainer(AppContext);

  const allPeriods = Object.values(periods || {})?.flat();

  const customColor =
    customBranding?.custom?.palette?.background?.primary || "#ffffff";

  const setReceiptConfig = (subscription: SubscriptionOrder) => {
    const period = allPeriods.find(
      (period) => period?.id === subscription?.periodId
    );
    return {
      title: (
        <p>
          {subscription.name}{" "}
          {period ? (
            <span className="opacity-70 font-normal">
              &mdash; {period?.name}
            </span>
          ) : null}
        </p>
      ),
      subtitle: t("subscription:active-subscription"),
    };
  };

  const now = moment().unix();
  const filterOutSubscription = (s: SubscriptionOrder) => s.to.seconds > now;

  const activeSubscriptions = subscriptions.filter(filterOutSubscription);

  return (
    <div className="flex flex-col pb-6 md:p-0 md:float-right">
      {activeSubscriptions.map((subscription) => (
        <ReceiptWrapper
          withPadding={true}
          svgColor={customColor}
          mobile={mobile}
          receiptConfig={setReceiptConfig(subscription)}
          fullWidth={true}
        >
          <Item subscription={subscription} module={module} />
        </ReceiptWrapper>
      ))}
      {subscriptions?.length > activeSubscriptions?.length && (
        <p className="text-sm text-text-secondary float-right mx-auto max-w-sm">
          {t("subscription:see-old")}
        </p>
      )}
    </div>
  );
};

export default Index;
