/* eslint-disable react-hooks/rules-of-hooks */
import { capitalizeFirstLetter } from "@kanpla/system";
import { CombinedOfferItem, DayIndex, Timestamp } from "@kanpla/types";
import classnames from "classnames";
import { Dictionary, capitalize, get, isEmpty } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import DayDetailCategory from "./DayDetailCategory";
import { MenuPreviewContext } from "./MenuPreviewContext";
import { useCategoriesDictionary } from "./useCategories";

interface Props {
  date: Timestamp;
  dayIndex: DayIndex;
}

const Day = ({ date, dayIndex }: Props) => {
  const { i18n, t } = useTranslation(["mealplan2", "flex-bulk"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const { highlightedItems } = useContainer(MenuPreviewContext);
  const {
    setDayIndex,
    dayIndex: selectedDayIndex,
    innerAppLoading,
    schoolId,
    week,
    offer,
  } = useContainer(AppContext);

  const isHoliday = useMemo(
    () => offer?.holidayDates?.[date?.seconds],
    [date?.seconds, offer?.holidayDates]
  );

  const daySeconds = useMemo(() => week[dayIndex]?.seconds, []);

  const categories: Dictionary<CombinedOfferItem[]> =
    useCategoriesDictionary(dayIndex);
  const menuIsEmpty = useMemo(
    () =>
      !Object.values(categories || {})
        .flat()
        .filter((item) => {
          const menuNotAvailable = isEmpty(
            get(item, ["dates", week[dayIndex].seconds, "menu"])
          );
          const dayDisabled = get(item, ["days", dayIndex, "disabled"]);
          const schoolDayDisabled = get(item, [
            "schools",
            schoolId,
            "days",
            dayIndex,
            "disabled",
          ]);

          return !dayDisabled && !schoolDayDisabled && !menuNotAvailable;
        })?.length,
    [categories, dayIndex, week]
  );

  const isCurrentSelected = dayIndex === selectedDayIndex;
  const isToday = useMemo(
    () => moment(date.toDate()).isSame(moment(), "days"),
    [date]
  );
  const dayName = moment.unix(date.seconds).format("dddd [d.] D/M");
  const handleClick = useCallback(() => {
    setDayIndex(dayIndex);
  }, [dayIndex, setDayIndex]);

  useEffect(() => {
    if (!isToday || innerAppLoading) return;

    setDayIndex(dayIndex);
  }, [innerAppLoading]);

  return (
    <div
      className={classnames({
        "px-4 md:p-6 border-b md:border md:border-r-0 border-divider-main md:border-transparent md:cursor-pointer hover:md:border-divider-main transition md:rounded-l-lg md:block":
          true,
        "md:bg-primary-main md:text-white": isCurrentSelected,
        hidden: !isCurrentSelected,
      })}
      onClick={handleClick}
    >
      {menuIsEmpty && !isHoliday && (
        <p className="text-text-contrast opacity-80 pt-1">
          {t("mealplan2:menu.not-available")}
        </p>
      )}
      {isHoliday && (
        <p className="text-text-contrast opacity-40 pt-1">
          {t("flex-bulk:kitchen-closed")}
        </p>
      )}
      {!menuIsEmpty && (
        <>
          <div className="hidden md:block leading-tight">
            <p className="font-semibold text-lg">{capitalize(dayName)}</p>
            {highlightedItems.map((item) => {
              const dayMenu = item?.dates?.[daySeconds];

              return (
                <>
                  <p className="mt-2 opacity-80 text-sm">
                    {capitalizeFirstLetter(item?.category)}
                  </p>
                  <p className="mt-2 font-medium text-sm max-w-[25ch]">
                    {dayMenu?.menu?.name || item?.name}
                  </p>
                </>
              );
            })}
          </div>
          <div className="md:hidden">
            {Object.entries(categories)
              .sort()
              .map(([categoryName, categoryMenus]) => {
                return (
                  <DayDetailCategory
                    overrideDayIndex={dayIndex}
                    key={categoryName}
                    categoryName={categoryName}
                    items={categoryMenus}
                  />
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default Day;
