import { useFetch, usePrevious } from "@kanpla/system";
import {
  FetchNotificationsData,
  FetchNotificationsProps,
} from "apps/frontend/pages/api/internal/notifications/fetchNotifications";
import {
  FetchPopupHistoryData,
  FetchPopupHistoryProps,
} from "apps/frontend/pages/api/internal/popups/fetchPopupHistory";
import { orderBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { createContainer, useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import NotificationCenter from "./NotificationCenter";

const ContextState = () => {
  const { schoolId, childId, userId, setNotificationBadge } =
    useContainer(AppContext);

  // Store Notification Center state to handle notification badge on the icon
  const [newMessagesAmount, setNewMessagesAmount] = useState(0);

  const {
    data: { notifications, grouped: groupedNotifications },
    loading: notificationsLoading,
  } = useFetch<FetchNotificationsProps, FetchNotificationsData>(
    "notifications/fetchNotifications",
    {
      schoolId,
      groupByDate: true,
    },
    {
      fallbackData: {
        notifications: [],
        grouped: {},
      },
    }
  );

  const {
    data: { popups, grouped: groupedPopups },
    loading: popupsLoading,
  } = useFetch<FetchPopupHistoryProps, FetchPopupHistoryData>(
    "popups/fetchPopupHistory",
    {
      schoolId,
      childId,
      userId,
    },
    {
      fallbackData: {
        popups: [],
        grouped: {},
      },
    }
  );

  // Check if there are new notifications
  const prevMessagesLength = usePrevious({
    popups: popups?.length || Infinity,
    notifications: notifications?.length || Infinity,
  });

  const hasNewMessages = useMemo(() => {
    const hasChangeInNotifications =
      prevMessagesLength?.notifications !== notifications.length &&
      prevMessagesLength?.notifications < notifications.length;

    const hasChangeInPopups =
      prevMessagesLength?.popups !== popups.length &&
      prevMessagesLength?.popups < popups.length;

    const changeDiff =
      ((hasChangeInNotifications &&
        notifications.length - prevMessagesLength?.notifications) ||
        0) +
      ((hasChangeInPopups && popups.length - prevMessagesLength?.popups) || 0);

    const isFirstNotification =
      (prevMessagesLength?.notifications === Infinity &&
        notifications.length === 1) ||
      (prevMessagesLength?.popups === Infinity && popups.length === 1);

    return {
      hasChange:
        isFirstNotification || hasChangeInPopups || hasChangeInNotifications,
      diffAmount: changeDiff,
    };
  }, [popups.length, notifications.length, prevMessagesLength]);

  const allMessages = useMemo(() => {
    const todayTargets = orderBy(
      [
        ...(groupedNotifications?.["today"] || []),
        ...(groupedPopups?.["today"] || []),
      ],
      ["createdAtSeconds", "dateTimeSeconds"],
      "desc"
    );
    const previousTargets = orderBy(
      [
        ...(groupedNotifications?.["previous"] || []),
        ...(groupedPopups?.["previous"] || []),
      ],
      ["createdAtSeconds", "dateTimeSeconds"],
      "desc"
    );

    return { today: todayTargets, previous: previousTargets };
  }, [groupedNotifications, groupedPopups]);

  useEffect(() => {
    if (hasNewMessages.diffAmount) {
      setNewMessagesAmount(
        (prevAmount) => prevAmount + hasNewMessages.diffAmount
      );
    }

    if (!hasNewMessages.hasChange) return;

    setNotificationBadge(true);
  }, [hasNewMessages.hasChange, hasNewMessages.diffAmount]);

  return {
    allMessages,
    notifications,
    popups,

    setNewMessagesAmount,
    newMessagesAmount,

    loading: notificationsLoading || popupsLoading,
  };
};

export const NotificationCenterContext = createContainer(ContextState);

const Index = () => (
  <NotificationCenterContext.Provider>
    <NotificationCenter />
  </NotificationCenterContext.Provider>
);

export default Index;
