import { faEdit } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { constructNewUrl, isDatePastDeadline, Timestamp } from "@kanpla/system";
import { LoadOfferReturn } from "@kanpla/types";
import { Tooltip } from "antd";
import { AppContext } from "apps/frontend/components/contextProvider";
import { HistoryItem } from "apps/frontend/pages/api/internal/user/history";
import moment from "moment";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import PaymentDescription from "./PaymentDescription";
import ShowReceiptButton from "./ShowReceiptButton";

interface Props {
  item: HistoryItem;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const BigItem = ({ item, setOpen }: Props) => {
  const hasDetails = item.order;

  const { t } = useTranslation(["settings"]);

  const { setChildId, setMenuOpen, setHistoryOpen, allModules, offer } =
    useContainer(AppContext);

  const router = useRouter();

  const date = useMemo(() => {
    return Timestamp.fromMillis(item.dateSeconds * 1000);
  }, [item.dateSeconds]);

  const { deadlineInfo = {} as LoadOfferReturn["deadlineInfo"] } = offer || {};

  const { deadline, deadlineWeekRelative, deadlineExcludingWeekends } =
    deadlineInfo;
  const isPastDeadline = isDatePastDeadline({
    date,
    deadline,
    deadlineExcludingWeekends,
    deadlineWeekRelative,
  });

  const order = item?.order;

  const allowEditing = useMemo(() => {
    if (isPastDeadline) return false;

    const module = allModules.find((m) => m.id === order?.moduleId);
    if (
      module?.plugins?.kanplaGo?.active ||
      module?.plugins?.payPerOrder?.active
    )
      return false;

    return true;
  }, [allModules, order?.moduleId, isPastDeadline]);

  const navigateToReceipt = async () => {
    const { moduleId, schoolId, childId, dateSeconds, id } = order;

    const url = constructNewUrl(schoolId, moduleId, {
      extraQueries: `?date=${dateSeconds}&edit-modal=${id}&edit-mode=1`,
    });

    router.push(url);
    setChildId(childId);
    setMenuOpen(false);
    setHistoryOpen(false);
  };

  return (
    <div className="flex flex-col w-full bg-background-secondary rounded-lg my-1.5 px-3 pb-3 pt-3 text-text-primary select-none">
      <div className="flex w-full items-start">
        <div>
          <div className={`${order?.refundIds?.length ? "line-through" : ""}`}>
            {order?.displayName ? `${order.displayName.trim()}, ` : ""}
            {item.title}, {moment.unix(item.dateSeconds).format("DD. MMM YYYY")}
          </div>
          <div className="text-xs text-text-secondary">
            {item.salesplaceName}
          </div>
          <PaymentDescription item={item} />
        </div>
        {allowEditing && (
          <Tooltip title={t("settings:edit-your-order")}>
            <button
              className="rounded-lg w-12 h-12 flex items-center justify-center ml-auto border border-transparent hover:border-text-disabled transition hover:shadow-sm"
              onClick={navigateToReceipt}
            >
              <FontAwesomeIcon icon={faEdit} className="text-xl" />
            </button>
          </Tooltip>
        )}
      </div>
      {hasDetails && (
        <ShowReceiptButton
          onClick={() => {
            if (hasDetails) setOpen(true);
          }}
        />
      )}
    </div>
  );
};

export default BigItem;
