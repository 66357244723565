type Offset = 3600 | 7200;

export const getCETOffset = () => {
  const now = new Date();

  // 1. Get strings for both CET and UTC
  const localeString = now.toLocaleString("en-US", {
    timeZone: "Europe/Copenhagen",
  });
  const utcString = now.toUTCString().replace("GMT", "");

  // 2. Extracts seconds from each string
  const extractSeconds = (string: string) => new Date(string).getTime() / 1000;

  // 3. Compare
  const offset = extractSeconds(localeString) - extractSeconds(utcString);

  return offset as Offset;
};
