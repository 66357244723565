import { FlexBulk } from "@kanpla/ordering";
import useDeadlineJump from "apps/frontend/lib/useDeadlineJump";
import CanteenClosed from "../CanteenClosed";
import ModuleDescription from "../ModuleDescription";
import NavbarSecondary from "../NavbarSecondary";
import AdminOverview from "./AdminOverview";

const FlexBulkIndex = () => {
  return (
    <FlexBulk
      Overview={AdminOverview}
      ModuleDescription={ModuleDescription}
      NavbarSecondary={NavbarSecondary}
      useDeadlineJump={useDeadlineJump}
      CanteenClosed={CanteenClosed}
    />
  );
};

export default FlexBulkIndex;
