import { HolidayPeriod } from "@kanpla/types";
import { message } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { FlexContext } from "..";
import { HolidaysModal } from "../../lib/flex/HolidaysModal";

const StandardHolidays = () => {
  const { t } = useTranslation(["flex-bulk", "flex"]);
  const {
    // Modal open state
    openStandardHolidays,
    setOpenStandardHolidays,

    periods,
    submitStandardHolidays,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    holidayDates,
  } = useContainer(FlexContext);

  const confirmHandler = useCallback(
    async (periodsValue: HolidayPeriod[]): Promise<void> => {
      await submitStandardHolidays(periodsValue);
      setOpenStandardHolidays(false);

      message.success({
        key: "success-holidays-saving",
        content: t("flex:message.success.holidays-saved"),
      });
    },
    [setOpenStandardHolidays, submitStandardHolidays, t]
  );

  return (
    <HolidaysModal
      open={openStandardHolidays}
      setOpen={setOpenStandardHolidays}
      periods={periods}
      holidayDates={holidayDates}
      onConfirm={confirmHandler}
      deadline={deadline}
      deadlineWeekRelative={deadlineWeekRelative}
      deadlineExcludingWeekends={deadlineExcludingWeekends}
    />
  );
};

export default StandardHolidays;
