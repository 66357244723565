import { BasketList } from "@kanpla/ordering";
import { Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanContext } from "../mealplan2";

interface Props {
  amountShownTakeFirstBanner?: number;
  hasKanplaGo?: boolean;
  basketEditMode?: boolean;
  setBasketEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AnonymousBasket = ({
  amountShownTakeFirstBanner,
  hasKanplaGo,
  basketEditMode,
  setBasketEditMode,
}: Props) => {
  const { t } = useTranslation(["mealplan2", "anonymous"]);
  const { module } = useContainer(MealplanContext);

  return (
    <Space direction="vertical" className="w-full relative">
      {amountShownTakeFirstBanner < 4 &&
        hasKanplaGo &&
        !module.plugins.kanplaGo?.hidePickProducts && (
          <div className="bg-yellow-50 text-yellow-900 border border-yellow-600 rounded-lg p-2 px-3 flex items-center mb-3">
            <div className="w-full flex justify-center">
              <p className="font-semibold">
                {t("mealplan2:remember-to-take-the-item")}
              </p>
            </div>
          </div>
        )}
      <BasketList
        type="mealplan"
        hideEditButton
        editMode={basketEditMode}
        setEditMode={setBasketEditMode}
        anonymous
      />
      <div className="absolute h-full flex justify-center items-center bg-gradient-to-b from-transparent via-background-primary to-transparent top-0 -mx-2 px-8 text-center">
        <p className="font-semibold text-lg text-primary-main">
          {t("anonymous:info-authenticate")}
        </p>
      </div>
    </Space>
  );
};

export default AnonymousBasket;
