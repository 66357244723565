import { faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMeasure } from "@kanpla/system";
import { AppContext } from "apps/frontend/components/contextProvider";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { animated, useSpring } from "react-spring";
import { useGesture } from "react-use-gesture";
import { useContainer } from "unstated-next";

const Slider = ({ submit, checkoutLoading, label = "", disabled = false }) => {
  const { t } = useTranslation(["translation", "mealplan2"]);
  const { createHapticFeedback } = useContainer(AppContext);

  const [{ transform, opacity }, set] = useSpring(() => ({
    transform: `translateX(0px)`,
    opacity: 1,
  }));

  const [measuredEl, measuredProps] = useMeasure();
  // @ts-ignore
  const containerWidth = measuredProps?.width;
  const maxDrag = containerWidth - 64;

  const bind = useGesture({
    onDrag: ({ down, movement: [x, y] }) => {
      if (x < 0) x = 0;
      if (x > maxDrag) x = maxDrag;
      const percentage = x / maxDrag;
      set({
        transform: down ? `translateX(${x}px)` : `translateX(0px)`,
        // opacity: down ? 1 - percentage : 1,
        opacity: down ? 1 - percentage : 1,
        immediate: down,
      });
    },
    onDragEnd: ({ down, movement: [x, y] }) => {
      const percentage = x / maxDrag;

      if (percentage > 0.9) {
        createHapticFeedback({
          type: "notification",
          notificationFeedback: "success",
        });
        submit();
        return;
      }

      createHapticFeedback({
        type: "notification",
        notificationFeedback: "error",
      });
    },
  });

  const bgColor = disabled ? "bg-background-secondary" : "bg-primary-main";

  return (
    <div
      data-cy="purchase-slider"
      className="p-1 h-14 relative whitespace-nowrap overflow-clip flex"
      {...measuredEl}
    >
      <animated.div
        className={classnames(
          "w-full h-full rounded-lg absolute inset-0",
          checkoutLoading ? "loader" : bgColor
        )}
      />
      <animated.div
        className={classnames(
          "font-white absolute whitespace-nowrap font-medium overflow-clip text-lg self-center justify-self-center select-none w-full text-center",
          disabled ? "text-text-secondary" : "sliding-gradient-animation",
          { "text-primary-contrast": !checkoutLoading && !disabled }
        )}
        style={{
          opacity,
        }}
      >
        {checkoutLoading
          ? t("translation:loading")
          : label || t("mealplan2:check-out")}
      </animated.div>
      <animated.div
        {...bind()}
        style={{
          transform,
        }}
        className={classnames(
          "w-12 h-12 relative rounded-lg flex items-center justify-center",
          {
            "opacity-0": checkoutLoading,
            "text-primary-contrast animate-pulse": !disabled,
            "text-text-secondary pointer-events-none": disabled,
          }
        )}
      >
        <FontAwesomeIcon
          icon={faAngleRight}
          size="2x"
          className={classnames({ "animate-slide-in": !disabled })}
        />
      </animated.div>
    </div>
  );
};

export default Slider;
