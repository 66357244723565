import { faEye, faPen } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BasketList } from "@kanpla/ordering";
import {
  calculateAmountOfOrderItems,
  calculateOrderTotal,
  callInternalApi,
} from "@kanpla/system";
import {
  OrderInfo as OrderInfoType,
  OrderMealplan,
  OrderOrder,
  Plugins,
} from "@kanpla/types";
import { ReceiptWrapper, RequiredProduct } from "@kanpla/ui";
import { Button, Divider, Form, message } from "antd";
import classnames from "classnames";
import { diff } from "just-diff";
import { cloneDeep, setWith } from "lodash";
import moment from "moment";
import { BooleanParam, StringParam, useQueryParams } from "next-query-params";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { MealplanContext } from "..";
import { AppContext } from "../../contextProvider";
import EditBasketModal from "../../modals/basket/EditBasketModal";
import ReceiptDivider from "./ReceiptDivider";

interface Props {
  orderDocument: OrderMealplan;
  ordersAmount?: number;
  index: number;
  editingDisabled?: boolean;
  disabled?: boolean;
  setEditingOrderId: Dispatch<SetStateAction<string>>;
  totalOrder: number;
}

const OrderItem: FC<Props> = ({
  orderDocument,
  ordersAmount,
  index,
  editingDisabled,
  disabled,
  setEditingOrderId,
  totalOrder,
}) => {
  const order = orderDocument.order;
  const orderInfo = orderDocument.info;

  const [localBasket, setLocalBasket] = useState<OrderOrder>(order);
  //const [openEditOrder, setOpenEditOrder] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isBasketEmptyTrigger, setIsBasketEmptyTrigger] = useState(false);

  const [editingParams, setEditingParams] = useQueryParams({
    "edit-mode": BooleanParam,
    "edit-modal": StringParam,
  });

  const editModalOpen = editingParams?.["edit-modal"] === orderDocument?.id;
  const setEditModalOpen = useCallback(
    (value: boolean) => {
      if (!value) {
        setEditingParams({
          "edit-mode": undefined,
          "edit-modal": undefined,
        });

        return;
      }

      setEditingParams({
        "edit-mode": true,
        "edit-modal": orderDocument?.id,
      });
    },
    [setEditingParams, orderDocument?.id]
  );

  const { t } = useTranslation([
    "mealplan2",
    "translation",
    "plugins",
    "flex",
    "plugins",
  ]);

  const { week, dayIndex, isSaving, setIsSaving, childId, activePlugins } =
    useContainer(AppContext);
  const {
    moduleId,
    orders,
    setReceiptTime,
    setReceiptOpen,
    setCheckoutItems,
    module,
    isPastDeadline,
    hasKanplaGo,
    mealOptions,
    items,
  } = useContainer(MealplanContext);

  useEffect(() => {
    isEditing && setEditingOrderId(orderDocument.id);
    if (!isEditing) setEditingOrderId(null);
  }, [isEditing]);

  const orderTotal = calculateOrderTotal(order);
  const amountOfProducts = calculateAmountOfOrderItems(localBasket);
  const basketTotal = calculateOrderTotal(localBasket);

  const [form] = Form.useForm();

  useEffect(() => {
    setLocalBasket(cloneDeep(order));
  }, [orderTotal]);

  const differences = useMemo(
    () => diff(localBasket, order),
    [basketTotal, orderTotal, amountOfProducts]
  );

  useEffect(() => {
    const isBasketEmpty = Object.entries(localBasket).every(
      ([_, value]) => !value.amount
    );

    setIsBasketEmptyTrigger(isBasketEmpty);
  }, [localBasket]);

  const needsToBeSaved = differences.length > 0;

  const plugins = module.plugins || ({} as Plugins.Array);

  const editablePlugins = ["timeInput", "textInput", "invoiceReference"];

  const hasActivePlugins = Object.entries(plugins)
    .filter(([key, _]) => editablePlugins.includes(key))
    .some(([_, plugin]) => plugin.active);

  const submitEditedOrder = async () => {
    try {
      const newOrder = localBasket;
      setIsSaving((s) => s + 1);

      const data = await form.validateFields();
      let info = {};

      const newData = Object.entries(data).reduce((acc, [path, value]) => {
        if (!value) return acc;

        const newAcc = setWith(acc, path, value, Object);
        return newAcc;
      }, {});

      info = submitOrderInfo(newData);

      await callInternalApi("ordering/submitOrder", {
        dateSeconds: week[dayIndex]?.seconds,
        childId,
        order: newOrder,
        moduleId,
        info: { ...orderInfo, ...info },
        allowedOrderIds: [orderDocument?.id],
      });

      form.resetFields();

      message.success(t("mealplan2:message.success.order-updated"));
    } catch (err) {
      console.error(err);
      message.error(err?.message);
    } finally {
      setIsSaving((s) => s - 1);
      setEditModalOpen(false);
      setIsEditing(false);
    }
  };

  const submitOrderInfo = (orderInfo: OrderInfoType) => {
    const { textInput, timeInput, reference } = orderInfo;

    return {
      ...(textInput && plugins?.textInput?.active && { textInput: textInput }),
      ...(timeInput && plugins?.timeInput?.active && { timeInput: timeInput }),
      ...(reference &&
        plugins?.invoiceReference?.active && { reference: reference }),
    };
  };

  const wrapperClassName = classnames({
    "opacity-30 pointer-events-none": disabled,
    "transition-opacity": true,
  });

  if (!orders.length) return null;

  const date = moment.unix(orderDocument.createdAtSeconds);

  const onClickProp = {
    onClick: async () => await submitEditedOrder(),
  };

  const buttonProps = {
    loading: isSaving !== 0,
    ...onClickProp,
  };

  const buttonText =
    isSaving !== 0
      ? t("flex:message.loading.order-being-saved")
      : needsToBeSaved
      ? t("translation:save")
      : undefined;

  const bottomActions = hasKanplaGo
    ? [
        <Button
          onClick={() => {
            setCheckoutItems(order || {});
            setReceiptOpen(true);
            setReceiptTime(orderDocument.updatedAtSeconds);
          }} // open kanpla go receipt
          className="font-semibold w-full py-4 h-auto -mx-8 -my-4"
          type="link"
        >
          {t("mealplan2:show-last-receipt")}
          <FontAwesomeIcon icon={faEye} className="ml-2" />
        </Button>,
      ]
    : isPastDeadline
    ? [
        <p className="text-sm text-text-disabled -my-2">
          {t("mealplan2:too-late-to-change-order-message")}
        </p>,
      ]
    : [
        <Button
          onClick={() => {
            setIsEditing(true);
            setEditModalOpen(true);
          }}
          disabled={hasKanplaGo}
          className="font-semibold w-full py-4 h-auto -mx-8 -my-4 group"
          type="link"
        >
          <div className="bg-gradient-to-t from-background-secondary to-transparent absolute inset-0 -bottom-4 opacity-0 transition group-hover:opacity-30 group-active:opacity-60 z-0" />
          <FontAwesomeIcon icon={faPen} className="mr-2" />
          {t("translation:edit")}
        </Button>,
      ];

  return (
    <>
      <div className={`${wrapperClassName}`}>
        {/* Basket List */}
        <div className="border-x-2 px-6 pt-6 pb-3">
          <BasketList
            type="receipt"
            key={orderDocument?.id}
            hideHeader={!!orders.length}
            setEditMode={setIsEditing}
            editType="amount"
            basket={localBasket}
            setBasket={setLocalBasket}
            disableEditing={editingDisabled}
            title={
              ordersAmount > 1 ? (
                <span className="text-text-secondary h200">
                  {orderDocument?.info?.name ||
                    t("plugins:order-number-kl", {
                      index: `#${index + 1}`,
                      time: date.format(
                        date.isSame(moment(), "day") ? "HH:mm" : "L HH:mm"
                      ),
                    })}
                </span>
              ) : (
                t("plugins:you-have-ordered")
              )
            }
            buttonProps={buttonProps as any}
            buttonText={buttonText}
          />
        </div>

        {/* Infos and Footer */}
        <div className="border-x-2 px-6 pt-3">
          <div className="flex justify-between items-baseline mb-2">
            <ReceiptWrapper.InfoDisplay orderInfo={orderInfo} module={module} />
          </div>

          {/* Added extra implementation of <RequiredProduct/> outside of OrderInfo, because the basket data to be edited is different */}
          {activePlugins.requiredProduct && (
            <RequiredProduct
              settings={plugins?.requiredProduct}
              requiredProductOrder={localBasket}
              setRequiredProductOrder={(requiredOrder) =>
                setLocalBasket({ ...localBasket, ...requiredOrder })
              }
              mealOptions={mealOptions}
              module={module}
              order={localBasket}
              offerItems={items}
            />
          )}

          <Divider
            style={{ borderColor: "#e2e8f0" }}
            className="m-0 border-t-2"
          />

          <ReceiptWrapper.Footer total={totalOrder} module={module} />
        </div>

        {/* Receipt Divider */}
        <ReceiptDivider />

        {/* Bottom Actions */}
        <ReceiptWrapper.BottomActions bottomActions={bottomActions} />

        {/* Needed to trigger the receipt animation */}
        {isBasketEmptyTrigger ? <div className="hidden"></div> : null}
      </div>

      <EditBasketModal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        title={t("mealplan2:edit-basket")}
        actions={[
          {
            label: t("translation:cancel"),
            onClick: () => {
              setEditModalOpen(false);
              setIsEditing(false);
              setLocalBasket(cloneDeep(order));
            },
            type: "default",
          },
          {
            label: (
              <>
                {!isSaving && <FontAwesomeIcon icon={faPen} className="mr-2" />}
                {t("translation:done")}
              </>
            ),
            loading: isSaving !== 0,
            ...onClickProp,
            type: "primary",
          },
        ]}
      >
        <BasketList
          showOrderInfo={!isPastDeadline && hasActivePlugins && !hasKanplaGo}
          type="receipt"
          editType="amount"
          key={orderDocument?.id}
          hideHeader={!!orders.length}
          editMode={isEditing || editingParams?.["edit-mode"]}
          setEditMode={setIsEditing}
          basket={localBasket}
          setBasket={setLocalBasket}
          disableEditing={editingDisabled}
          title={
            ordersAmount > 1 ? (
              <span className="text-text-secondary h200">
                {orderDocument?.info?.name ||
                  t("plugins:order-number-kl", {
                    index: `#${index + 1}`,
                    time: date.format(
                      date.isSame(moment(), "day") ? "HH:mm" : "L HH:mm"
                    ),
                  })}
              </span>
            ) : (
              t("plugins:you-have-ordered")
            )
          }
          buttonProps={buttonProps as any}
          buttonText={buttonText}
          form={form}
          orderInfo={orderInfo}
        />
      </EditBasketModal>
    </>
  );
};

export default OrderItem;
